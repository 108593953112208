import { useNavigate, useParams } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
// import { types } from "helpers";
import { Fragment } from "react";

export default function StudentList() {
  const { agentID } = useParams();
  const navigate = useNavigate();
  const onActionA = (data) => {
    if (data.type === "View") {
      navigate(`/agent/promoters/student/info/${data.item._id}`);
    } else if (data.type === "Student List") {
      //   navigate(`/agent/promoters/student/list/${data.item._id}`);
    }
  };

  return (
    <Fragment>
      {true && (
        <AgTable
          module="promoterStudent"
          unqID={agentID}
          goBack={true}
          filterData={{
            status: { key: "isActive", value: "" },
            dateA: { key: "startDate", value: "", title: "Promo Start Date" },
            dateB: { key: "endDate", value: "", title: "Promo End Date" },
          }}
          onAction={onActionA}
        />
      )}
    </Fragment>
  );
}
