import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Slider from "react-slick";
// includes
import { api, common } from "helpers";
import FireBase from "../../helpers/firebase";

function Register() {
  // init
  const navigate = useNavigate();
  const { type = "direct" } = useParams();

  // state
  const [viewA, setViewA] = useState(false);
  const [viewB, setViewB] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState(null);
  const [localData] = useState(common.localDataGet());
  const [queryParams] = useSearchParams();
  const [deviceToken, setDeviceToken] = useState(null);
  // form
  const formFields = {
    firstName: {
      validate: ["req#First Name is required", "min:3", "max:25"],
    },
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
        "max:60",
      ],
    },
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
      value: "",
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
    noveltyId: {},
    course: {},
    intakeMonth: {},
    intakeYear: {},
  };
  const { fields, errors, setMultiValue, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect
  useEffect(() => {
    FireBase.getDeviceToken(setDeviceToken);
    let valid = checkValid();
    if (valid) {
      let fData = {};
      if (localData) {
        Object.entries(localData).map(([key, item]) => {
          if (key !== "type") {
            fData[key] = item;
          }
        });
      } else {
        fData = getQueryData();
      }
      setMultiValue(fData);
    }
  }, []);

  // Api
  const register = () => {
    let payload = {
      name: fields?.firstName,
      email: fields?.email,
      password: fields?.password,
      userType: "STUDENT",
      webDevToken: deviceToken,
      registeredFrom: "Web",
    };
    let data = {
      url: "ids_sign_up",
      // query: type === "novelty" ? "/novelty" : "",
      method: "POST",
      auth: "none",
      body: payload,
    };

    api.call(data, (res) => {
      if (res.status == 200) {
        let data = res.data;
        if (data?.token) {
          common.notify("S", "Registration has been successfully completed");
          common.localSet("authData", common.crypt(data, true));
          navigate("/student/home");
        } else {
          common.localDataSet({
            ...payload,
            type: "studentRegister",
          });
          navigate("/otp/verify");
        }
      } else if (res.status == 500) {
        setError(res.data.error);
      }
      setLoading(false);
    });
  };

  // handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      setLoading(true);
      register();
    }
  };

  // support
  const checkValid = () => {
    let qry = queryParams.get("q");
    if (type === "novelty" && !qry) {
      navigate("/login");
      return false;
    }
    return true;
  };

  const getQueryData = () => {
    let qry = queryParams.get("q");
    let qryDecode = {};
    let splitA = {};
    let splitB = {};
    let data = {};
    if (qry) {
      qry = qry.replace(/ /g, "+");
      qryDecode = common.deCrypt(qry, false);
      console.log(qryDecode);
      /* console.log(
        common.crypt(
          "7#62eceaff418acfbe1e5b23ba#5#2022#meetbaburaj@gmail.com:Baburaj"
        )
      ); */
      splitA = qryDecode.split(":");
      splitB = splitA[0].split("#");
      data = {
        firstName: splitA?.[1] || "",
        email: splitB?.[4] || "",
        noveltyId: splitB?.[0] || "",
        course: splitB?.[1] || "",
        intakeMonth: splitB?.[2] || "",
        intakeYear: splitB?.[3] || "",
      };
    }
    return data;
  };

  //render
  return (
    <div className="container-fluid std-reg-wrap p-0">
      <div className="row">
        <div className="col-md-8 reg-slider-wrap">
          {/* <div className="row">
            <div className="col-md-9 mx-auto mt-5"> */}
          <div className="slider-container">
            <Slider {...common.signupSliderSettings()}>
              <div>
                <img src={common.loadImg("slider/slider-img1.png")} alt="" />
              </div>
              <div>
                <img src={common.loadImg("slider/slider-img2.png")} alt="" />
              </div>
              <div>
                <img src={common.loadImg("slider/slider-img3.png")} alt="" />
              </div>
              <div>
                <img src={common.loadImg("slider/slider-img4.png")} alt="" />
              </div>
              <div>
                <img src={common.loadImg("slider/slider-img5.png")} alt="" />
              </div>
            </Slider>
          </div>
          {/* <div className=" border-solid mt-4">
                <div className="mb-5">
                  <img src={common.loadImg("novelty-card.svg")} />
                </div>
                <h1 className="novelty-card">
                  Get discounts up to 30% on course fee through Novelty Card App
                </h1>
                <p className="form-label para-content mt-4">
                  Our student lifestyle app can save you 30% on uni course fees.
                  DOWNLOAD Novelty Card NOW and START SAVING!
                </p>
              </div> */}
          {/* <div className="download-link mt-4">
                You can download the application through these links
                <div className="d-flex mt-2">
                  <a href="https://www.apple.com/in/app-store/" target="_blank">
                    <img
                      src={common.loadImg("appstore copy.svg")}
                      className="me-3"
                    />
                  </a>
                  <a href="https://play.google.com" target="_blank">
                    <img src={common.loadImg("googleplay.svg")} />
                  </a>
                </div>
              </div> */}
          {/* </div>
          </div> */}
        </div>

        <div className="col-md-4 position-relative reg-student-right-col">
          <div className="right-content-width signup-shadow-border">
            <p className="signup-heading mb-0">Register Now</p>
            <div className="box-body">
              <label className="form-label">First Name</label>
              <div className="et-form-row">
                <input
                  type="text"
                  name="firstName"
                  value={fields.firstName}
                  onChange={handleChange}
                  className="form-control"
                  maxLength={25}
                />
                <div className=" email-fieldcheckbox checkbox-design">
                  <div className="input-tick"></div>
                </div>
                <p className="error-txt">{errors.firstName}</p>
              </div>
              <label className="form-label mt-2">Email Address</label>
              <div className="et-form-row">
                <OverlayTrigger
                  show={type === "novelty" && focus === "email"}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Required OTP verification if you change / add your email
                      address
                    </Tooltip>
                  }
                >
                  <input
                    type="email"
                    name="email"
                    value={fields.email}
                    onChange={(e) => {
                      handleChange(e);
                      setError(null);
                    }}
                    onFocus={() => setFocus("email")}
                    onBlur={() => setFocus(null)}
                    maxLength={60}
                    className="form-control input-email"
                  />
                </OverlayTrigger>
                <div className=" email-fieldcheckbox checkbox-design">
                  <div className="input-tick"></div>
                </div>
                <p className="error-txt">{error ?? errors.email}</p>
              </div>

              <label className="form-label mt-2">Password</label>
              <span className="ms-2">
                <OverlayTrigger
                  placement="right"
                  delay={{ show: 250, hide: 400 }}
                  overlay={
                    <Tooltip id="button-tooltip">
                      Password should be minimum 8 Characters
                    </Tooltip>
                  }
                >
                  <img src={common.loadImg("iconly-bulk-info-square.svg")} />
                </OverlayTrigger>
              </span>
              <div className="et-form-row">
                <input
                  type={viewA ? "text" : "password"}
                  name="password"
                  value={fields.password}
                  onChange={handleChange}
                  maxLength={15}
                  className="form-control"
                />
                <div
                  className="pwd-eye"
                  onClick={() => setViewA((prev) => !prev)}
                >
                  <img
                    src={
                      viewA
                        ? common.loadImg("password_show.svg")
                        : common.loadImg("password_hide.svg")
                    }
                    alt={viewA ? "shown" : "hidden"}
                    className="et-cp"
                  />
                </div>
                <div className=" email-fieldcheckbox checkbox-design">
                  <div className="input-tick"></div>
                </div>
                <p className="error-txt">{errors.password}</p>
              </div>

              <label className="form-label mt-2">Re-type Password</label>
              <div className="et-form-row">
                <input
                  name="confirmPassword"
                  type={viewB ? "text" : "password"}
                  value={fields.confirmPassword}
                  onChange={handleChange}
                  maxLength={15}
                  className="form-control"
                />
                <div
                  className="pwd-eye"
                  onClick={() => setViewB((prev) => !prev)}
                >
                  <img
                    src={
                      viewB
                        ? common.loadImg("password_show.svg")
                        : common.loadImg("password_hide.svg")
                    }
                    alt={viewB ? "shown" : "hidden"}
                    className="et-cp"
                  />
                </div>
                <div className=" email-fieldcheckbox checkbox-design">
                  <div className="input-tick"></div>
                </div>
                <p className="error-txt">{errors.confirmPassword}</p>
              </div>
              <div className="terms-font mt-2">
                I agree to the{" "}
                <a href="#" className="terms-font">
                  Terms and Conditions{" "}
                </a>
                by clicking Submit
              </div>

              <div className="d-flex justify-content-center mt-2">
                <button
                  className="btn-default"
                  onClick={() => onSubmit()}
                  disabled={loading}
                >
                  {!loading ? "Sign up" : "Processing"}
                </button>
              </div>
            </div>
          </div>
          <div className="double-border"></div>
        </div>
      </div>
    </div>
  );
}

export default Register;
