import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Modal from "react-modal";
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
// includes
import BookSlot from "elements/TimelineModal/BookSlot";
import InterviewCancel from "elements/TimelineModal/cancelInterview";
import InterviewConfirm from "elements/TimelineModal/confirmInterview";
import FileUpload from "elements/fileUpload";
import ModalConfirm from "elements/modalConfirm";
import StatusBar from "elements/statusBar";
import { api, common, config, types } from "helpers";
import ConnectToAgent from "includes/connectToAgent";
import CoursePickup from "includes/coursePickup";
import Switch from "@mui/material/Switch";

function Status({ socket }) {
  const [verified, setVerified] = useState(false);
  const [fields, setFields] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const DatePickerElement = (name, label, size = 6, sub = null, key = null) => {
    let set = true;
    return (
      <Col md={size}>
        <label className="form-label">rescheduled Date</label>
        <div className="signin-form-row date-picker">
          <DateRangePicker
            initialSettings={{
              ...config.elements.singleDp,
              ...(name == "dob"
                ? {
                    minYear: parseInt(
                      moment().subtract(70, "years").format("Y")
                    ),
                    maxYear: parseInt(
                      moment().subtract(22, "years").format("Y")
                    ),
                  }
                : {}),
            }}
            onShow={(event, picker) => {
              onDatePickerChange(name, picker.startDate, sub, key);
            }}
            onCallback={(end) => {
              onDatePickerChange(name, end, sub, key);
            }}
          >
            <input
              type="text"
              className="form-control date-picker"
              value={sub ? fields[sub][key][name] : fields?.[name]}
              placeholder={`Please select the Date`}
              readOnly={true}
            />
          </DateRangePicker>
          <button
            className="clear-icn"
            onClick={() => onDatePickerChange(name, "", sub, key)}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className="css-tj5bde-Svg"
            >
              <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
            </svg>
          </button>
          <p className="error-txt">
            {sub ? errors?.[sub]?.[key]?.[name] : errors?.[name]}
          </p>
        </div>
      </Col>
    );
  };

  const onDatePickerChange = (name, value, sub, k) => {
    if (sub) {
      let f = common.reParse(fields);
      f[sub][k][name] =
        value === "" ? "" : moment(value).format(config.elements.dp.A);

      setFields(f);
    } else {
      setFields((prev) => ({
        ...prev,
        [name]: value === "" ? "" : moment(value).format(config.elements.dp.A),
      }));
    }
  };
  // props
  let { id, tId, tKey } = useParams();

  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [init, setInit] = useState(0);
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [docsLoading, setDocsLoading] = useState(false);
  const [courseId, setCourseId] = useState(null);
  const [myModal, setMyModal] = useState(null);
  const [avAccord, setAvAccord] = useState("0");
  const [interviewModal, setInterviewModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [modalPayload, setModalPayload] = useState({});
  const [confirm, setConfirm] = useState(null);
  const fileUploadType = {
    otherActivity: "multiple",
    extraCurricular: "multiple",
    passportDocs: "single",
    bankDocs: "single",
    euDocs: "single",
    brpDocs: "single",
    sfeAppDocs: "single",
    homeOfficeDocs: "single",
    poeDocs: "multiple",
    expDocs: "multiple",
    otherQualificationDocs: "multiple",
  };
  console.log({ myModal });
  const [docs, setDocs] = useState({
    otherActivity: [],
    extraCurricular: [],
    otherQualificationDocs: [],
    expDocs: [],
    passportDocs: "",
    euDocs: "",
    brpDocs: "",
    sfeAppDocs: "",
    homeOfficeDocs: "",
    poeDocs: "",
  });

  const [result, setResult] = useState({
    application: {},
    status: [],
    support: [],
  });
  const [cancel, setCancel] = useState({
    reason: "",
    error: false,
    submit: false,
  });
  const [cInterview, setCInterview] = useState({
    cancelReason: "",
    sturescheduledDate: null,
    error: "",
    submit: false,
  });
  const [timeline, setTimline] = useState({});
  const [isIntake, setIsIntake] = useState(false);
  let timelineStatus = {
    ac1: ["fees_payment", "review", "review_missing_info"],
    ac2: [
      "submitted_to_university",
      "conditional_offer_letter",
      "conditional_offer_interview",
      "conditional_offer_missing_info",
      "conditional_offer_mock",
      "unconditional_offer"
    ],
    ac3: [
      "course_payment",
      "sfe_process",
      "course_payment_docs",
      "course_payment_interview",
      "enrolled",
      "visa"
    ],
    ac4: ["cancelled", "rejected"],
  };

  // effect
  useEffect(() => {
    if (!init) {
      initData();
    } else if (init >= 3) {
      setData();
    }
  }, [init]);

  useEffect(() => {
    if (status === "error") {
      common.notify("W", "Something went wrong");
      navigate("/application/list");
    }
  }, [status]);

  // init
  const initData = () => {
    getApplication();
    getStatus();
    getSupport();
  };

  //field to label
  const convertFieldToLabel = (getFiled = "") => {
    const obj = {
      passportDocs: "Passport Docs",
      birthDocs: "Birth Certificate or Proof of ID Docs",
      euDocs: "EU Share Code",
      brpDocs: "Biometric Residence Permit (BRP)",
      sfeAppDocs: "Letter of Approval from SFE / Academic Progress",
      homeOfficeDocs: "Home office letter",
      poeDocs: "Proof of evidence of Stay",
    };

    return obj[getFiled] ?? "";
  };

  // api
  const getApplication = () => {
    let data = {
      url: "application",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, application: res.data }));
        setInit((prev) => ++prev);
        setVerified(res.data.isVerified);
        // let tempDocs = docs;
        // let resData = res?.data?.profile;
        // for (const key in docs) {
        //   if (resData.hasOwnProperty(key)) {
        //     tempDocs[key] = resData[key];
        //   }
        // }
        // setDocs((prev) => ({ ...tempDocs }));
      } else {
        setStatus("error");
      }
    });
  };

  //socket
  useEffect(() => {
    socket.on(`${types?.socket?.APPLICATION_TIMELINE}`, (data) => {
      // if (data?.userID === common.getAuth()?._id) {
      // setInit(0);

      initData();
      // }
    });
  }, [socket]);

  const getStatus = () => {
    let data = {
      url: "application_status",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, status: res.data }));
        setInit((prev) => ++prev);
      } else {
        setStatus("error");
      }
    });
  };

  const getSupport = () => {
    let data = {
      url: "application_support",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, support: res.data }));
        setInit((prev) => ++prev);
      } else {
        setStatus("error");
      }
    });
  };

  const onYes = () => {
    if (fileUploadType[myModal] === "multiple") {
      setDocs((prev) => {
        let nData = { ...prev };
        nData[myModal]?.splice(confirm[1], 1);
        return nData;
      });
    }
    if (fileUploadType[myModal] === "single") {
      setDocs((prev) => ({
        ...prev,
        [myModal]: "",
      }));
    }
    //remove
    setConfirm(null);
  };

  // handler
  const onCancelChange = (e) => {
    const { value } = e.target;
    let error = false;
    if (value === "" && cancel.submit) {
      error = true;
    }
    setCancel((prev) => ({ ...prev, reason: value, error }));
  };

  const onCancelSubmit = () => {
    if (cancel.reason === "") {
      setCancel((prev) => ({ ...prev, error: true, submit: true }));
    } else {
      setLoading("cancel");
      let data = {
        url: "application_status",
        method: "PUT",
        query: `/${id}`,
        body: {
          status: "cancelled",
          description: cancel.reason,
        },
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          setInit(0);
          initData();

          common.notify("S", "Application has been cancelled");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(null);
        setMyModal(null);
      });
    }
  };

  const confirmInterview = (id) => {
    processOnApplication(
      { status: "interview_confirm", id },
      "thank you for your confirmation",
      "confirm"
    );
  };

  const cancelInterview = (id, cancelReason = "", userReqStatus) => {
    processOnApplication(
      {
        status: "interview_cancel",
        id,
        cancelReason,
        userReqStatus,
        action: userReqStatus,
      },
      userReqStatus === "rescheduled"
        ? "Your request has been sent; please wait for a response out from university."
        : "We will proceed with the cancellation of your scheduled interview.",
      "cancelInterview"
    );
  };

  const SubmitBookSlot = (type, id, date) => {
    if (type === "sfeBookSlot") {
      processOnApplication(
        {
          status: "sfe_book_slot",
          id,
          date,
        },
        "Thank you for booking the slot; our admission team will contact you shortly.",
        "BookSlot"
      );
    } else if (type === "mockInTeBookSlot") {
      processOnApplication(
        {
          status: "mock_book_slot",
          id,
          date,
        },
        "Thank you for booking the time slot; please arrive.",
        "BookSlot"
      );
    }
  };

  const processOnApplication = (payload, message = "", loading = "cancel") => {
    setLoading(loading);
    let data = {
      url: "application_status",
      method: "PUT",
      query: `/${id}`,
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setInit(0);
        initData();

        common.notify("S", `${message}`);
      } else {
        common.notify("E", "Something went wrong");
      }
      setLoading(null);
      setMyModal(null);
    });
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  const onSubmitSFE = async (field) => {
    if (docs[field].length) {
      setDocsLoading(true);
      let payload = {};

      if (fileUploadType[field] === "single") {
        let upf = await uploadFile(docs[field]);
        payload[field] = upf?.data?.link[0];
        console.log({ payload });
      } else if (fileUploadType[field] === "multiple") {
        let upf = await uploadFile(docs[field]);
        if ([].includes(field)) {
          payload[field] = [
            ...result?.application?.profile[field],
            {
              title: "CRM required recently uploaded",
              description: "",
              docs: [...upf?.data?.link],
            },
          ];
        } else {
          payload[field] = [
            ...result.application.profile[field],
            ...upf?.data?.link,
          ];
        }
      }
      updateApplicationProfile(payload);
    } else {
      setErrors((prev) => ({ ...prev, [field]: `please upload your docs` }));
    }
  };

  const updateApplicationProfile = (payload) => {
    let data = {
      url: "application_profile",
      query: `/${result?.application?._id}`,
      method: "PUT",
      body: payload,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setInit(0);
        initData();

        common.notify("S", "your document have successfully uploaded");
      } else {
        common.notify("E", "Something went wrong");
      }
      setDocsLoading(false);
      setMyModal(null);
    });
  };

  const gotoProfile = (field) => {
    if (["work", "extra"].includes(field.split("_")?.[0])) {
      if ("work" == field.split("_")?.[0]) {
        return `/application/profile/${id}/workExp`;
      }

      if ("extra" == field.split("_")?.[0]) {
        return `/application/profile/${id}/document`;
      }
    } else {
      return `/application/profile/${id}/${field.split("_")?.[0]}`;
    }
  };

  const missingFiled = (field) => {
    let mFields = "";
    mFields = `${field.split("_")?.[0]} - ${field
      .split("_")?.[1]
      .toLowerCase()}`;
    return mFields;
  };

  const gotoVisa = () => {
    return `/application/profile/${id}/document`;
  };

  // support
  const setData = () => {
    // submission
    let tData = {};
    // timeline
    let ni = [""];
    let status = {
      ac1: ["fees_payment", "review", "review_missing_info"],
      ac2: [
        "submitted_to_university",
        "conditional_offer_letter",
        "conditional_offer_interview",
        "conditional_offer_missing_info",
        "conditional_offer_mock",
        "unconditional_offer"
      ],
      ac3: [
        "course_payment",
        "sfe_process",
        "course_payment_docs",
        "course_payment_interview",
        "enrolled",
        "visa"
      ],
      ac4: ["cancelled", "rejected"],
    };

    let ls = "fees_payment";

    let arr = [];
    result.status.map((stItem, stKey) => {
      if (!ni.includes(stItem.category)) {
        if(stItem.category != "intake_diff"){
        // status
        tData[stItem.category] = stItem;
        tData[stItem.category].support = [];
        ls = stItem.category;
        // support
        result.support.map((spItem) => {
          if (spItem.category === stItem.category) {
            tData[stItem.category].support.push(spItem);
          }
        });
      } else {
        stItem.support = [];
        arr.push(stItem);
        // tData["intake_diff"] = arr;
      }
      }
      const groupedByType = arr.reduce((acc, item) => {
        const type = item.type;
        (acc[type] = acc[type] || []).push(item);
        return acc;
    }, {});
    console.log("===========type", groupedByType);
    tData["intake_diff"] = groupedByType;
    });

    //set Active accordion
    if (status.ac1.includes(ls)) {
      setAvAccord((prev) => "0");
    } else if (status.ac2.includes(ls)) {
      setAvAccord((prev) => "1");
    } else if (status.ac3.includes(ls)) {
      setAvAccord((prev) => "2");
    } else if (status.ac4.includes(ls)) {
      setAvAccord((prev) => "3");
    }

    setTimline((prv) => tData);
    setStatus("success");
  };

  const tlData = (type, statusType = "") => {
    let tl = timeline[type];

    let res = {
      status: tl?.status,
      lineClass: "tl-status-none",
      headClass: "tmh-grey",
      dateAt: tlDate(tl?.updatedAt),
    };

    if (
      [
        "upload",
        "progress",
        "applied",
        "pending",
        "invalid",
        "missing",
        "schedule",
      ].includes(tl?.status)
    ) {
      res.headClass = "tmh-yellow";
    } else if (
      ["rejected", "intake_diff", "notEligible", "cancelled"].includes(
        tl?.status
      )
    ) {
      res.headClass = "tmh-red";
    } else if (["completed", "approved", "accepted"].includes(tl?.status)) {
      res.lineClass = "tl-status-complete";
      if (["Cancellation", "Rejected"].includes(statusType)) {
        res.headClass = "tmh-red";
      } else {
        res.headClass = "tmh-green";
      }
    }
    return res;
  };

  const tlDate = (dt, type = "date") => {
    let result = dt
      ? moment(dt).format("MMM Do YYYY") + " | " + moment(dt).format("hh:mm A")
      : "";
    // return (
    //   moment(dt).format("MMM Do YYYY") + " | " + moment(dt).format("hh:mm A")
    // );
    if (type === "timeSince") {
      return dt ? common.timeSince(new Date(dt)) : "";
    } else {
      return result;
    }
  };

  const tlText = (k) => {
    const res = {
      missing: "Missing",
      under_review: "Under Review",
      reviewed: "Reviewed",
    };
    return res[k];
  };

  const fieldStatus = (st) => {
    let res = "tm-none";
    if (st === "missing") {
      res = "tm-yellow";
    } else if (st === "under_review") {
      res = "tm-yellow";
    }
    return res;
  };

  const statusUi = (status) => {
    if (
      [
        "pending",
        "schedule",
        "scheduled",
        "confirmed",
        "no rescheduling",
        "reschedule unavailable",
        "not attended",
      ].includes(status)
    ) {
      //progress
      return {
        tagLine: "In progress",
        txtColor: "tmt-yellow",
        bgColor: "tm-yellow",
        headerCOlor: "tmh-yellow",
      };
    }
    if (["completed", "attended"].includes(status)) {
      //positive
      return {
        tagLine: "Completed",
        txtColor: "tmt-green",
        bgColor: "tm-green",
        headerCOlor: "tmh-green",
      };
    }
    if (["rescheduled", "cancelled"].includes(status)) {
      //negative
      return {
        tagLine: "Completed",
        txtColor: "tmt-red",
        bgColor: "tm-red",
        headerCOlor: "tmh-red",
      };
    }
  };

  const paymentStatus = (st) => {
    let res = "Pending";
    if (st === "pending") {
      res = "Pay Now";
    } else if (st === "progress") {
      res = "Under Review";
    } else if (st === "invalid") {
      res = "Not Valid";
    } else if (st === "completed") {
      res = "Completed";
    }
    return res;
  };

  const visaStatus = (st) => {
    let res = ["", "", ""];
    if (st === "missing") {
      res = [
        "tmt-yellow",
        "In Progress",
        "Upload your visa copy to confirm your arrrival with the univeristy.",
      ];
    } else if (st === "progress") {
      res = ["tmt-yellow", "In Progress", "We are verifying your visa"];
    } else if (st === "applied") {
      res = [
        "tmt-yellow",
        "In Progress",
        "We are applied visa and waiting for approval",
      ];
    } else if (st === "rejected") {
      res = ["tmt-red", "Rejected", "Your visa has been rejected"];
    } else if (st === "approved") {
      res = ["tmt-green", "Completed", "Your Visa has been approved"];
    }
    return res;
  };

  // props change
  const onCoursePickSuccess = () => {
    setCourseId(null);
    setStatus("process");
    setInit(0);
    initData();
  };

  const applicationUpdate = (name, value) => {
    let data = {
      url: "application",
      query: `/${result?.application?._id}`,
      method: "PUT",
      body: {
        verified: value,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        initData();
        common.notify("S", "your application have successfully verified");
      } else {
        common.notify("E", "Something went wrong");
      }
    });
  };

  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate("/application/list")}
            />
            <h3 className="inner-page-header-title mb-0 me-1">
              Application Progress
            </h3>
          </div>
          <div className="d-flex align-items-center">
            {!timeline.rejected &&
              !timeline.cancelled &&
              status === "success" && (
                <div>
                  <div
                    className="report-issue text-dec-none ed-cp"
                    onClick={() => setMyModal("cancel")}
                  >
                    <span>Cancel Application</span>
                  </div>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <Fragment>
            <div className="application-status-inner-headernew">
              <div className="row">
                <div className="col-md-3 app-status-inner-colnew">
                  <p className="filter-cards-title">Profile</p>
                  <Link
                    to={`/application/info/${id}`}
                    className="application-status-course-name app-profile-name"
                  >
                    {result.application.profile?.firstName}{" "}
                    {result.application.profile?.lastName}
                  </Link>
                </div>
                <div className="col-md-3 app-status-inner-colnew">
                  <p className="filter-cards-title">ID</p>
                  <p className="application-status-course-name">
                    {result.application.refId}
                  </p>
                </div>
                <div className="col-md-3 app-status-inner-colnew">
                  <p className="filter-cards-title">Date and Time</p>
                  <p className="application-status-course-name">
                    {moment(result.application.createdAt).format(
                      "MMMM Do YYYY"
                    )}{" "}
                    | {moment(result.application.createdAt).format("hh:mm A")}
                  </p>
                </div>
                <div className="col-md-3 card-last-col-border d-flex justify-content-end">
                  <div className="d-flex align-items-center mt-0 ms-0 viewdetail">
                    <div className="mt-0 me-3">
                      <Link
                        to={`/application/info/${id}`}
                        className="side-menu-items-link-wrap clr"
                        // target="_blank"
                      >
                        View Details
                      </Link>
                    </div>
                    <div>
                      <Link to={`/application/info/${id}`}>
                        <img
                          src={common.loadImg("blue-arrow-right.svg")}
                          alt=""
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="application-status-inner-headernew">
              <Row>
                <Col md={12} className="p-0">
                  <Row>
                    <Col md={3} className="app-status-inner-coloxford">
                      <p className="filter-cards-title">University</p>
                      <img
                        src={common.cloudImg(
                          result?.application?.course?.university?.logo
                        )}
                        width={80}
                        height={80}
                        className="app-uni-img"
                      />
                    </Col>
                    <Col md={3} className="app-status-inner-coloxford">
                      <p className="filter-cards-title">Course Name</p>
                      <p className="application-status-course-name app-course-txt-bold">
                        {result.application?.course?.name}
                      </p>
                    </Col>

                    <Col md={3} className="app-status-inner-colnewsem">
                      <p className="filter-cards-title">Semester</p>
                      <p className="application-status-course-name app-course-txt-bold">
                        {result?.application?.year}{" "}
                        {
                          common.getMonthList()[result?.application?.month - 1]
                            ?.label
                        }
                      </p>
                    </Col>
                    <Col md={3} className="app-status-inner-colnewsem">
                      <p className="filter-cards-title">Course Fee</p>
                      <p className="application-status-course-name">
                        <span className="app-course-txt-bold">
                          {common.numberWithCommas(
                            Number(result?.application?.course?.tuitionFee)
                          )}
                        </span>{" "}
                        {result?.application?.course?.currency || "GBP"} /
                        Annual
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {common.deCrypt(common.localGet("authData"), true)?.role ==
              "agent" &&
              result?.application &&
              result?.application?.subAgentId && (
                <div className="application-status-inner-headernew">
                  <Row>
                    <Col md={12} className="p-0">
                      <Row>
                        <Col md={3} className="app-status-inner-coloxford">
                          <p className="filter-cards-title">Verify</p>
                          <Switch
                            defaultChecked={verified}
                            name="isVerified"
                            color="secondary"
                            disabled={verified}
                            onChange={(e) => {
                              setVerified(true);
                              if (!verified) {
                                applicationUpdate("isVerified", true);
                              }
                            }}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              )}

            {/* Timeline */}
            <div className="application-statuscheck px-5 pt-4">
              <div className="d-flex align-items-center justify-content-between page-sub-header">
                <p className="inner-page-header-title me-3 mb-0">
                  Application Status
                </p>
                <p className="application-status-id">
                  ID {result?.application?.refId}
                </p>
                {/* {!timeline.rejected &&
                  !timeline.cancelled &&
                  status === "success" && (
                    <div>
                      <div
                        className="report-issue text-dec-none ed-cp"
                        onClick={() => setMyModal("cancel")}
                      >
                        <span>Cancel</span>
                        <img
                          src={common.loadImg("close-icn.svg")}
                          alt=""
                          className="ms-3"
                        />
                      </div>
                    </div>
                  )} */}
              </div>
            </div>

            <div className="px-5 my-4">
              <div className="timeline-wrap position-relative">
                <Accordion activeKey={avAccord} defaultActiveKey={[avAccord]}>
                  {timeline.fees_payment && (
                    <Accordion.Item
                      eventKey="0"
                      onClick={() =>
                        setAvAccord((prv) => (prv === "0" ? null : "0"))
                      }
                    >
                      <Accordion.Header>
                        Pre Application Submission{" "}
                        <span>
                          {tlDate(timeline.fees_payment.updatedAt, "timeSince")}
                        </span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {/* Application fees paid */}
                        {timeline.fees_payment &&
                          ["fees_payment"].map((i, k) => {
                            let td = tlData(i);
                            return (
                              <div
                                className={`timeline-row mb-5 position-relative ${td.lineClass}`}
                                key={k}
                              >
                                <div className="tab-head-wrap d-flex">
                                  <div
                                    className={`ststus-title clr-white ${td.headClass}`}
                                  >
                                    Application Fee
                                  </div>
                                </div>
                                <div className="tab-content-wrap">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                      {td.status === "completed" ? (
                                        <h5 className="tmt-green">Completed</h5>
                                      ) : (
                                        <h5 className="tmt-yellow">
                                          In Progress
                                        </h5>
                                      )}
                                      <p className="tm-date mb-0">
                                        {td.dateAt}
                                      </p>
                                    </div>

                                    <div className="col-md-6 timeline-col timeline-status-content">
                                      <h5>Info</h5>
                                      {td.status === "completed" ? (
                                        <p>Application fees has been paid</p>
                                      ) : (
                                        <p>
                                          Your Application will not be processed
                                          until payment is received.
                                        </p>
                                      )}
                                    </div>
                                    <div className="col-md-3 timeline-col" />
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {/* Review */}
                        {timeline.review &&
                          ["review"].map((i, k) => {
                            let td = tlData(i);
                            return (
                              <div
                                className={`timeline-row mb-5 position-relative ${td.lineClass}`}
                                key={k}
                              >
                                <div className="tab-head-wrap d-flex">
                                  <div
                                    className={`ststus-title clr-white ${td.headClass}`}
                                  >
                                    Application Review
                                  </div>
                                </div>
                                {/* course changed */}

                                {result.application?.log?.length ? (
                                  <div className="tab-content-wrap border-bottom">
                                    <div className="row justify-content-between align-items-center">
                                      <div className="col-md-3 timeline-col timeline-status-info text-center">
                                        <h5 className="tmt-green">Completed</h5>
                                        <span className="ed-tm-status tm-green">
                                          Course Changed
                                        </span>
                                        <p className="tm-date mb-0">
                                          {result?.application?.log[0]
                                            ?.updatedAt
                                            ? tlDate(
                                                result?.application?.log[0]
                                                  ?.updatedAt
                                              )
                                            : ""}
                                        </p>
                                      </div>
                                      <div className="col-md-9 timeline-col timeline-status-content">
                                        <h5>Info</h5>
                                        <p>
                                          Course changed from "
                                          {result.application?.log[0].course?.name?.trim()}
                                          " to "
                                          {result.application?.course?.name?.trim()}
                                          "
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                ) : null}

                                {timeline.review_missing_info &&
                                td.status === "progress" ? (
                                  <div className="tab-content-wrap">
                                    {timeline.review_missing_info.support.map(
                                      (ci, ck) => (
                                        <div
                                          className="row justify-content-between border-bottom align-items-center"
                                          key={ck}
                                        >
                                          <div className="col-md-3 timeline-col timeline-status-info text-center">
                                            {ci.status === "reviewed" ? (
                                              <h5 className="tmt-green">
                                                Completed
                                              </h5>
                                            ) : (
                                              <h5 className="tmt-yellow">
                                                In Progress
                                              </h5>
                                            )}
                                            {ci.status !== "reviewed" ? (
                                              <span
                                                className={`ed-tm-status tm-red`}
                                              >
                                                Missing Information
                                              </span>
                                            ) : (
                                              <span
                                                className={`ed-tm-status tm-green`}
                                              >
                                                Missing Information
                                              </span>
                                            )}
                                            <p className="tm-date mb-0">
                                              {td.dateAt}
                                            </p>
                                          </div>

                                          <div
                                            className={`col-md-6 timeline-col timeline-status-content`}
                                          >
                                            <h5>Info</h5>
                                            <p>{ci.description}</p>
                                          </div>

                                          {/* Status */}
                                          {ci.status === "missing" ? (
                                            <div className="col-md-3 timeline-col complete-border timeline-col-multi">
                                              <div className="d-flex align-items-center justify-content-between">
                                                <Link
                                                  to={
                                                    Boolean(
                                                      ci.field?.match(/modal/)
                                                    )
                                                      ? "#"
                                                      : gotoProfile(ci.field)
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      Boolean(
                                                        ci.field?.match(/modal/)
                                                      )
                                                    ) {
                                                      setMyModal(
                                                        ci.field?.replace(
                                                          "modal_",
                                                          ""
                                                        )
                                                      );
                                                    }
                                                  }}
                                                  className="me-4"
                                                >
                                                  Update Missing Details
                                                </Link>
                                                <Link
                                                  to={
                                                    Boolean(
                                                      ci.field?.match(/modal/)
                                                    )
                                                      ? "#"
                                                      : gotoProfile(ci.field)
                                                  }
                                                  onClick={() => {
                                                    if (
                                                      Boolean(
                                                        ci.field?.match(/modal/)
                                                      )
                                                    ) {
                                                      setMyModal(
                                                        ci.field?.replace(
                                                          "modal_",
                                                          ""
                                                        )
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <img
                                                    src={common.loadImg(
                                                      "blue-arrow-right.svg"
                                                    )}
                                                    alt=""
                                                  />
                                                </Link>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                              <h5>Status</h5>
                                              <span
                                                className={`ed-tm-status ${fieldStatus(
                                                  ci.status
                                                )}`}
                                              >
                                                {tlText(ci.status)}
                                              </span>
                                              <p className="tm-date mb-0">
                                                {tlDate(ci.updatedAt)}
                                              </p>
                                            </div>
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div className="tab-content-wrap">
                                    <div className="row justify-content-between align-items-center">
                                      <div className="col-md-3 timeline-col timeline-status-info text-center">
                                        {td.status === "completed" ? (
                                          <h5 className="tmt-green">
                                            Completed
                                          </h5>
                                        ) : // : td.status === "intake_diff" ? (
                                        //   <h5 className="tmt-red">
                                        //     Intake Differ
                                        //   </h5>
                                        // )
                                        td.status === "notEligible" ? (
                                          <h5 className="tmt-red">
                                            Not Eligible
                                          </h5>
                                        ) : (
                                          <h5 className="tmt-yellow">
                                            In Progress
                                          </h5>
                                        )}
                                        <p className="tm-date mb-0">
                                          {td.dateAt}
                                        </p>
                                      </div>

                                      <div className="col-md-6 timeline-col timeline-status-content">
                                        <h5>Info</h5>
                                        {td.status === "completed" ? (
                                          <p>We Reviewed your application</p>
                                        ) : // : td.status === "intake_diff" ? (
                                        //   <p>{result.application.intakeReason}</p>
                                        // )
                                        td.status === "notEligible" ? (
                                          <p>{result.application.feedBack}</p>
                                        ) : (
                                          <p>
                                            We’re reviewing your application
                                          </p>
                                        )}
                                      </div>
                                      <div className="col-md-3 timeline-col" />
                                    </div>

                                    {timeline.review?.status ===
                                      "notEligible" &&
                                      result.application.suggestedCourses
                                        .length > 0 && (
                                        <div className="p-4">
                                          <div className="progressheader p-0">
                                            <div>
                                              <p className="inner-page-header-title-progress1 mt-1 mb-0">
                                                We Suggest the following courses
                                                based on your profile. Please
                                                select a new course and update
                                                the application
                                              </p>
                                            </div>
                                          </div>

                                          <div>
                                            <ul className="course-list row mt-4">
                                              {result.application.suggestedCourses.map(
                                                (i, k) => (
                                                  <li
                                                    className="col-md-4"
                                                    key={k}
                                                  >
                                                    <div className="suggested-course-card">
                                                      <div>
                                                        <Link
                                                          to="#"
                                                          className="cc-header-link"
                                                        >
                                                          <div className="cc-header">
                                                            <h3
                                                              title={
                                                                i.course.name
                                                              }
                                                              className="cc-header-title"
                                                            >
                                                              {i.course.name}
                                                            </h3>
                                                            <ul className="course-info-list d-flex justify-content-start align-items-center">
                                                              <li className="me-2">
                                                                <img
                                                                  src={common.loadImg(
                                                                    "level-icn.svg"
                                                                  )}
                                                                  alt="level-icon"
                                                                  className="cc-header-icn"
                                                                />
                                                                {
                                                                  i.course
                                                                    .courseLevel
                                                                }
                                                              </li>
                                                              <li>
                                                                <img
                                                                  src={common.loadImg(
                                                                    "duration-icn.svg"
                                                                  )}
                                                                  alt="duration-icon"
                                                                  className="cc-header-icn"
                                                                />
                                                                {
                                                                  i.course
                                                                    .courseDurationType
                                                                }{" "}
                                                                Year
                                                              </li>
                                                            </ul>
                                                          </div>
                                                        </Link>
                                                      </div>
                                                      <div className="cc-body">
                                                        <Link
                                                          to={`/university/info/${i?.course?.university?._id}/p/0`}
                                                          target="_blank"
                                                          className="card-course-title-link"
                                                        >
                                                          <div className="university-info-wrap d-flex justify-content-between align-items-center">
                                                            <div className="university-logo-wrap">
                                                              <img
                                                                src={common.cloudImg(
                                                                  i.course
                                                                    .university
                                                                    ?.logo
                                                                )}
                                                                alt={
                                                                  i.course
                                                                    .university
                                                                    ?.logo
                                                                }
                                                              />
                                                            </div>
                                                            <div className="university-info">
                                                              <h4>
                                                                {
                                                                  i.course
                                                                    .university
                                                                    ?.name
                                                                }
                                                              </h4>
                                                              <p>
                                                                <p>
                                                                  {
                                                                    i?.course
                                                                      ?.university
                                                                      ?.city
                                                                      ?.name
                                                                  }
                                                                </p>
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </Link>
                                                        <div className="course-desc">
                                                          <Link
                                                            className="course-desc-link"
                                                            target="_blank"
                                                            to={`/course/info/${i?.course?._id}/p/0`}
                                                          >
                                                            {
                                                              i?.course
                                                                ?.shortDesc
                                                            }
                                                          </Link>
                                                        </div>
                                                      </div>
                                                      <div className="cc-footer">
                                                        <div className="d-flex justify-content-between align-items-center course-card-footer">
                                                          <div>
                                                            <p className="amount">
                                                              {common.currencyToSymbol(
                                                                i.course
                                                                  ?.currency
                                                              )}{" "}
                                                              {common.numberWithCommas(
                                                                i.course
                                                                  ?.tuitionFee
                                                              )}
                                                            </p>
                                                            <p className="label label-sm">
                                                              Per Annum
                                                            </p>
                                                          </div>
                                                          <div>
                                                            <p
                                                              className="apply-now"
                                                              onClick={() =>
                                                                setCourseId(
                                                                  i.course._id
                                                                )
                                                              }
                                                            >
                                                              Apply Now
                                                            </p>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </li>
                                                )
                                              )}
                                              <li
                                                className="col-md-4 et-cp"
                                                onClick={() =>
                                                  navigate(
                                                    `/course/finder/s/${result?.application?.studentId}:${id}`
                                                  )
                                                }
                                              >
                                                <div className="searchcourse">
                                                  <img
                                                    src={common.loadImg(
                                                      "search-blue-icn.svg"
                                                    )}
                                                    className="mb-1"
                                                  />
                                                  <p className="coursetext1 mb-0">
                                                    Course Finder
                                                  </p>
                                                  <p className="coursetext2">
                                                    of find a course you prefer
                                                  </p>
                                                </div>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      )}

                                    {/* {timeline.review?.status ===
                                      "intake_diff" &&
                                      result.application.intakeDiffer && (
                                        <div
                                        className="d-flex justify-content-between border-bottom align-items-center"
                                        // key={ck}
                                      >
                                        <div className="col-md-3 timeline-col timeline-status-info text-center">
                                          {!result.application.intakeDiffer? (
                                            <h5 className="tmt-green">Completed</h5>
                                          ) : (
                                            <h5 className="tmt-yellow">
                                              In Progress
                                            </h5>
                                          )}
                                          
                                            <span
                                              className={`ed-tm-status tm-green`}
                                            >
                                              Intake Differ
                                            </span>
                                          <p className="tm-date mb-0">
                                            {tlDate(timeline.review.updatedAt)}
                                          </p>
                                        </div>
  
                                        <div
                                          className={`col-md-6 timeline-col timeline-status-content`}
                                        >
                                          <h5>Info</h5>
                                          <p>{result.application.intakeReason} </p>
                                        </div>
  
                                        {result.application.intakeReason ? (
                                          <div className="col-md-3 timeline-col complete-border">
                                            <div className="d-flex align-items-center justify-content-between">
                                              <Link
                                                to="#"
                                                  onClick={() => {
                                                    setCourseId(result?.application?.course?._id);
                                                    setIsIntake(true);
                                                  }
                                                }
                                                className="me-4"
                                              >
                                                Change Intake
                                              </Link>
                                              <Link
                                                to={"#"}
                                              >
                                                <img
                                                  src={common.loadImg(
                                                    "blue-arrow-right.svg"
                                                  )}
                                                  alt=""
                                                />
                                              </Link>
                                            </div>
                                          </div>
                                        ) : (
                                          <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                            <h5>Status</h5>
                                            <span
                                              className={`ed-tm-status ${fieldStatus(
                                                td.status
                                              )}`}
                                            >
                                              Intake Changed
                                            </span>
                                            <p className="tm-date mb-0">
                                              {tlDate(timeline.review.updatedAt)}
                                            </p>
                                          </div>
                                        )}
                                      </div>
                                        // <div className="col-md-3 timeline-col complete-border timeline-col-multi">
                                        //       <div className="d-flex align-items-center justify-content-between">
                                        //         <Link
                                        //           to="#"
                                        //           onClick={() => {
                                        //             setCourseId(
                                        //               result?.application?.course?._id
                                        //             );
                                        //             setIsIntake(true);
                                        //           }}
                                        //         >
                                        //           Change intake year
                                        //         </Link>
                                        //         <Link
                                        //           to={
                                        //             Boolean(
                                        //               ci.field?.match(/modal/)
                                        //             )
                                        //               ? "#"
                                        //               : gotoProfile(ci.field)
                                        //           }
                                        //           onClick={() => {
                                        //             if (
                                        //               Boolean(
                                        //                 ci.field?.match(/modal/)
                                        //               )
                                        //             ) {
                                        //               setMyModal(
                                        //                 ci.field?.replace(
                                        //                   "modal_",
                                        //                   ""
                                        //                 )
                                        //               );
                                        //             }
                                        //           }}
                                        //         >
                                        //           <img
                                        //             src={common.loadImg(
                                        //               "blue-arrow-right.svg"
                                        //             )}
                                        //             alt=""
                                        //           />
                                        //         </Link>
                                        //       </div>
                                        //     </div>
                                      )
                                    } */}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        {/* intake diff */}
                        {timeline.intake_diff && (
                          <>
                            {Object.keys(timeline.intake_diff).map(
                              (item, i) => {
                                return (
                                  <>
                                    {timelineStatus.ac1.includes(item) && (
                                      <div
                                        className={`timeline-row mb-5 position-relative ${
                                          tlData(i).lineClass
                                        }`}
                                        key={i}
                                      >
                                        <div className="tab-head-wrap d-flex">
                                          <div
                                            className={`ststus-title clr-white ${
                                              tlData(i).headClass
                                            }`}
                                          >
                                            Intake Differ
                                          </div>
                                        </div>
                                        {timeline.intake_diff[item].length >
                                          0 &&
                                          timeline.intake_diff[item].map(
                                            (val, k) => {
                                              return (
                                                <div
                                                  className="tab-content-wrap border-bottom"
                                                  key={k}
                                                >
                                                  <div className="row justify-content-between align-items-center">
                                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                                      <h5 className="tmt-green">
                                                        Completed
                                                      </h5>
                                                      <span className="ed-tm-status tm-green">
                                                        intake differ
                                                      </span>
                                                      <p className="tm-date mb-0">
                                                        {val?.updatedAt
                                                          ? tlDate(
                                                              val?.updatedAt
                                                            )
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-md-9 timeline-col timeline-status-content">
                                                      <h5>Info</h5>
                                                      <p>{val.description}</p>
                                                      <p>Reason: {val.attr1}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}

                  <Accordion.Item
                    eventKey="1"
                    onClick={() =>
                      setAvAccord((prv) => (prv === "1" ? null : "1"))
                    }
                  >
                    <Accordion.Header>
                      Application Submission
                      <span>
                        {tlDate(
                          timeline?.submitted_to_university?.updatedAt,
                          "timeSince"
                        )}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {/* Submitted to university */}
                      {timeline.submitted_to_university ? (
                        ["submitted_to_university"].map((i, k) => {
                          let td = tlData(i);
                          return (
                            <div
                              className={`timeline-row mb-5 position-relative ${td.lineClass}`}
                              key={k}
                            >
                              <div className="tab-head-wrap d-flex">
                                <div
                                  className={`ststus-title clr-white ${td.headClass}`}
                                >
                                  Submitted To University
                                </div>
                              </div>
                              <div className="tab-content-wrap">
                                <div className="row justify-content-between align-items-center">
                                  <div className="col-md-3 timeline-col timeline-status-info text-center">
                                    {td.status === "completed" ? (
                                      <h5 className="tmt-green">Completed</h5>
                                    ) : (
                                      <h5 className="tmt-yellow">
                                        In Progress
                                      </h5>
                                    )}
                                    <p className="tm-date mb-0">{td.dateAt}</p>
                                  </div>

                                  <div className="col-md-6 timeline-col timeline-status-content">
                                    <h5>Info</h5>
                                    {td.status === "completed" ? (
                                      <p>
                                        Your application has been processed by
                                        university
                                      </p>
                                    ) : (
                                      <p>
                                        Your application has been submitted to
                                        the university for processing
                                      </p>
                                    )}
                                  </div>
                                  <div className="col-md-3 timeline-col" />
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="timeline-row-gray mb-5 position-relative">
                          <div className="tab-head-wrap d-flex">
                            <div className="ststus-title clr-white tmh-grey">
                              Submitted To University
                            </div>
                          </div>
                          <div className="tab-content-wrap">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-md-3 timeline-col timeline-status-info text-center">
                                <h5>Not Started</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Conditional offer */}
                      {(timeline.conditional_offer_letter ||
                        timeline.conditional_offer_interview ||
                        timeline.unconditional_offer ||
                        timeline.conditional_offer_missing_info) && (
                        <div className="timeline-row position-relative mb-5 tl-status-complete">
                          <div className="tab-head-wrap d-flex">
                            <div className="ststus-title clr-white tmh-green">
                              Offer Letter
                            </div>
                          </div>
                          <div className="tab-content-wrap">
                            {/* Offer letter */}
                            {timeline.conditional_offer_letter &&
                              ["conditional_offer_letter"].map((i, k) => {
                                let td = tlData(i);
                                return (
                                  <div
                                    className="row justify-content-between border-bottom align-items-center"
                                    key={k}
                                  >
                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                      <h5 className="tmt-green">Completed</h5>
                                      <span className="ed-tm-status tm-green">
                                        Conditional Offer
                                      </span>
                                      <p className="tm-date mb-0">
                                        {td.dateAt}
                                      </p>
                                    </div>

                                    <div className="col-md-6 timeline-col timeline-status-content">
                                      <h5>Info</h5>
                                      <p>
                                        Please download your Conditional offer
                                        letter
                                      </p>
                                    </div>
                                    <div className="col-md-3 timeline-col complete-border">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Link
                                          to=""
                                          onClick={() =>
                                            common.openCloudUrl(
                                              timeline[i].support[0].offerLetter
                                            )
                                          }
                                          className="me-4"
                                        >
                                          Download
                                        </Link>
                                        <Link
                                          to=""
                                          onClick={() =>
                                            common.openCloudUrl(
                                              timeline[i].support[0].offerLetter
                                            )
                                          }
                                        >
                                          <img
                                            src={common.loadImg(
                                              "blue-arrow-right.svg"
                                            )}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}

                            {/* Conditional missing info */}
                            {timeline.conditional_offer_missing_info &&
                              timeline.conditional_offer_missing_info.support.map(
                                (ci, ck) => (
                                  <div
                                    className="d-flex justify-content-between border-bottom align-items-center"
                                    key={ck}
                                  >
                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                      {ci.status === "reviewed" ? (
                                        <h5 className="tmt-green">Completed</h5>
                                      ) : (
                                        <h5 className="tmt-yellow">
                                          In Progress
                                        </h5>
                                      )}
                                      {ci.status !== "reviewed" ? (
                                        <span className={`ed-tm-status tm-red`}>
                                          Missing Information
                                        </span>
                                      ) : (
                                        <span
                                          className={`ed-tm-status tm-green`}
                                        >
                                          Missing Information
                                        </span>
                                      )}
                                      <p className="tm-date mb-0">
                                        {tlDate(ci.updatedAt)}
                                      </p>
                                    </div>

                                    <div
                                      className={`col-md-6 timeline-col timeline-status-content`}
                                    >
                                      <h5>Info</h5>
                                      <p>{ci.description} </p>
                                    </div>

                                    {ci.status === "missing" ? (
                                      <div className="col-md-3 timeline-col complete-border">
                                        <div className="d-flex align-items-center justify-content-between">
                                          <Link
                                            to={
                                              Boolean(ci.field?.match(/modal/))
                                                ? "#"
                                                : gotoProfile(ci.field)
                                            }
                                            onClick={() => {
                                              if (
                                                Boolean(
                                                  ci.field?.match(/modal/)
                                                )
                                              ) {
                                                setMyModal(
                                                  ci.field?.replace(
                                                    "modal_",
                                                    ""
                                                  )
                                                );
                                              }
                                            }}
                                            className="me-4"
                                          >
                                            Update Missing Details
                                          </Link>
                                          <Link
                                            to={
                                              Boolean(ci.field?.match(/modal/))
                                                ? "#"
                                                : gotoProfile(ci.field)
                                            }
                                            onClick={() => {
                                              if (
                                                Boolean(
                                                  ci.field?.match(/modal/)
                                                )
                                              ) {
                                                setMyModal(
                                                  ci.field?.replace(
                                                    "modal_",
                                                    ""
                                                  )
                                                );
                                              }
                                            }}
                                          >
                                            <img
                                              src={common.loadImg(
                                                "blue-arrow-right.svg"
                                              )}
                                              alt=""
                                            />
                                          </Link>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                        <h5>Status</h5>
                                        <span
                                          className={`ed-tm-status ${fieldStatus(
                                            ci.status
                                          )}`}
                                        >
                                          {tlText(ci.status)}
                                        </span>
                                        <p className="tm-date mb-0">
                                          {tlDate(ci.updatedAt)}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                )
                              )}

                            {/* Interview */}
                            {timeline.conditional_offer_interview &&
                              timeline?.conditional_offer_interview?.array1.map(
                                (i, k) => {
                                  let td = tlData(i);
                                  return (
                                    <div
                                      className="d-flex justify-content-between border-bottom align-items-center"
                                      key={k}
                                      style={{ backgroundColor: "#FEF3DF" }}
                                    >
                                      <div className="col-md-3 timeline-col timeline-status-info text-center">
                                        {/* <p className="tm-date mb-0"> */}
                                        <h5
                                          className={
                                            statusUi(i?.status?.toLowerCase())
                                              ?.txtColor
                                          }
                                        >
                                          {
                                            statusUi(i?.status?.toLowerCase())
                                              ?.tagLine
                                          }
                                        </h5>

                                        <span
                                          className={`ed-tm-status ${
                                            statusUi(i?.status?.toLowerCase())
                                              ?.bgColor
                                          }`}
                                        >
                                          {i?.type}
                                        </span>
                                        <p className="tm-date mb-0">
                                          {td?.dateAt}
                                        </p>
                                        {/* </p> */}
                                      </div>

                                      <div className="col-md-6 timeline-col timeline-status-content">
                                        <h5>Info</h5>
                                        <p>
                                          {i?.type}({i?.mode}) -{" "}
                                          {i?.description}
                                        </p>
                                        <div>
                                          {i?.mode?.toLowerCase() ===
                                          "online" ? (
                                            <p>
                                              Your Interview is on the{" "}
                                              {moment(new Date(i?.date)).format(
                                                "YYYY-MM-DD hh:mm A"
                                              )}{" "}
                                              <a
                                                className="link"
                                                href={i?.venue}
                                                target="_blank"
                                              >
                                                {i?.venue}
                                              </a>
                                            </p>
                                          ) : (
                                            <p>
                                              Your Interview is on the{" "}
                                              {moment(new Date(i?.date)).format(
                                                "YYYY-MM-DD hh:mm A"
                                              )}{" "}
                                              <a
                                                className="link"
                                                href={i?.venue}
                                                target="_blank"
                                              >
                                                view location
                                              </a>
                                            </p>
                                          )}
                                          {i?.userReqStatus?.toLowerCase() ===
                                            "rescheduled" && (
                                            <p className="mt-2">
                                              <b>
                                                You have requested to Reschedule
                                                the interview, and we are
                                                working on it; please keep in
                                                contact until the university
                                                responds.
                                              </b>
                                            </p>
                                          )}
                                          {i?.cancelReason && (
                                            <p className="mt-2">
                                              <b>Your reason: </b>
                                              {i?.cancelReason}{" "}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                        <h5>Status</h5>
                                        <span
                                          className={`ed-tm-status ${fieldStatus(
                                            timeline[i]?.status
                                          )}`}
                                          style={{
                                            textTransform: "capitalize",
                                          }}
                                        >
                                          {i?.status}
                                        </span>
                                        <p className="tm-date mb-0 d-flex align-items-center justify-content-center flex-wrap">
                                          {tlDate(timeline[i]?.updatedAt)}
                                          {[
                                            "schedule",
                                            "scheduled",
                                            "no rescheduling",
                                            "reschedule unavailable",
                                          ]?.includes(
                                            i?.status?.toLowerCase()
                                          ) && (
                                            <>
                                              <button
                                                className="btn btn-confirm me-2"
                                                onClick={() => {
                                                  // confirmInterview(i?.id)
                                                  setModalPayload((prev) => ({
                                                    id: i?.id,
                                                    interviewType: i?.type,
                                                    interviewMode: i?.mode,
                                                    description: i?.description,
                                                    dateTime: tlDate(i?.date),
                                                  }));
                                                  setMyModal(
                                                    "confirmInterview"
                                                  );
                                                }}
                                              >
                                                confirm
                                              </button>
                                              {!["confirmed"]?.includes(
                                                i?.status?.toLowerCase()
                                              ) && (
                                                <button
                                                  className="btn btn-red-txt me-2"
                                                  onClick={() => {
                                                    setModalPayload((prev) => ({
                                                      id: i?.id,
                                                      dateTime: tlDate(i?.date),
                                                      rescheduled: false,
                                                      avoidrescheduled:
                                                        common.getRepeatedObj(
                                                          timeline
                                                            ?.conditional_offer_interview
                                                            ?.array1,
                                                          "userReqStatus",
                                                          "Rescheduled"
                                                        ).length,
                                                    }));
                                                    setMyModal(
                                                      `cancelInterview`
                                                    );
                                                  }}
                                                >
                                                  cancel
                                                </button>
                                              )}
                                            </>
                                          )}
                                          {["not attended"]?.includes(
                                            i?.status?.toLowerCase()
                                          ) &&
                                            i?.userReqStatus?.toLowerCase() !==
                                              "rescheduled" &&
                                            common.getRepeatedObj(
                                              timeline
                                                ?.conditional_offer_interview
                                                ?.array1,
                                              "userReqStatus",
                                              "Rescheduled"
                                            ).length < 2 && (
                                              <button
                                                className="btn btn-outline-success me-2"
                                                onClick={() => {
                                                  setModalPayload((prev) => ({
                                                    id: i?.id,
                                                    dateTime: tlDate(i?.date),
                                                    rescheduled: true,
                                                  }));
                                                  setMyModal(`cancelInterview`);
                                                }}
                                              >
                                                rescheduled
                                              </button>
                                            )}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                            {/* Mock Interview */}
                            {timeline.conditional_offer_mock &&
                              timeline?.conditional_offer_mock?.array1.map(
                                (i, k) => {
                                  let td = tlData(i);
                                  return (
                                    <div
                                      className="d-flex justify-content-between border-bottom align-items-center"
                                      key={k}
                                    >
                                      <div className="col-md-3 timeline-col timeline-status-info text-center">
                                        {/* <p className="tm-date mb-0"> */}
                                        {["completed", "attended"].includes(
                                          i?.status?.toLowerCase()
                                        ) ? (
                                          <h5 className="tmt-green">
                                            Completed
                                          </h5>
                                        ) : [
                                            "not attended",
                                            "cancelled",
                                          ].includes(
                                            i?.status?.toLowerCase()
                                          ) ? (
                                          <h5 className="tmt-red">completed</h5>
                                        ) : (
                                          <h5 className="tmt-yellow">
                                            In Progress
                                          </h5>
                                        )}
                                        {["completed", "attended"].includes(
                                          i?.status?.toLowerCase()
                                        ) ? (
                                          <span
                                            className={`ed-tm-status tm-green `}
                                          >
                                            {i?.type}
                                          </span>
                                        ) : [
                                            "not attended",
                                            "cancelled",
                                          ].includes(
                                            i?.status?.toLowerCase()
                                          ) ? (
                                          <span
                                            className={`ed-tm-status tm-red`}
                                          >
                                            {i?.type}
                                          </span>
                                        ) : (
                                          <span
                                            className={`ed-tm-status tm-yellow`}
                                          >
                                            {i?.type}
                                          </span>
                                        )}
                                        <p className="tm-date mb-0">
                                          {td?.dateAt}
                                        </p>
                                        {/* </p> */}
                                      </div>

                                      <div className="col-md-6 timeline-col timeline-status-content">
                                        <h5>Info</h5>
                                        <p>
                                          {i?.type}({i?.mode}) -{" "}
                                          {i?.description}
                                        </p>
                                        <div>
                                          {i?.mode?.toLowerCase() ==
                                          "online" ? (
                                            <p>
                                              <a
                                                className="link"
                                                href={i?.venue}
                                                target="_blank"
                                              >
                                                {i?.venue}
                                              </a>
                                            </p>
                                          ) : (
                                            <p>
                                              your interview @{" "}
                                              <a
                                                className="link"
                                                href={i?.venue}
                                                target="_blank"
                                              >
                                                view location
                                              </a>
                                            </p>
                                          )}
                                          {Boolean(i?.bookedSlot?.length) && (
                                            <p>
                                              <b>
                                                Your have Booked the Slot @{" "}
                                                {moment(
                                                  new Date(i?.bookedSlot)
                                                ).format("YYYY-MM-DD hh:mm A")}
                                              </b>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                        <h5>Status</h5>
                                        <span
                                          className={`ed-tm-status ${fieldStatus(
                                            timeline[i]?.status
                                          )}`}
                                        >
                                          {i?.status}
                                        </span>
                                        {i?.status?.toLowerCase() ===
                                          "pending" && (
                                          <button
                                            onClick={() => {
                                              setModalPayload((prev) => ({
                                                id: i?.id,
                                                type: "mockInTeBookSlot",
                                                timelineSlot: i?.slot,
                                              }));
                                              setMyModal("BookSlot");
                                            }}
                                            className="btn btn-book"
                                          >
                                            Book Slot
                                          </button>
                                        )}

                                        <p className="tm-date mb-0">
                                          {tlDate(timeline[i]?.updatedAt)}
                                        </p>
                                      </div>
                                    </div>
                                  );
                                }
                              )}

                            {/* Unconditional offer */}
                            {timeline.unconditional_offer &&
                              ["unconditional_offer"].map((i, k) => {
                                let td = tlData(i);
                                return (
                                  <div
                                    className="row justify-content-between border-bottom align-items-center"
                                    key={k}
                                  >
                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                      <h5 className="tmt-green">Completed</h5>
                                      <span className="ed-tm-status tm-green">
                                        Unconditional Offer
                                      </span>
                                      <p className="tm-date mb-0">
                                        {td.dateAt}
                                      </p>
                                    </div>

                                    <div className="col-md-6 timeline-col timeline-status-content">
                                      <h5>Info</h5>
                                      <p>
                                        Please download your Unconditional offer
                                        letter
                                      </p>
                                    </div>
                                    <div className="col-md-3 timeline-col timeline-col-multi complete-border">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Link
                                          to=""
                                          onClick={() =>
                                            common.openCloudUrl(
                                              timeline[i].support[0].offerLetter
                                            )
                                          }
                                          className="me-4"
                                        >
                                          Download
                                        </Link>
                                        <Link
                                          to=""
                                          onClick={() =>
                                            common.openCloudUrl(
                                              timeline[i].support[0].offerLetter
                                            )
                                          }
                                        >
                                          <img
                                            src={common.loadImg(
                                              "blue-arrow-right.svg"
                                            )}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      )}

                      {!timeline.conditional_offer_letter &&
                        !timeline.conditional_offer_mock &&
                        !timeline.conditional_offer_interview &&
                        !timeline.conditional_offer_missing_info &&
                        !timeline.unconditional_offer && (
                          <div className="timeline-row-gray mb-5 position-relative">
                            <div className="tab-head-wrap d-flex">
                              <div className="ststus-title clr-white tmh-grey">
                                Offer Letter
                              </div>
                            </div>
                            <div className="tab-content-wrap">
                              <div className="row justify-content-between align-items-center">
                                <div className="col-md-3 timeline-col timeline-status-info text-center">
                                  <h5>Not Started</h5>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                      {/* intake diff */}
                      {timeline.intake_diff && (
                          <>
                            {Object.keys(timeline.intake_diff).map(
                              (item, i) => {
                                return (
                                  <>
                                    {timelineStatus.ac2.includes(item) && (
                                      <div
                                        className={`timeline-row mb-5 position-relative ${
                                          tlData(i).lineClass
                                        }`}
                                        key={i}
                                      >
                                        <div className="tab-head-wrap d-flex">
                                          <div
                                            className={`ststus-title clr-white ${
                                              tlData(i).headClass
                                            }`}
                                          >
                                            Intake Differ
                                          </div>
                                        </div>
                                        {timeline.intake_diff[item].length >
                                          0 &&
                                          timeline.intake_diff[item].map(
                                            (val, k) => {
                                              return (
                                                <div
                                                  className="tab-content-wrap border-bottom"
                                                  key={k}
                                                >
                                                  <div className="row justify-content-between align-items-center">
                                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                                      <h5 className="tmt-green">
                                                        Completed
                                                      </h5>
                                                      <span className="ed-tm-status tm-green">
                                                        intake differ
                                                      </span>
                                                      <p className="tm-date mb-0">
                                                        {val?.updatedAt
                                                          ? tlDate(
                                                              val?.updatedAt
                                                            )
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-md-9 timeline-col timeline-status-content">
                                                      <h5>Info</h5>
                                                      <p>{val.description}</p>
                                                      <p>Reason: {val.attr1}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item
                    eventKey="2"
                    onClick={() =>
                      setAvAccord((prv) => (prv === "2" ? null : "2"))
                    }
                  >
                    <Accordion.Header>
                      Post Application Submission
                      <span>
                        {tlDate(
                          timeline?.course_payment?.updatedAt,
                          "timeSince"
                        )}
                      </span>
                    </Accordion.Header>
                    <Accordion.Body>
                      {/* SFE  */}
                      {timeline.sfe_process ? (
                        <div
                          className={`timeline-row position-relative mb-5 ${
                            timeline?.sfe_process?.status?.toLowerCase() !==
                            "completed"
                              ? "tl-status-progress"
                              : "tl-status-complete"
                          } `}
                        >
                          <div className="tab-head-wrap d-flex">
                            <div
                              className={`ststus-title clr-white ${
                                timeline?.sfe_process?.status?.toLowerCase() !==
                                "completed"
                                  ? "tmh-yellow"
                                  : "tmh-green"
                              } `}
                            >
                              Student Funding
                            </div>
                          </div>
                          <div className="tab-content-wrap">
                            <div className="row justify-content-between border-bottom align-items-center">
                              <div className="col-md-3 timeline-col timeline-status-info text-center">
                                <h5
                                  className={
                                    timeline?.sfe_process?.status?.toLowerCase() !==
                                    "completed"
                                      ? "tmt-yellow"
                                      : "tmt-green"
                                  }
                                >
                                  {timeline?.sfe_process?.status.toLowerCase() ===
                                  "completed"
                                    ? "Completed"
                                    : "In progress"}
                                </h5>
                                <span
                                  className={`ed-tm-status ${
                                    timeline?.sfe_process?.status?.toLowerCase() !==
                                    "completed"
                                      ? "tm-yellow"
                                      : "tm-green"
                                  }`}
                                >
                                  SFE loan
                                </span>
                                {/* <p className="tm-date mb-0">10-10-2023</p> */}
                              </div>

                              <div className="col-md-6 timeline-col timeline-status-content">
                                <h5>Info</h5>
                                <p>
                                  {timeline?.sfe_process?.status.toLowerCase() ===
                                  "pending"
                                    ? "Please book a slot, to complete the SFE Application."
                                    : timeline?.sfe_process?.status.toLowerCase() ===
                                      "booked"
                                    ? `Our Admissions team will contact ${
                                        authInfo.role === "student"
                                          ? " you shortly"
                                          : "the student shortly"
                                      } `
                                    : "Your SFE Application is completed."}
                                </p>
                                <p>
                                  {timeline?.sfe_process?.status.toLowerCase() ===
                                    "booked" && timeline?.sfe_process?.date ? (
                                    <b>
                                      Your have Booked the Slot @{" "}
                                      {moment(
                                        new Date(timeline?.sfe_process?.date)
                                      ).format("YYYY-MM-DD hh:mm A")}{" "}
                                    </b>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                              <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                <h5>Status</h5>
                                <span
                                  className={`ed-tm-status ${fieldStatus(
                                    timeline?.sfe_process?.status
                                  )}`}
                                  style={{
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {timeline?.sfe_process?.status}
                                </span>
                                <p className="tm-date mb-0">
                                  {tlDate(timeline?.sfe_process?.updatedAt)}

                                  {timeline?.sfe_process?.date == null &&
                                    timeline?.sfe_process?.status.toLowerCase() ===
                                      "pending" &&
                                    Boolean(
                                      timeline?.sfe_process?.array1?.length
                                    ) && (
                                      <button
                                        onClick={() => {
                                          setModalPayload((prev) => ({
                                            id: 1,
                                            type: "sfeBookSlot",
                                            timelineSlot:
                                              timeline?.sfe_process?.array1,
                                          }));
                                          setMyModal("BookSlot");
                                        }}
                                        className="btn btn-outline-success me-2"
                                      >
                                        Book Slot
                                      </button>
                                    )}
                                </p>
                              </div>
                            </div>
                            {timeline.sfe_process &&
                              timeline.sfe_process?.status.toLowerCase() !==
                                "completed" &&
                              timeline.sfe_process.support.map((ci, ck) => (
                                <div
                                  className="d-flex justify-content-between border-bottom align-items-center"
                                  key={ck}
                                >
                                  <div className="col-md-3 timeline-col timeline-status-info text-center">
                                    {ci.status === "reviewed" ? (
                                      <h5 className="tmt-green">Completed</h5>
                                    ) : (
                                      <h5 className="tmt-yellow">
                                        In Progress
                                      </h5>
                                    )}
                                    {ci.status !== "reviewed" ? (
                                      <span className={`ed-tm-status tm-red`}>
                                        Missing Information
                                      </span>
                                    ) : (
                                      <span className={`ed-tm-status tm-green`}>
                                        Missing Information
                                      </span>
                                    )}
                                    <p className="tm-date mb-0">
                                      {tlDate(ci.updatedAt)}
                                    </p>
                                  </div>

                                  <div
                                    className={`col-md-6 timeline-col timeline-status-content`}
                                  >
                                    <h5>Info</h5>
                                    <p>{ci.description}</p>
                                  </div>

                                  {ci.status === "missing" ? (
                                    <div className="col-md-3 timeline-col complete-border">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Link
                                          to={
                                            Boolean(ci.field?.match(/modal/))
                                              ? "#"
                                              : gotoProfile(ci.field)
                                          }
                                          onClick={() => {
                                            if (
                                              Boolean(ci.field?.match(/modal/))
                                            ) {
                                              setMyModal(
                                                ci.field?.replace("modal_", "")
                                              );
                                            }
                                          }}
                                          className="me-4"
                                        >
                                          Update Missing Details
                                        </Link>
                                        <Link
                                          to={
                                            Boolean(ci.field?.match(/modal/))
                                              ? "#"
                                              : gotoProfile(ci.field)
                                          }
                                          onClick={() => {
                                            if (
                                              Boolean(ci.field?.match(/modal/))
                                            ) {
                                              setMyModal(
                                                ci.field?.replace("modal_", "")
                                              );
                                            }
                                          }}
                                        >
                                          <img
                                            src={common.loadImg(
                                              "blue-arrow-right.svg"
                                            )}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-md-3 timeline-col timeline-status-info text-center complete-border">
                                      <h5>Status</h5>
                                      <span
                                        className={`ed-tm-status ${fieldStatus(
                                          ci.status
                                        )}`}
                                      >
                                        {tlText(ci.status)}
                                      </span>
                                      <p className="tm-date mb-0">
                                        {tlDate(ci.updatedAt)}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              ))}
                          </div>
                        </div>
                      ) : (
                        <div className="timeline-row-gray mb-5 position-relative">
                          <div className="tab-head-wrap d-flex">
                            <div className="ststus-title clr-white tmh-grey">
                              Student Funding
                            </div>
                          </div>
                          <div className="tab-content-wrap">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-md-3 timeline-col timeline-status-info text-center">
                                <h5>Not Started</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* Enrolled */}
                      {timeline.enrolled ? (
                        ["enrolled"].map((i, k) => {
                          let td = tlData(i);
                          return (
                            <div
                              className={`timeline-row position-relative mb-5  ${
                                timeline?.enrolled?.status !== "completed"
                                  ? "tl-status-progress"
                                  : "tl-status-complete"
                              }`}
                              key={k}
                            >
                              <div className="tab-head-wrap d-flex">
                                <div
                                  className={`ststus-title clr-white ${
                                    timeline?.enrolled?.status?.toLowerCase() ===
                                    "completed"
                                      ? "tmh-green"
                                      : "tmh-yellow"
                                  }`}
                                >
                                  Enrollment
                                </div>
                              </div>
                              <div className="tab-content-wrap">
                                <div className="row justify-content-between border-bottom align-items-center">
                                  <div className="col-md-3 timeline-col timeline-status-info text-center">
                                    <h5
                                      className={
                                        timeline?.enrolled?.status ===
                                        "completed"
                                          ? "tmt-green"
                                          : "tmt-yellow"
                                      }
                                    >
                                      {timeline?.enrolled?.status}
                                    </h5>
                                    <p className="tm-date mb-0">{td.dateAt}</p>
                                  </div>

                                  <div
                                    className={
                                      authInfo.role === "student"
                                        ? "col-md-9 timeline-col timeline-status-content"
                                        : "col-md-9 timeline-col timeline-status-content"
                                    }
                                  >
                                    <h5>Info</h5>
                                    {authInfo.role === "student" ? (
                                      <p>
                                        {timeline?.enrolled?.status?.toLowerCase() ===
                                        "completed"
                                          ? "Congratulations on your Enrolment! We wish you Good Luck"
                                          : "Almost there! We are awaiting feedback from Student Finance England "}
                                      </p>
                                    ) : (
                                      <p>
                                        {timeline?.enrolled?.status?.toLowerCase() ===
                                        "completed"
                                          ? "Congratulations! Your student has successfully enrolled"
                                          : "Almost there! We are awaiting feedback from Student Finance England "}
                                      </p>
                                    )}
                                  </div>
                                  {/* {authInfo.role === "student" && (
                                    <div className="col-md-3 timeline-col complete-border">
                                      <div className="d-flex align-items-center justify-content-between">
                                        <Link to="" className="me-4">
                                          Claim Cashback
                                        </Link>
                                        <Link to="">
                                          <img
                                            src={common.loadImg(
                                              "blue-arrow-right.svg"
                                            )}
                                            alt=""
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  )} */}
                                </div>
                              </div>
                            </div>
                          );
                        })
                      ) : (
                        <div className="timeline-row-gray mb-5 position-relative">
                          <div className="tab-head-wrap d-flex">
                            <div className="ststus-title clr-white tmh-grey">
                              Enrollment
                            </div>
                          </div>
                          <div className="tab-content-wrap">
                            <div className="row justify-content-between align-items-center">
                              <div className="col-md-3 timeline-col timeline-status-info text-center">
                                <h5>Not Started</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      {/* intake diff */}
                      {timeline.intake_diff && (
                          <>
                            {Object.keys(timeline.intake_diff).map(
                              (item, i) => {
                                return (
                                  <>
                                    {timelineStatus.ac3.includes(item) && (
                                      <div
                                        className={`timeline-row mb-5 position-relative ${
                                          tlData(i).lineClass
                                        }`}
                                        key={i}
                                      >
                                        <div className="tab-head-wrap d-flex">
                                          <div
                                            className={`ststus-title clr-white ${
                                              tlData(i).headClass
                                            }`}
                                          >
                                            Intake Differ
                                          </div>
                                        </div>
                                        {timeline.intake_diff[item].length >
                                          0 &&
                                          timeline.intake_diff[item].map(
                                            (val, k) => {
                                              return (
                                                <div
                                                  className="tab-content-wrap border-bottom"
                                                  key={k}
                                                >
                                                  <div className="row justify-content-between align-items-center">
                                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                                      <h5 className="tmt-green">
                                                        Completed
                                                      </h5>
                                                      <span className="ed-tm-status tm-green">
                                                        intake differ
                                                      </span>
                                                      <p className="tm-date mb-0">
                                                        {val?.updatedAt
                                                          ? tlDate(
                                                              val?.updatedAt
                                                            )
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-md-9 timeline-col timeline-status-content">
                                                      <h5>Info</h5>
                                                      <p>{val.description}</p>
                                                      <p>Reason: {val.attr1}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                    </Accordion.Body>
                  </Accordion.Item>

                  {(timeline.cancelled || timeline.rejected) && (
                    <Accordion.Item
                      eventKey="3"
                      onClick={() =>
                        setAvAccord((prv) => (prv === "3" ? null : "3"))
                      }
                    >
                      <Accordion.Header>
                        Reject & Cancellation
                        <span>{tlDate(new Date())}</span>
                      </Accordion.Header>
                      <Accordion.Body>
                        {/* intake diff */}
                        {timeline.intake_diff && (
                          <>
                            {Object.keys(timeline.intake_diff).map(
                              (item, i) => {
                                return (
                                  <>
                                    {timelineStatus.ac4.includes(item) && (
                                      <div
                                        className={`timeline-row mb-5 position-relative ${
                                          tlData(i).lineClass
                                        }`}
                                        key={i}
                                      >
                                        <div className="tab-head-wrap d-flex">
                                          <div
                                            className={`ststus-title clr-white ${
                                              tlData(i).headClass
                                            }`}
                                          >
                                            Intake Differ
                                          </div>
                                        </div>
                                        {timeline.intake_diff[item].length >
                                          0 &&
                                          timeline.intake_diff[item].map(
                                            (val, k) => {
                                              return (
                                                <div
                                                  className="tab-content-wrap border-bottom"
                                                  key={k}
                                                >
                                                  <div className="row justify-content-between align-items-center">
                                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                                      <h5 className="tmt-green">
                                                        Completed
                                                      </h5>
                                                      <span className="ed-tm-status tm-green">
                                                        intake differ
                                                      </span>
                                                      <p className="tm-date mb-0">
                                                        {val?.updatedAt
                                                          ? tlDate(
                                                              val?.updatedAt
                                                            )
                                                          : ""}
                                                      </p>
                                                    </div>
                                                    <div className="col-md-9 timeline-col timeline-status-content">
                                                      <h5>Info</h5>
                                                      <p>{val.description}</p>
                                                      <p>Reason: {val.attr1}</p>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                      </div>
                                    )}
                                  </>
                                );
                              }
                            )}
                          </>
                        )}
                        {/* Cancel request */}
                        {timeline.cancelled &&
                          ["cancelled"].map((i, k) => {
                            let td = tlData(i, "Cancellation");
                            return (
                              <div
                                className={
                                  td.lineClass == "tl-status-none"
                                    ? `timeline-row mb-5 position-relative ${td.lineClass}`
                                    : `timeline-row mb-5 position-relative tl-status-complete-negative`
                                }
                                key={k}
                              >
                                <div className="tab-head-wrap d-flex">
                                  <div
                                    className={`ststus-title clr-white ${td.headClass}`}
                                  >
                                    Cancellation
                                  </div>
                                </div>
                                <div className="tab-content-wrap">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                      {td.status === "progress" ? (
                                        <h5 className="tmt-yellow">
                                          In Progress
                                        </h5>
                                      ) : td.status === "accepted" ? (
                                        <h5 className="tmt-red">Accepted</h5>
                                      ) : (
                                        td.status === "rejected" && (
                                          <h5 className="tmt-red">Rejected</h5>
                                        )
                                      )}
                                      <p className="tm-date mb-0">
                                        {td.dateAt}
                                      </p>
                                    </div>

                                    <div className="col-md-6 timeline-col timeline-status-content">
                                      <h5>Info</h5>
                                      {td.status === "progress" ? (
                                        <p>
                                          Your cancellation request in review
                                          <br />
                                          <span>Reason:</span>{" "}
                                          <span className="text-gray">
                                            {timeline.cancelled.description}
                                          </span>
                                        </p>
                                      ) : td.status === "accepted" ? (
                                        <p>
                                          Your Application cancellation request
                                          has been accepted.
                                        </p>
                                      ) : td.status === "completed" ? (
                                        <p>
                                          <span>Reason:</span>{" "}
                                          {timeline.cancelled.description}
                                          <br />
                                          <span className="text-gray">
                                            {timeline?.cancelled?.attr1
                                              ? "Application canceled by Pickauni  "
                                              : ""}
                                          </span>
                                        </p>
                                      ) : (
                                        td.status === "rejected" && (
                                          <p>
                                            Your cancellation request has been
                                            rejected due to the below reason
                                            <br />
                                            <u>Reason: </u>{" "}
                                            {
                                              timeline.cancelled.support[0]
                                                .description
                                            }
                                          </p>
                                        )
                                      )}
                                    </div>
                                    <div className="col-md-3 timeline-col" />
                                  </div>
                                </div>
                              </div>
                            );
                          })}

                        {/* Rejected */}
                        {timeline.rejected &&
                          ["rejected"].map((i, k) => {
                            let td = tlData("rejected");
                            return (
                              <div
                                className={`timeline-row mb-5 position-relative tl-status-complete-negative`}
                                key={k}
                              >
                                <div className="tab-head-wrap d-flex">
                                  <div
                                    className={`ststus-title clr-white tmh-red`}
                                  >
                                    Rejected
                                  </div>
                                </div>
                                <div className="tab-content-wrap">
                                  <div className="row justify-content-between align-items-center">
                                    <div className="col-md-3 timeline-col timeline-status-info text-center">
                                      <h5 className="tmt-red">Completed</h5>
                                      <p className="tm-date mb-0">
                                        {td.dateAt}
                                      </p>
                                    </div>

                                    <div className="col-md-6 timeline-col timeline-status-content">
                                      <h5>Info</h5>
                                      <p>
                                        Your Application has been rejected
                                        <br />
                                        <span>Reason: </span>
                                        <span className="text-gray">
                                          {timeline.rejected.description}
                                        </span>
                                      </p>
                                    </div>
                                    <div className="col-md-3 timeline-col" />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                </Accordion>
              </div>
            </div>

            {/* Modal */}
            <Modal
              isOpen={myModal === "cancel"}
              style={common.modalStyle(60)}
              contentLabel=""
              ariaHideApp={false}
            >
              <div>
                <div className="register-step-header d-flex justify-content-between align-items-center">
                  <p className="register-step-title mb-0">
                    Cancellation Request
                  </p>
                  <div
                    className="cp"
                    onClick={() => {
                      setMyModal(null);
                      setCancel({
                        reason: "",
                        error: false,
                        submit: false,
                      });
                    }}
                  >
                    <img src={common.loadImg("close-icn.svg")} alt="" />
                  </div>
                </div>
                <div className="register-step-content">
                  <Row>
                    <textarea
                      className="txtarea pt-2"
                      rows={4}
                      placeholder="Reason for cancellation?"
                      value={cancel.reason}
                      onChange={onCancelChange}
                    ></textarea>
                    {cancel.error && (
                      <p className="error-txt p-0">
                        Cancellation reason required
                      </p>
                    )}
                  </Row>
                </div>

                <div className="register-step-footernew">
                  <Link to="">
                    <button
                      className="btn-default"
                      onClick={() => onCancelSubmit()}
                      disabled={loading === "cancel"}
                    >
                      {loading === "cancel" ? "Procesing..." : "Submit"}
                    </button>
                  </Link>
                </div>
              </div>
            </Modal>

            {/* modal */}
            {courseId !== null && (
              <CoursePickup
                tId={isIntake ? "u" : "s"}
                tKey={result?.application?.studentId}
                applicationId={result?.application?._id}
                courseId={courseId}
                onClose={() => {
                  setCourseId(null);
                }}
                onSuccess={() => {
                  onCoursePickSuccess();
                }}
              />
            )}
          </Fragment>
        )}
        {myModal === "agentConnect" && (
          <ConnectToAgent
            onClose={() => {
              outletAction("myProgress");
              setMyModal(null);
            }}
          />
        )}

        {[
          "otherActivity",
          "extraCurricular",
          "otherQualificationDocs",
          "expDocs",
          "passportDocs",
          "euDocs",
          "brpDocs",
          "sfeAppDocs",
          "homeOfficeDocs",
          "poeDocs",
        ].includes(myModal) && (
          <Modal
            isOpen={[
              "otherActivity",
              "extraCurricular",
              "otherQualificationDocs",
              "expDocs",
              "passportDocs",
              "euDocs",
              "brpDocs",
              "sfeAppDocs",
              "homeOfficeDocs",
              "poeDocs",
            ].includes(myModal)}
            style={common.modalStyle(60)}
            contentLabel=""
            ariaHideApp={false}
          >
            <Container fluid>
              <Row>
                <Col
                  lg={12}
                  className="register-step-header d-flex justify-content-between align-items-center"
                >
                  <p className="register-step-title mb-0">Document</p>
                  <img
                    src={common.loadImg("close-icn.svg")}
                    alt="arrow"
                    onClick={() => {
                      setDocs((prev) => ({
                        ...prev,
                        [myModal]:
                          fileUploadType[myModal] === "multiple" ? [] : "",
                      }));
                      setErrors((prev) => ({
                        ...prev,
                        [myModal]: "",
                      }));
                      setMyModal(null);
                    }}
                    className="cp"
                  />
                </Col>
                <Col lg={12} className="register-step-content">
                  <Row>
                    <Col md={12}>
                      <FileUpload
                        multiple={false}
                        onSuccess={(files) => {
                          setDocs((prev) => ({
                            ...prev,
                            [myModal]:
                              fileUploadType[myModal] === "multiple"
                                ? [...prev[myModal], ...files.acceptedFiles]
                                : files.acceptedFiles,
                          }));
                          setErrors((prev) => ({
                            ...prev,
                            [myModal]: "",
                          }));
                        }}
                      />
                      <p className="error-txt">
                        {errors[myModal] ? errors[myModal] : ""}
                      </p>
                      {docs[myModal] && (
                        <>
                          {fileUploadType[myModal] === "multiple" &&
                          docs[myModal].length ? (
                            <Fragment>
                              {docs[myModal]?.map((item, index) => (
                                <div
                                  className="d-flex align-items-center justify-content-between pt-3"
                                  key={index}
                                >
                                  <p className="agt-proof-file mb-0">
                                    {item?.name}
                                  </p>
                                  <img
                                    src={common.loadImg(
                                      "iconly-dark-delete.svg"
                                    )}
                                    className="et-cp"
                                    onClick={() => {
                                      setConfirm(["open", index]);
                                    }}
                                  />
                                </div>
                              ))}
                            </Fragment>
                          ) : fileUploadType[myModal] === "single" &&
                            docs[myModal].length ? (
                            <div className="d-flex align-items-center justify-content-between pt-3">
                              <p className="agt-proof-file mb-0">
                                {" "}
                                {docs[myModal][0]?.name}
                              </p>
                              <img
                                src={common.loadImg("iconly-dark-delete.svg")}
                                className="et-cp"
                                onClick={() => {
                                  setConfirm(["open"]);
                                }}
                              />
                            </div>
                          ) : null}
                        </>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="register-form-footer mt-0 mb-2">
                <Col lg={12} className="text-right pt-2 pe-2">
                  {/* <Link
                        to="#"
                        className="m-0 p-0"
                       
                      >
                       
                      </Link> */}
                  <button
                    className="btn-default"
                    onClick={() => onSubmitSFE(myModal)}
                    disabled={docsLoading}
                  >
                    {docsLoading ? "Procesing..." : "Save & Update"}
                  </button>
                </Col>
              </Row>
            </Container>
            <div className="shadow-div m-0"></div>
            <div className="greenshow-div"></div>
          </Modal>
        )}

        {/*Confirm the Interview  */}
        <InterviewConfirm
          data={modalPayload}
          resetData={setModalPayload}
          default={""}
          close={setMyModal}
          open={myModal}
          loading={loading}
          onSubmit={confirmInterview}
        />
        {/* Cancel the Interview */}
        <InterviewCancel
          data={modalPayload}
          secondModal={false}
          default={""}
          resetData={setModalPayload}
          close={setMyModal}
          open={myModal}
          loading={loading}
          onSubmit={cancelInterview}
        />
        {/* BookSlot */}
        <BookSlot
          data={modalPayload}
          default={null}
          resetData={setModalPayload}
          close={setMyModal}
          open={myModal}
          loading={loading}
          onSubmit={SubmitBookSlot}
        />
        {/* Remove */}
        {confirm && (
          <ModalConfirm
            loading={loading}
            onYes={() => onYes()}
            onNo={() => setConfirm(null)}
          />
        )}
      </div>
    </Fragment>
  );
}

export default Status;
