import { common } from "helpers";
import Modal from "react-modal";

function ModalConfirm(props) {
  return (
    <Modal
      isOpen={props?.open === "confirmInterview"}
      style={common.modalStyle(40)}
      ariaHideApp={false}
      contentLabel="Modal"
    >
      <div className="p-4">
        <div className="et-modal-header d-flex justify-content-between align-items-center">
          <h2 className="et-modal-title">Confirmation</h2>
          <button
            className="et-modal-close"
            onClick={() => {
              props.resetData({});
              props?.close(null);
            }}
          >
            <img src={common.loadImg("modal-close-icn.svg")} className="cp" />
          </button>
        </div>
        <div className="et-modal-body text-center">
          <h3>
            {props?.data?.interviewType} - {props?.data?.interviewMode}
          </h3>
          <p>{props?.data?.description}</p>
          <p>
            <b>your interview @</b> {props?.data?.dateTime}
          </p>
        </div>

        <div className="et-modal-footer p-3">
          <div className="d-flex justify-content-center">
            <button
              className="register-step-btn"
              onClick={() => props?.onSubmit(props?.data?.id)}
              disabled={props?.loading === "confirm" ? true : false}
            >
              {props?.loading === "confirm" ? "Processing...." : "Confirm"}
            </button>
          </div>
        </div>
      </div>
      <div className="shadow-div"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}
export default ModalConfirm;
