import AmlHashForm from "aml-hash-form";
import React, { Fragment, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useNavigate, useOutletContext } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

// includes
// import CropIt from "../../elements/CropIt";
import { api, common, config, types } from "helpers";
import FieldSelect from "../../elements/fieldSelect";
import StatusBar from "../../elements/statusBar";

function Settings() {
  const navigate = useNavigate();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [country, setCountry] = useState([]);
  const [galleryImage, setGalleryImage] = useState(null);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [bInfo, setBInfo] = useState(false);
  const [uData, setUData] = useState(null);
  const [agInfoBlock, setAgInfoBlock] = useState({
    bInfo: false,
    dInfo: false,
    rInfo: false,
    bDInfo: false,
  });
  const [fileA, setFileA] = useState([]);
  const [fileB, setFileB] = useState([]);
  const [fileC, setFileC] = useState([]);
  const [docsInfo, setDocsInfo] = useState(false);
  const [refInfo, setRefInfo] = useState(false);
  const [toggleState, setToggleState] = useState(
    authInfo?.role === "agent" ? 5 : 1
  );
  const [reference, setReference] = useState([]);
  let channelImageCroper = React.useRef(null);
  let channelImageFile = React.useRef(null);
  // Crop and Upload functions
  const cropItUpload = (e, type) => {
    let cropSize = {};
    cropSize = { width: 200, height: 210 };
    channelImageCroper.onFileChange(e, cropSize);
  };

  const handleClick = (event) => {
    channelImageFile.current.click();
  };

  const cropComplete = (data, type) => {
    // channelImageFile.value = "";
    if (data.status == "success") {
      //handleChangeFile(data.croppedImage);
      channelImageFile.current.value = "";
      //
    }
  };

  const [selectRender, setSelectRender] = useState({
    country: "yes",
    province: "yes",
    city: "no",
  });
  const [status, setStatus] = useState("process");
  const [count, setCount] = useState(0);
  const [error, setError] = useState(0);
  const [result, setResult] = useState({
    plan: [],
    info: {},
    profile: {},
    subscription: {},
  });
  const [fieldsCC, setFieldsCC] = useState({
    businessLogo: "",
    businessLogoFile: [],
  });

  const [loading, setLoading] = useState({
    loadingA: false,
    loadingB: false,
    loadingC: false,
    loadingD: false,
    loadingE: false,
    loadingF: false
  });
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const onFileChange = (e) => {
    const { name, files } = e.target;
    setFieldsCC((prev) => ({
      ...prev,
      [name]: [...files],
    }));
  };

  const deleteFile = (key, name) => {
    setFieldsCC((prev) => ({
      ...prev,
      [name]: prev[name].filter((i, k) => k !== key),
    }));
  };

  const isUpgrade = (id) => {
    let obj = Object.keys(result.subscription);
    return obj.indexOf(id) > obj.indexOf(result.payment?.planId?._id);
  };

  const planStatus = () => {
    let status = result.payment?.status;
    let res = {
      title: "",
      date: "",
      notes: "",
    };
    if (status === "active") {
      res = {
        title: "Expired On",
        date: moment(result.payment.endDate).format(config.elements.dp.B),
        notes: "Your plan is active",
      };
    } else if (status === "grace") {
      res = {
        title: "Grace Period Till",
        date: moment(result.payment.graceDate).format(config.elements.dp.B),
        notes: "You are in Grace period please renew your plan",
      };
    } else if (status === "expired") {
      res = {
        title: "Expired At",
        date: moment(result.payment.graceDate).format(config.elements.dp.B),
        notes: "Your plan has been expired please activate the plan",
      };
    } else if (status === "free") {
      res = {
        title: "Plan Type",
        date: "Free",
        notes: "Your are in BASIC plan now please upgrade to get more features",
      };
    }
    return res;
  };

  const FormA = {
    firstName: { validate: ["req#Please fill the FirstName"] },
  };

  const FormB = {
    oldPassword: { validate: ["req#Please provide the current password"] },
    newPassword: { validate: ["req#Please provide the new password"] },
    confirmPassword: {
      validate: [
        "req#Please provide the confirm password",
        "sameAsField:newPassword#New Password and confirm password do not match",
      ],
    },
  };

  const FormC = {
    firstName: {
      validate: ["req#Agent Name is required"],
    },
    businessName: {
      validate: ["req#Business Name is required"],
    },
    designation: {
      //validate: ["req#Designation is required"],
      validate: []
    },
    businessAddress: {
      validate: ["req#Business Address is required!"],
    },
    mobileCode: {
      validate: ["req#Mobile code is required!"],
    },
    businessContactNo: {
      validate: [
        "req#Business Contact Number is required!",
        "min:5#Not a valid contact number",
        "max:15#Not a valid contact number",
      ],
      allow: "number",
    },
    businessRegNumber: {
      validate: []
      //validate: ["req#Business Registration Number is required!"],
    },
    noOfStudentRecruitLastYear: {
      validate: [],
      allow: "number",
    },
    noOfStaffWorking: {
      validate: [],
      allow: "number",
    },
    countryOfOperation: {
      validate: ["objValReq#Please select the country"],
      obj: "value",
    },
    province: {
      validate: ["objValReq#Please select the province"],
      obj: "value",
    },
    city: {},
    recruitMethod: {
      validate: ["req#Recruitment Method is required!"],
    },
    description: {
      validate: [],
    },
  };

  const FormD = {
    businessRegDocs: {
      validate: [
        "req#Please upload the business document",
        "gt:0#Please upload the business document",
      ],
    },
    proofIdentityDocs: {
      validate: [
        "req#Please upload your proof",
        "gt:0#Please upload your proof",
      ],
    },
  };

  const FormE = {
    bankName: { validate: ["req#Please fill the Bank Name"] },
    bankAddr: { validate: ["req#Please fill the Bank Address"] },
    bankCountry: { validate: ["req#Please fill the Bank Country"] },
    accHolder: { validate: ["req#Please fill the Account Holder"] },
    accHolderAddr: { validate: ["req#Please fill the Account Address"] },
    accountNumber: { validate: ["req#Please fill the Account Number"] },
    sortCode: { validate: ["req#Please fill the sort Code"] }
  };
  const formFields = {
    fullName1: {
      validate: [],
    },
    designation1: {
      validate: [],
    },
    emailAddress1: {
      validate: ["email"],
    },
    fullName2: {
      validate: [],
    },
    designation2: {
      validate: [],
    },
    emailAddress2: {
      validate: ["email"],
    }
  };
  const {
    fields: fieldsA,
    errors: errorsA,
    setValue,
    handleChange: handleChangeA,
    handleSubmit: handleSubmitA,
  } = AmlHashForm(FormA);
  const {
    fields: fieldsB,
    errors: errorsB,
    setError: setErrorB,
    handleReset: handleResetB,
    setMultiValue: setMultiValueB,
    handleChange: handleChangeB,
    handleSubmit: handleSubmitB,
  } = AmlHashForm(FormB);

  const {
    fields: fieldsC,
    errors: errorsC,
    setValue: setValueC,
    setMultiValue: setMultiValueC,
    handleChange: handleChangeC,
    handleSubmit: handleSubmitC,
  } = AmlHashForm(FormC);

  const {
    fields: fieldsD,
    errors: errorsD,
    setValue: setValueD,
    setMultiValue: setMultiValueD,
    handleChange: handleChangeD,
    handleSubmit: handleSubmitD,
  } = AmlHashForm(FormD);

  const {
    fields: fieldsE,
    errors: errorsE,
    setValue: setValueE,
    setMultiValue: setMultiValueE,
    handleChange: handleChangeE,
    handleSubmit: handleSubmitE,
  } = AmlHashForm(FormE);

  const {
    fields: fieldsF,
    errors: errorsF,
    setValue: setValueF,
    setMultiValue: setMultiValueF,
    handleChange: handleChangeF,
    handleSubmit: handleSubmitF,
  } = AmlHashForm(formFields);

  const [agreement, setAgreement] = useState("");
  const [isVerified, setIsVerified] = useState(false);

  useEffect(() => {
    GetUserProfile();
    getCountry();
    if (authInfo?.role === "agent") {
      getPlan();
      getPayment();
      subPaymentHistory();
    }
  }, []);

  useEffect(() => {
    if (count === 2) {
      if (error === 0) {
        setStatus("success");
      } else {
        setStatus("error");
      }
    }
  }, [count]);

  //handler
  const onSubmitA = () => {
    let isValid = handleSubmitA();
    if (isValid) {
      updateUserInfo("A");
    }
  };

  const onSubmitB = () => {
    let isValid = handleSubmitB();
    if (isValid) {
      passwordUpdate();
    }
  };

  const onSubmitC = () => {
    let isValid = handleSubmitC();
    if (isValid) {
      updateUserInfo("C");
      setBInfo((prev) => !prev);
    }
  };

  const onSubmitF = () => {
    let isValid = handleSubmitF();
    if (isValid) {
      updateUserInfoF();
    }
  };

  const onSubmitE = () => {
    let isValid = handleSubmitE();
    if (isValid) {
      updateUserInfoA();
    }
  };

  const eData = (jsArg, key, type) => {
    let rs = "-";
    if (uData) {
      if (type === "string") {
        rs = uData[key] ? uData[key] : "-";
      } else if (type === "select") {
        rs = uData[key]?.label || jsArg[key]?.label;
      } else if (type === "mobile") {
        rs = `+${uData[key] ? common.hideMobile(uData[key]) : "-"}`;
      }
    }
    return rs;
  };

  //Api
  const GetUserProfile = () => {
    api.call(
      {
        url: authInfo?.role === "student" ? "student_profile" : "agent_profile",
        method: "GET",
      },
      (res) => {
        if (res.status === 200) {
          if ((authInfo?.role === "student" || authInfo?.role === "sub_agent")) {
            setValue("firstName", res?.data?.firstName);
            setUData(result);
            setStatus("success");
          } else {
            setAgreement(res?.data?.agreement);
            setIsVerified(res?.data?.isVerified);
            setValue("firstName", res?.data?.firstName);
            let result = common.reParse(res.data);
            // select
            if (!result.mobileCode) {
              result.mobileCode = "+44";
            }
            result.countryOfOperation = {
              label: result.countryOfOperation?.name || "United Kingdom",
              value: result.countryOfOperation?._id || "GB",
            };
            result.province = result.province
              ? {
                label: result.province?.name,
                value: result.province?._id,
              }
              : null;
            result.city = result.city
              ? {
                label: result.city?.name,
                value: result.city?._id,
              }
              : null;

            setFieldsCC((prev) => ({
              ...prev,
              businessLogo: result.businessLogo,
            }));

            setMultiValueC(result);
            setSelectRender((prev) => ({
              ...prev,
              city: result.province ? "yes" : "no",
            }));
            if(result?.businessRegDocs !== null){
              setFileA((old) => [...result.businessRegDocs]);
            }
            if(result?.proofIdentityDocs !== null){
              setFileB((old) => [...result.proofIdentityDocs]);
            }
            setMultiValueD({
              businessRegDocs: result.businessRegDocs?.length ,
              proofIdentityDocs: result.proofIdentityDocs?.length,
            });
            setMultiValueE({
              bankName: result?.bankName,
              bankCountry: result?.bankCountry,
              accountNumber: result?.accountNumber,
              sortCode: result?.sortCode,
              bankAddr: result?.bankAddr,
              accHolder: result?.accHolder,
              bankDocs: result?.bankDocs?.length,
            });
            if (result?.bankDocs?.length) {
              setFileC((old) => [...result?.bankDocs]);
            }

            setReference((preOld) => [...result?.reference]);

            setMultiValueF({
              fullName1: result?.reference[0]?.fullName,
              designation1: result?.reference[0]?.designation,
              emailAddress1: result?.reference[0]?.email,
              fullName2: result?.reference[1]?.fullName,
              designation2: result?.reference[1]?.designation,
              emailAddress2: result?.reference[1]?.email
            });
            getCountry();
            setUData(result);
            setStatus("success");
          }
        }
      }
    );
  };

  const uploadFile = async (idx) => {
    var formdata = new FormData();
    let presentFile = [];

    let count = 0;
    if (idx == "fileA") {
      fileA.map((i, k) => {
        i?.name ? formdata.append("file", i) : presentFile.push(i);
      });
      count = fileA.filter((item) => item?.name !== undefined).length;
    } else if (idx == "fileB") {
      fileB.map((i, k) => {
        i?.name ? formdata.append("file", i) : presentFile.push(i);
      });
      count = fileB.filter((item) => item?.name !== undefined).length;
    } else {
      fileC.map((i, k) => {
        i?.name ? formdata.append("file", i) : presentFile.push(i);
      });
      count = fileC.filter((item) => item?.name !== undefined).length;
    }

    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      auth: "token",
      ssr: true,
      cType: 6,
    };
    let resultData = count ? await api.call(data) : [];
    if (count) {
      resultData?.data?.link.map((item) => presentFile.push(item));
    }
    return {
      resp: count ? resultData : { status: 200 },
      result: presentFile,
    };
    //
  };

  const docsUpload = async () => {
    setLoading((oldPrev) => ({ ...oldPrev, loadingD: true }));
    let aResult = {};
    let bResult = {};
    let error = "";
    // upload 1
    aResult = await uploadFile("fileA");
    if (aResult?.resp?.status !== 200) {
      error = aResult.data.error;
    }
    // upload 2
    if (!error) {
      bResult = await uploadFile("fileB");
      if (bResult?.resp?.status !== 200) {
        error = bResult.data.error;
      }
    }
    // call
    if (error) {
      common.notify("E", error);
    } else {
      let params = {
        businessRegDocs: aResult.result,
        proofIdentityDocs: bResult.result,
      };

      let data = {
        url: "agent_profile",
        method: "PUT",
        auth: "token",
        body: params,
      };
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", "Document details has been updated");
          GetUserProfile();
          setAgInfoBlock((oldPrev) => ({ ...oldPrev, dInfo: !oldPrev.dInfo }));
        } else if (res.status === 500) {
          common.notify("E", res.data.error);
        }
      });
      setLoading((oldPrev) => ({ ...oldPrev, loadingD: false }));
    }
  };

  // file handler
  const onFileChangeA = (e) => {
    const files = e.target.files;
    setFileA((prev) => {
      let nData = [...prev, ...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      setValueD("businessRegDocs", result.length);
      return result;
    });
  };

  const onFileChangeB = (e) => {
    const files = e.target.files;
    setFileB((prev) => {
      let nData = [...prev, ...files];
      let result = [];
      if (nData.length > 3) {
        result = prev;
        common.notify("E", "Allowed only 3 files");
      } else {
        result = nData;
      }
      setValueD("proofIdentityDocs", result.length);
      return result;
    });
  };

  const onFileChangeC = (e) => {
    const files = e.target.files;
    setFileC((prev) => {
      let nData = [...prev, ...files];
      let result = [];
      result = nData;
      setValueE("bankDocs", result.length);
      return result;
    });
  };

  const deleteFileA = (key) => {
    setFileA((prev) => {
      let result = [];
      prev.map((i, k) => {
        if (k !== key) {
          result.push(i);
        }
      });
      setValueD("businessRegDocs", result.length);
      return result;
    });
  };

  const deleteFileB = (key) => {
    setFileB((prev) => {
      let result = [];
      prev.map((i, k) => {
        if (k !== key) {
          result.push(i);
        }
      });
      setValueD("proofIdentityDocs", result.length);
      return result;
    });
  };

  const deleteFileC = (key) => {
    setFileC((prev) => {
      let result = [];
      prev.map((i, k) => {
        if (k !== key) {
          result.push(i);
        }
      });
      setValueE("bankDocs", result.length);
      return result;
    });
  };

  // submit handler
  const onSubmitD = () => {
    let isValid = handleSubmitD();
    if (isValid) {
      docsUpload();
    }
  };

  const subPaymentHistory = () => {
    // paymentHistory
    api.call(
      {
        url: "agent_payment",
        query: "?limit=5&offset=1&sortBy=_id&sortDir=desc",
        method: "GET",
      },
      (res) => {
        if (res.status === 200) {
          setPaymentHistory(res.data.data);
        }
      }
    );
  };

  const formatFields = (payload) => {
    let py = { ...payload };
    for (let ky in payload) {
      if (typeof payload[ky] === "object") {
        if (payload[ky]?.value !== undefined) {
          py[ky] = payload[ky].value;
        }
      }
    }
    return py;
  };
  const updateUserInfo = (theField) => {
    setLoading((oldState) => ({ ...oldState, loadingA: true }));
    api.call(
      {
        url: authInfo?.role === "student" ? "ids_update_profile" : "agent_profile",
        method: "PUT",
        body: theField === "A" ? fieldsA : formatFields(fieldsC),
      },
      (res) => {
        if (res.status === 200) {
          //update
          if (theField === "C") {
            setUData((oldData) => ({ ...oldData, firstName: fieldsC.firstName, businessName: fieldsC.businessName }));
          }
          if (theField === "A") {
            setAgInfoBlock((oldPrev) => ({ ...oldPrev, bInfo: !oldPrev }));
          } else {
            setAgInfoBlock((oldPrev) => ({ ...oldPrev, bInfo: !oldPrev }));
          }
          common.notify("S", "update successfully");
          GetUserProfile();
        } else {
          common.notify("E", res.data.error);
        }
        setLoading((oldState) => ({ ...oldState, loadingA: false }));
      }
    );
  };

  const updateUserInfoA = async () => {
    setLoading((oldState) => ({ ...oldState, loadingE: true }));
    // let aResult = await uploadFile("fileC");
    // if (aResult?.resp?.status !== 200) {
    //   error = aResult.data.error;
    // }
    let params = fieldsE;
    //params.bankDocs = aResult.result;
    api.call(
      {
        url: authInfo?.role === "student" ? "student_profile" : "agent_profile",
        method: "PUT",
        body: params,
      },
      (res) => {
        if (res.status === 200) {
          //update
          setAgInfoBlock((oldPrev) => ({ ...oldPrev, bDInfo: !oldPrev }));
          common.notify("S", "update successfully");
          GetUserProfile();
        } else {
          common.notify("E", res.data.error);
        }
        GetUserProfile();
        setLoading((oldState) => ({ ...oldState, loadingE: false }));
      }
    );
  };

  const passwordUpdate = () => {
    setLoading((oldState) => ({ ...oldState, loadingB: true }));
    let payload = {
      currentPassword: fieldsB?.oldPassword,
      newPassword: fieldsB?.newPassword,
    };
    api.call(
      {
        url: "ids_update_password",
        method: "PUT",
        body: payload,
      },
      (res) => {
        if (res.status === 200) {
          //update
          setMultiValueB({
            oldPassword: "",
            newPassword: "",
            confirmPassword: "",
          });
          handleResetB();

          // setErrorB("");
          common.notify("S", "update successfully");
          GetUserProfile();
        } else {
          //  setErrorB('oldPassword',res.data.error)
          common.notify("E", res.data.error);
        }
        setLoading((oldState) => ({ ...oldState, loadingB: false }));
      }
    );
  };

  const updateUserInfoF = async () => {
    setLoading((oldState) => ({ ...oldState, loadingF: true }));
    let params = {
      reference: [
        {
          fullName: fieldsF.fullName1,
          designation: fieldsF?.designation1,
          email: fieldsF.emailAddress1,
        },
        {
          fullName: fieldsF.fullName2,
          designation: fieldsF?.designation2,
          email: fieldsF.emailAddress2,
        },
      ],
    }
    api.call(
      {
        url: authInfo?.role === "student" ? "student_profile" : "agent_profile",
        method: "PUT",
        body: params,
      },
      (res) => {
        if (res.status === 200) {
          //update
          setAgInfoBlock((oldPrev) => ({ ...oldPrev, rInfo: !oldPrev }));
          common.notify("S", "update successfully");
        } else {
          common.notify("E", res.data.error);
        }
        GetUserProfile();
        setLoading((oldState) => ({ ...oldState, loadingF: false }));
      }
    );
  };


  const getCountry = () => {
    let data = {
      url: "common_country",
      query: "?all=show",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setCountry(res.data.data);
        // setStatus("success");
      } else {
        // setStatus("error");
      }
    });
  };

  // api
  const getPlan = () => {
    let data = {
      url: "agent_plan",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let data = res.data?.data;
        let dataA = {};
        data.map((i) => {
          dataA[i._id] = i.title;
        });
        setResult((prev) => ({ ...prev, plan: data, subscription: dataA }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  const getPayment = () => {
    let data = {
      url: "agent_payment",
      query: "?limit=1&offset=1&sortBy=_id&sortDir=desc",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult((prev) => ({ ...prev, payment: res?.data?.data?.[0] }));
      } else {
        setError((prev) => ++prev);
      }
      setCount((prev) => ++prev);
    });
  };

  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(`/${authInfo.role == "sub_agent" ? "agent" : authInfo.role}/home`)}
            />
            <h3 className="inner-page-header-title mb-0">Settings</h3>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        <div className="container small-content-wrap">
          <div className="row settings-tab-wrap">
            <div className="col-md-3 tab-links-wrap">
              <div className="settings-tabs">
                {(authInfo?.role === "student" || authInfo?.role === "sub_agent") ? (
                  <div
                    className={toggleState === 1 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(1)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 1
                          ? "account-active-icn.svg"
                          : "account-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Account</span>
                  </div>
                ) : null}

                {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 5 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(5)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 5
                          ? "business-active-icn.svg"
                          : "business-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Business Name</span>
                  </div>
                ) : null}

                <div
                  className={toggleState === 2 ? "tabs active" : "tabs"}
                  onClick={() => toggleTab(2)}
                >
                  <img
                    src={common.loadImg(
                      toggleState === 2 ? "pwd-active-icn.svg" : "pwd-icn.svg"
                    )}
                    alt="user-icon"
                  />
                  <span className="ps-4">Password</span>
                </div>

                {authInfo?.role === "student" ? (
                  <div
                    className={toggleState === 3 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(3)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 3
                          ? "notify-active-icn.svg"
                          : "notify-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Notifications</span>
                  </div>
                ) : null}
                {authInfo?.role === "student" ? (
                  <div
                    className={toggleState === 4 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(4)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 4
                          ? "help-active-icn.svg"
                          : "help-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Help</span>
                  </div>
                ) : null}

                {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 6 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(6)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 6
                          ? "document-active-icn.svg"
                          : "document1-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Documents</span>
                  </div>
                ) : null}
                {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 7 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(7)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 7
                          ? "reference-active-icn.svg"
                          : "reference-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Reference</span>
                  </div>
                ) : null}

                {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 10 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(10)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 10
                          ? "active-bankdetails.svg"
                          : "default-bankdetails.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Bank Details</span>
                  </div>
                ) : null}

                {/* {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 8 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(8)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 8
                          ? "subscription-active-icn.svg"
                          : "subscription-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Subscription</span>
                  </div>
                ) : null}

                {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 9 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(9)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 9
                          ? "payment-active-icn.svg"
                          : "payment-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Payments</span>
                  </div>
                ) : null} */}

                {authInfo?.role === "agent" ? (
                  <div
                    className={toggleState === 11 ? "tabs active" : "tabs"}
                    onClick={() => toggleTab(11)}
                  >
                    <img
                      src={common.loadImg(
                        toggleState === 11
                          ? "document-active-icn.svg"
                          : "document1-icn.svg"
                      )}
                      alt="user-icon"
                    />
                    <span className="ps-4">Agreement</span>
                  </div>
                ) : null}
              </div>
            </div>

            {status !== "success" ? (
              <StatusBar status={status} />
            ) : (
              <div className="col-md-9 tabs-content-wrap">
                <div className="tabs-content">
                  {/* Account */}
                  <div
                    className={toggleState === 1 ? "content active" : "content"}
                  >
                    <h3 className="tabs-content-title">General Info</h3>
                    {/* <input
                    type="file"
                    ref={channelImageFile}
                    onChange={cropItUpload}
                    className="form-control"
                    accept="image/*"
                  /> */}
                    {/* <CropIt
                    ref={(instance) => {
                      channelImageCroper = instance;
                    }}
                    onComplete={(data) => cropComplete(data, "profileImage")}
                    shape={"round"}
                  /> */}

                    <label className="form-label tabs-form-label">
                      First Name
                    </label>
                    <input
                      type="text"
                      name="firstName"
                      value={fieldsA?.firstName}
                      className="form-control"
                      onChange={handleChangeA}
                    />
                    <p className="error-txt">{errorsA?.firstName}</p>
                    <label className="form-label tabs-form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      disabled
                      value={authInfo?.email}
                      className="form-control"
                    />
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="btn-default mt-5 mb-4"
                        onClick={onSubmitA}
                        disabled={loading?.loadingA}
                      >
                        {loading?.loadingA ? "processing..." : `Save & Update`}
                      </button>
                    </div>
                  </div>

                  {/* Password */}
                  <div
                    className={toggleState === 2 ? "content active" : "content"}
                  >
                    <h3 className="tabs-content-title">Change Password</h3>
                    <label className="form-label tabs-form-label">
                      Current Password
                    </label>
                    <input
                      type="password"
                      name="oldPassword"
                      className="form-control"
                      value={fieldsB?.oldPassword}
                      onChange={handleChangeB}
                    />
                    <p className="error-txt">{errorsB?.oldPassword}</p>
                    <label className="form-label tabs-form-label">
                      New Password
                    </label>
                    <input
                      type="password"
                      name="newPassword"
                      className="form-control"
                      value={fieldsB?.newPassword}
                      onChange={handleChangeB}
                    />
                    <p className="error-txt">{errorsB?.newPassword}</p>
                    <label className="form-label tabs-form-label">
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      className="form-control"
                      value={fieldsB?.confirmPassword}
                      onChange={handleChangeB}
                    />
                    <p className="error-txt">{errorsB?.confirmPassword}</p>
                    <div className="d-flex align-items-center justify-content-end">
                      <button
                        className="btn-default mt-5 mb-4"
                        onClick={onSubmitB}
                        disabled={loading?.loadingB}
                      >
                        {loading?.loadingB ? "Processing..." : "Save & Update"}
                      </button>
                    </div>
                  </div>

                  {/* Notification */}
                  <div
                    className={toggleState === 3 ? "content active" : "content"}
                  >
                    <h3 className="tabs-content-title mb-4">Notifications</h3>
                    <div className="tab-notify-wrap">
                      <div>
                        <div className="tab-msg-wrap">
                          <img
                            src={common.loadImg("tab-msg-icn.svg")}
                            alt="user-icon"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="tab-notify-txt">
                          <strong>Lorem Ipsum has been</strong> the industry's
                          standard dummy{" "}
                          <strong>text ever since the 1500s,</strong> when an
                          unknown printer took.
                        </p>
                        <label className="tab-notify-time">2 hours ago</label>
                      </div>
                    </div>
                    <div className="tab-notify-wrap">
                      <div>
                        <div className="tab-msg-wrap">
                          <img
                            src={common.loadImg("tab-msg-icn.svg")}
                            alt="user-icon"
                          />
                        </div>
                      </div>
                      <div>
                        <p className="tab-notify-txt">
                          <strong>Lorem Ipsum has been</strong> the industry's
                          standard dummy{" "}
                          <strong>text ever since the 1500s,</strong> when an
                          unknown printer took.
                        </p>
                        <label className="tab-notify-time">2 hours ago</label>
                      </div>
                    </div>
                  </div>

                  {/* Help & Support */}
                  <div
                    className={toggleState === 4 ? "content active" : "content"}
                  >
                    <h3 className="tabs-content-title mb-4">Help and Support</h3>
                    <Accordion defaultActiveKey="0" className="tabs-accordion">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Lorem Ipsum is simply dummy text of the printing
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. Lorem Ipsum
                          is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          Lorem Ipsum is simply dummy text of the printing
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. Lorem Ipsum
                          is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry.
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          Lorem Ipsum is simply dummy text of the printing
                        </Accordion.Header>
                        <Accordion.Body>
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. Lorem Ipsum
                          is simply dummy text of the printing and typesetting
                          industry. Lorem Ipsum has been the industry.
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>

                  {/* Business Info */}
                  <div
                    className={toggleState === 5 ? "content active" : "content"}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <h3 className="tabs-content-title">Business Information</h3>
                      {agInfoBlock.bInfo ? (
                        <img
                          src={common.loadImg("back-icn.svg")}
                          className="ed-cp"
                          alt=""
                          onClick={() =>
                            setAgInfoBlock((oldPrev) => ({
                              ...oldPrev,
                              bInfo: !oldPrev.bInfo,
                            }))
                          }
                        />
                      ) : (
                        <img
                          src={common.loadImg("edit-btn.svg")}
                          className="ed-cp"
                          alt=""
                          onClick={() =>
                            setAgInfoBlock((oldPrev) => ({
                              ...oldPrev,
                              bInfo: !oldPrev.bInfo,
                            }))
                          }
                        />
                      )}
                    </div>
                    {agInfoBlock.bInfo ? (
                      <>
                        <label className="label-padding">Agent Name</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="firstName"
                            placeholder="Enter the Agent name"
                            value={fieldsC.firstName}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.firstName}</p>

                        <label className="label-padding">Business Name</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="businessName"
                            placeholder="Enter the business name"
                            value={fieldsC.businessName}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.businessName}</p>

                        <label className="label-padding">Designation</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="designation"
                            placeholder="Enter the designation"
                            value={fieldsC.designation}
                            onChange={handleChangeC}
                            className="form-control"
                          />
                        </div>
                        <p className="error-txt">{errorsC.designation}</p>

                        <label className="label-padding">Business Address</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="businessAddress"
                            placeholder="Enter the business address"
                            value={fieldsC.businessAddress}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.businessAddress}</p>

                        <label className="label-padding">
                          Business Contact Number
                        </label>
                        <div className="row">
                          <div className="signin-form-row">
                            <PhoneInput
                              inputProps={{
                                name: "businessContactNo",
                                // required: true,
                                // autoFocus: true,
                              }}
                              value={fieldsC.businessContactNo}
                              country={"lk"}
                              onChange={(phone) =>
                                handleChangeC({
                                  target: {
                                    name: "businessContactNo",
                                    value: phone,
                                  },
                                })
                              }
                            />
                            <p className="error-txt">
                              {errorsC.businessContactNo}
                            </p>
                          </div>

                          {/* <p className="error-txt">
                          {errorsC.mobileCode ?? errorsC.businessContactNo}
                        </p> */}
                        </div>

                        <label className="label-padding">
                          Business Registration Number
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="businessRegNumber"
                            placeholder="Enter the registration number"
                            value={fieldsC.businessRegNumber}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.businessRegNumber}</p>

                        <label className="label-padding">
                          Your Recruitment Methods
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="recruitMethod"
                            placeholder="Enter the requirement method"
                            value={fieldsC.recruitMethod}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.recruitMethod}</p>

                        <label className="label-padding">
                          Description
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="description"
                            placeholder="Enter the description"
                            value={fieldsC.description}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.description}</p>

                        <label className="label-padding">
                          Number of students recruited for last year
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="noOfStudentRecruitLastYear"
                            placeholder="Enter last year recruitment"
                            value={fieldsC.noOfStudentRecruitLastYear}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt"></p>

                        <label className="label-padding">
                          Number of staffs working
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="noOfStaffWorking"
                            placeholder="Enter the staff working"
                            value={fieldsC.noOfStaffWorking}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt"></p>

                        <label className="label-padding">
                          Other countries or branches
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="otherCountryBranch"
                            placeholder="Enter the branches"
                            value={fieldsC.otherCountryBranch}
                            className="form-control"
                            onChange={handleChangeC}
                          />
                        </div>
                        <p className="error-txt">{errorsC.otherCountryBranch}</p>

                        <label className="label-padding">
                          Country of Operation
                        </label>
                        <div className="signin-form-row">
                          <FieldSelect
                            api="common_country"
                            query="?all=show"
                            get="name"
                            set="code"
                            value={fieldsC.countryOfOperation}
                            placeholder="Please select the country"
                            render={selectRender.country}
                            clearable={true}
                            findValue={false}
                            multi={false}
                            auth="temp"
                            onSelect={(data) => {
                              setMultiValueC({
                                countryOfOperation: data,
                                province: null,
                                city: null,
                              });
                              setSelectRender((prev) => ({
                                ...prev,
                                province: data === null ? "empty" : new Date(),
                                city: "empty",
                              }));
                            }}
                          />
                        </div>
                        <p className="error-txt">{errorsC.countryOfOperation}</p>

                        <label className="label-padding">Province</label>
                        <div className="signin-form-row">
                          <FieldSelect
                            api="common_state"
                            query={`/${fieldsC?.countryOfOperation?.value}?&offset=1&limit=1000`}
                            get="name"
                            set="_id"
                            value={fieldsC.province}
                            placeholder="Please select the province"
                            render={selectRender.province}
                            clearable={true}
                            findValue={false}
                            multi={false}
                            auth="temp"
                            onSelect={(data) => {
                              setMultiValueC({
                                province: data,
                                city: null,
                              });
                              setSelectRender((prev) => ({
                                ...prev,
                                city: data === null ? "empty" : new Date(),
                              }));
                            }}
                          />
                        </div>
                        <p className="error-txt">{errorsC.province}</p>

                        <label className="label-padding">City</label>
                        <div className="signin-form-row">
                          <FieldSelect
                            api="common_city"
                            query={`/${fieldsC?.countryOfOperation?.value}/${fieldsC?.province?.value}?&offset=1&limit=1000`}
                            get="name"
                            set="_id"
                            value={fieldsC.city}
                            placeholder="Please select the city"
                            render={selectRender.city}
                            clearable={true}
                            findValue={false}
                            multi={false}
                            auth="temp"
                            onSelect={(data) => {
                              setMultiValueC({
                                city: data,
                              });
                            }}
                          />
                        </div>
                        <p className="error-txt">{errorsC.city}</p>

                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-default mt-5 mb-4"
                            onClick={onSubmitC}
                            disabled={loading?.loadingA}
                          >
                            {loading?.loadingA
                              ? "Processing..."
                              : "Save & Update"}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="mb-3">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6 set-detail-col set-detail-col1">
                              <p className="et-field-name">Profile</p>
                              <p className="et-field-value">
                                {uData?.firstName} {uData?.lastName}
                              </p>
                              <img
                                src={common.loadImg("user-img.png")}
                                className="setting-user-img pt-3"
                              />
                            </div>
                            <div className="col-md-6 set-detail-col ps-5">
                              <p className="et-field-name">Business Name</p>
                              <p className="et-field-value">
                                {uData?.businessName}
                              </p>
                              <img
                                src={common.loadImg("user-img.png")}
                                className="setting-user-img pt-3"
                              />
                            </div>
                            <div className="col-md-6 set-detail-col set-detail-col1">
                              <p className="et-field-name">Email</p>
                              <p className="et-field-value">{fieldsC?.email ? common.hideEmail(fieldsC?.email) : "-"}</p>
                            </div>

                            {[
                              {
                                lb: "Designation",
                                key: "designation",
                                type: "string",
                              },
                              {
                                lb: "Business Address",
                                key: "businessAddress",
                                type: "string",
                              },
                              {
                                lb: "Business Contact Number",
                                key: "businessContactNo",
                                type: "mobile",
                              },
                              {
                                lb: "Business Registration Number",
                                key: "businessRegNumber",
                                type: "string",
                              },
                              {
                                lb: "Your Recruitment Methods",
                                key: "recruitMethod",
                                type: "string",
                              },
                              {
                                lb: "Description",
                                key: "description",
                                type: "string",
                              },
                              {
                                lb: "Number of students recruited for last year",
                                key: "noOfStudentRecruitLastYear",
                                type: "string",
                              },
                              {
                                lb: "Number of staffs workings",
                                key: "noOfStaffWorking",
                                type: "string",
                              },
                              {
                                lb: "Other countries or branches",
                                key: "otherCountryBranch",
                                type: "string",
                              },
                              {
                                lb: "Country Of Operation",
                                key: "countryOfOperation",
                                type: "select",
                              },
                              {
                                lb: "Province",
                                key: "province",
                                type: "select",
                              },
                              {
                                lb: "City",
                                key: "city",
                                type: "select",
                              }
                            ].map((item, index) => (
                              <div
                                key={index}
                                className={
                                  (index + 1) % 2 == 0
                                    ? "col-md-6 set-detail-col set-detail-col1"
                                    : "col-md-6 set-detail-col ps-5"
                                }
                              >
                                <p className="et-field-name">{item?.lb} </p>
                                <p className="et-field-value">
                                  {eData(fieldsC, item.key, item.type)}
                                </p>
                              </div>
                            ))}

                            {/* <div className="col-md-12 set-detail-col">
                            <p className="et-field-name">
                              Other countries or branches
                            </p>
                            <p className="et-field-value">
                              In publishing and graphic design, Lorem ipsum is a
                              placeholder text commonly used to demonstrate the
                              visual form of a document or a typeface without
                              relying on meaningful content. Lorem ipsum may be
                              used as a placeholder before final copy is
                              available. Wikipedia
                            </p>
                          </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Documents */}
                  <div
                    className={toggleState === 6 ? "content active" : "content"}
                  >
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="tabs-content-title">Documents</h3>
                        {agInfoBlock?.dInfo ? (
                          <img
                            src={common.loadImg("back-icn.svg")}
                            className="ed-cp"
                            alt=""
                            onClick={() =>
                              setAgInfoBlock((oldPrev) => ({
                                ...oldPrev,
                                dInfo: !oldPrev.dInfo,
                              }))
                            }
                          />
                        ) : (
                          <img
                            src={common.loadImg("edit-btn.svg")}
                            className="ed-cp"
                            alt=""
                            onClick={() =>
                              setAgInfoBlock((oldPrev) => ({
                                ...oldPrev,
                                dInfo: !oldPrev.dInfo,
                              }))
                            }
                          />
                        )}
                      </div>

                      {!agInfoBlock?.dInfo ? (
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6 set-detail-col set-detail-col1">
                              <p className="et-field-name">Business Registration</p>
                              {fieldsC?.businessRegDocs?.map((iB, kB) => (
                                <div key={kB} className="row mb-3">
                                  <div className="et-field-value col-md-9">
                                    <span
                                      className="et-link"
                                      onClick={() => common.openCloudUrl(iB)}
                                    >
                                      {common.fileName(iB)}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                            <div className="col-md-6 set-detail-col ps-5">
                              <p className="et-field-name">Proof of Identity</p>
                              {fieldsC?.proofIdentityDocs?.map((iB, kB) => (
                                <div key={kB} className="row mb-3">
                                  <div className="et-field-value col-md-9">
                                    <span
                                      className="et-link"
                                      onClick={() => common.openCloudUrl(iB)}
                                    >
                                      {common.fileName(iB)}
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="container">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="agt-proof-upload d-flex justify-content-between align-items-center">
                                <div>
                                  <p className="agt-proof-title mb-0">
                                    Business Registration
                                  </p>
                                  <p className="mb-0 error-txt">
                                    {errorsD.businessRegDocs}
                                  </p>
                                </div>
                                <div>
                                  <input
                                    type="file"
                                    id="file-1"
                                    className="et-hide"
                                    name="businessRegDocs"
                                    value=""
                                    multiple={true}
                                    onChange={(e) => onFileChangeA(e)}
                                  />
                                  <label
                                    className="btn btn-outline-secondary delete-btn-padding"
                                    type="button"
                                    htmlFor="file-1"
                                    id="button-addon2"
                                  >
                                    <img
                                      src={common.loadImg(
                                        "paper-dark-upload.svg"
                                      )}
                                    />
                                  </label>
                                </div>
                              </div>
                              <p className="mb-0">
                                (Maximum 3 files and per file size is 10 MB)
                              </p>
                              {fileA.map((i, k) => (
                                <div
                                  className="d-flex align-items-center justify-content-between pt-3"
                                  key={k}
                                >
                                  <p className="agt-proof-file mb-0">
                                    {i?.name ? i.name : common.fileName(i)}
                                  </p>
                                  <img
                                    src={common.loadImg("iconly-dark-delete.svg")}
                                    className="et-cp"
                                    onClick={() => deleteFileA(k)}
                                  />
                                </div>
                              ))}
                            </div>
                            <div className="col-md-6">
                              <div className="agt-proof-upload d-flex justify-content-between align-items-center">
                                <div>
                                  <p className="agt-proof-title mb-0">
                                    Proof of Identity
                                  </p>
                                  <p className="mb-0 error-txt">
                                    {errorsD.proofIdentityDocs}
                                  </p>
                                </div>
                                <div>
                                  <input
                                    type="file"
                                    id="file-2"
                                    className="et-hide"
                                    name="proofIdentityDocs"
                                    multiple={true}
                                    value=""
                                    onChange={(e) => onFileChangeB(e)}
                                  />
                                  <label
                                    className="btn btn-outline-secondary delete-btn-padding"
                                    type="button"
                                    htmlFor="file-2"
                                    id="button-addon2"
                                  >
                                    <img
                                      src={common.loadImg(
                                        "paper-dark-upload.svg"
                                      )}
                                    />
                                  </label>
                                </div>
                              </div>
                              <p className="mb-0">
                                (Maximum 3 files and per file size is 10 MB)
                              </p>
                              {fileB.map((i, k) => (
                                <div
                                  className="d-flex align-items-center justify-content-between pt-3"
                                  key={k}
                                >
                                  <p className="agt-proof-file mb-0">
                                    {i?.name ? i.name : common.fileName(i)}
                                  </p>
                                  <img
                                    src={common.loadImg("iconly-dark-delete.svg")}
                                    className="et-cp"
                                    onClick={() => deleteFileB(k)}
                                  />
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {agInfoBlock?.dInfo ? (
                      <div className="d-flex justify-content-end">
                        <button
                          className="btn-default mt-5 mb-4"
                          disabled={loading?.loadingD}
                          onClick={onSubmitD}
                        >
                          {loading?.loadingD ? "Processing..." : "Save & Update"}
                        </button>
                      </div>
                    ) : null}
                  </div>

                  {/* Reference */}
                  <div
                    className={toggleState === 7 ? "content active" : "content"}
                  >
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="tabs-content-title">Reference</h3>
                        {agInfoBlock.rInfo ? (
                          <img
                            src={common.loadImg("back-icn.svg")}
                            className="ed-cp"
                            alt=""
                            onClick={() =>
                              setAgInfoBlock((oldPrev) => ({
                                ...oldPrev,
                                rInfo: !oldPrev.rInfo,
                              }))
                            }
                          />
                        ) : (
                          <img
                            src={common.loadImg("edit-btn.svg")}
                            className="ed-cp"
                            alt=""
                            onClick={() =>
                              setAgInfoBlock((oldPrev) => ({
                                ...oldPrev,
                                rInfo: !oldPrev.rInfo,
                              }))
                            }
                          />
                        )}
                      </div>
                      {!agInfoBlock.rInfo && (
                        <>
                          {reference?.filter((k) => k.email.length !== 0)
                            .length ? (
                            reference?.map((i, k) => (
                              <>
                                <h4 className="reference-title">Reference</h4>
                                <div className="container">
                                  <div className="row">
                                    <div className="col-md-6 set-detail-col set-detail-col1">
                                      <p className="et-field-name">Full Name</p>
                                      <p className="et-field-value">{i.fullName ? i.fullName : '-'}</p>
                                    </div>
                                    <div className="col-md-6 set-detail-col ps-5">
                                      <p className="et-field-name">Designation</p>
                                      <p className="et-field-value">
                                        {i.designation ? i.designation : '-'}
                                      </p>
                                    </div>
                                    <div className="col-md-12 set-detail-col">
                                      <p className="et-field-name">Email</p>
                                      <p className="et-field-value">{i?.email ? common.hideEmail(i?.email) : '-'}</p>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))
                          ) : (
                            <p>No reference found</p>
                          )}
                        </>
                      )}
                      {agInfoBlock.rInfo && (
                        <>
                          <div>
                            <Row className="right-content-width remove-border">
                              <Col lg={12} className="register-step-header">
                                <p className="register-step-title mb-0">
                                  01. Reference
                                </p>
                              </Col>
                            </Row>

                            <Row className="agent-box-body">
                              <Col lg={6}>
                                <label className="label-padding">Full Name</label>
                                <div className="signin-form-row">
                                  <input
                                    type="text"
                                    name={"fullName1"}
                                    className="form-control"
                                    value={fieldsF["fullName1"]}
                                    onChange={handleChangeF}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <label className="label-padding">
                                  Designation
                                </label>
                                <div className="signin-form-row">
                                  <input
                                    type="text"
                                    name={"designation1"}
                                    className="form-control"
                                    value={fieldsF["designation1"]}
                                    onChange={handleChangeF}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <label className="label-padding">
                                  Email Address
                                </label>
                                <div className="signin-form-row">
                                  <input
                                    type="text"
                                    name={"emailAddress1"}
                                    className="form-control"
                                    value={fieldsF["emailAddress1"]}
                                    onChange={handleChangeF}
                                  />
                                </div>
                                <p className="error-txt">
                                  {errorsF["emailAddress1"]}
                                </p>
                              </Col>
                            </Row>
                          </div>
                          <div>
                            <Row className="right-content-width remove-border">
                              <Col lg={12} className="register-step-header">
                                <p className="register-step-title mb-0">
                                  02. Reference
                                </p>
                              </Col>
                            </Row>

                            <Row className="agent-box-body">
                              <Col lg={6}>
                                <label className="label-padding">Full Name</label>
                                <div className="signin-form-row">
                                  <input
                                    type="text"
                                    name={"fullName2"}
                                    className="form-control"
                                    value={fieldsF["fullName2"]}
                                    onChange={handleChangeF}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <label className="label-padding">
                                  Designation
                                </label>
                                <div className="signin-form-row">
                                  <input
                                    type="text"
                                    name={"designation2"}
                                    className="form-control"
                                    value={fieldsF["designation2"]}
                                    onChange={handleChangeF}
                                  />
                                </div>
                              </Col>
                              <Col lg={6}>
                                <label className="label-padding">
                                  Email Address
                                </label>
                                <div className="signin-form-row">
                                  <input
                                    type="text"
                                    name={"emailAddress2"}
                                    className="form-control"
                                    value={fieldsF["emailAddress2"]}
                                    onChange={handleChangeF}
                                  />
                                </div>
                                <p className="error-txt">
                                  {errorsF["emailAddress2"]}
                                </p>
                              </Col>
                            </Row>
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              className="btn-default mt-5 mb-4"
                              onClick={onSubmitF}
                              disabled={loading?.loadingF}
                            >
                              {loading?.loadingF
                                ? "Processing..."
                                : "Save & Update"}
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  {/* Bank Details */}
                  <div
                    className={toggleState === 10 ? "content active" : "content"}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <h3 className="tabs-content-title mb-4">Bank Details</h3>
                      {agInfoBlock.bDInfo ? (
                        <img
                          src={common.loadImg("back-icn.svg")}
                          className="ed-cp"
                          alt=""
                          onClick={() =>
                            setAgInfoBlock((oldPrev) => ({
                              ...oldPrev,
                              bDInfo: !oldPrev.bDInfo,
                            }))
                          }
                        />
                      ) : (
                        <img
                          src={common.loadImg("edit-btn.svg")}
                          className="ed-cp"
                          alt=""
                          onClick={() =>
                            setAgInfoBlock((oldPrev) => ({
                              ...oldPrev,
                              bDInfo: !oldPrev.bDInfo,
                            }))
                          }
                        />
                      )}
                    </div>

                    {agInfoBlock.bDInfo ? (
                      <>
                        <label className="label-padding">Bank Name</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="bankName"
                            placeholder="Enter the Bank name"
                            value={fieldsE.bankName}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.bankName}</p>
                        <label className="label-padding">Bank Address</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="bankAddr"
                            placeholder="Enter the Bank Address"
                            value={fieldsE.bankAddr}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.bankAddr}</p>
                        <label className="label-padding">Bank Country</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="bankCountry"
                            placeholder="Enter the Bank Country"
                            value={fieldsE.bankCountry}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.bankCountry}</p>
                        <label className="label-padding">
                          Account Holder Name
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="accHolder"
                            placeholder="Enter the Account holder name"
                            value={fieldsE.accHolder}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.accHolder}</p>
                        <label className="label-padding">Account Number</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="accountNumber"
                            placeholder="Enter the Account Number"
                            value={fieldsE.accountNumber}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.accHolder}</p>
                        <label className="label-padding">
                          Account Holder Address
                        </label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="accHolderAddr"
                            placeholder="Enter the Account holder address"
                            value={fieldsE.accHolderAddr}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.accHolderAddr}</p>
                        <label className="label-padding">Sort Code</label>
                        <div className="signin-form-row">
                          <input
                            type="text"
                            name="sortCode"
                            placeholder="Enter the Sort code"
                            value={fieldsE.sortCode}
                            className="form-control"
                            onChange={handleChangeE}
                          />
                        </div>
                        <p className="error-txt">{errorsE.sortCode}</p>
                        {/* <div className="col-md-12 mt-5">
                         <div className="agt-proof-upload d-flex justify-content-between align-items-center">
                          <div>
                            <p className="agt-proof-title mb-0">Bank Book</p>
                          </div>
                          <div>
                            <input
                              type="file"
                              id="file-1"
                              className="et-hide"
                              name="bankDocs"
                              value=""
                              multiple={true}
                              onChange={(e) => onFileChangeC(e)}
                            />
                            <label
                              className="btn btn-outline-secondary delete-btn-padding"
                              type="button"
                              htmlFor="file-1"
                              id="button-addon2"
                            >
                              <img
                                src={common.loadImg("paper-dark-upload.svg")}
                              />
                            </label>
                          </div>
                        </div> 

                        {fileC.map((i, k) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={k}
                          >
                            <p className="agt-proof-file mb-0">
                              {i?.name ? i.name : common.fileName(i)}
                            </p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() => deleteFileC(k)}
                            />
                          </div>
                        ))}
                      </div> */}

                        <div className="d-flex justify-content-end">
                          <button
                            className="btn-default mt-5 mb-4"
                            onClick={onSubmitE}
                            disabled={loading?.loadingE}
                          >
                            {loading?.loadingE
                              ? "Processing..."
                              : "Save & Update"}
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="mb-3">
                        <div className="container">
                          <div className="row">
                            {[
                              {
                                lb: "Bank Name",
                                key: "bankName",
                                type: "string",
                              },
                              {
                                lb: "Bank Address",
                                key: "bankAddr",
                                type: "string",
                              },
                              {
                                lb: "Bank Country",
                                key: "bankCountry",
                                type: "string",
                              },
                              {
                                lb: "Account Holder",
                                key: "accHolder",
                                type: "string",
                              },
                              {
                                lb: "Account Number",
                                key: "accountNumber",
                                type: "string",
                              },
                              {
                                lb: "Account Holder Address",
                                key: "accHolderAddr",
                                type: "string",
                              },
                              {
                                lb: "Sort code",
                                key: "sortCode",
                                type: "string",
                              },
                            ].map((item, index) => (
                              <div
                                key={index}
                                className={
                                  (index + 1) % 2 == 0
                                    ? "col-md-6 set-detail-col ps-5"
                                    : "col-md-6 set-detail-col set-detail-col1 "
                                }
                              >
                                <p className="et-field-name">{item?.lb} </p>
                                <p className="et-field-value">
                                  {eData(fieldsC, item.key, item.type)}
                                </p>
                              </div>
                            ))}
                            {/* <div className="col-md-6 set-detail-col set-detail-col1">
                            <p className="et-field-name">Bank Book</p>
                            {fieldsE?.bankDocs
                              ? fileC?.map((iB, kB) => (
                                <div key={kB} className="row mb-3">
                                  <div className="et-field-value col-md-9">
                                    <span
                                      className="et-link"
                                      onClick={() => common.openCloudUrl(iB)}
                                    >
                                      {common.fileName(iB)}
                                    </span>
                                  </div>
                                </div>
                              ))
                              : "-----"}
                          </div> */}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                  {/* subscription */}
                  <div
                    className={toggleState === 8 ? "content active" : "content"}
                  >
                    <h3 className="tabs-content-title mb-4">
                      Subscription & Payment
                    </h3>
                    <div className="container">
                      <div className="row">
                        <div className="col-md-6 set-detail-col set-detail-col1">
                          <p className="et-field-name">Current Plan</p>
                          <p className="et-field-value">
                            {result.payment?.planId?.title}
                          </p>
                        </div>
                        {[1].map((i, k) => {
                          let ps = planStatus();
                          return (
                            <Fragment key={k}>
                              <div className="col-md-6 set-detail-col ps-5">
                                <p className="et-field-name">{ps.title}</p>
                                <p className="et-field-value">{ps.date}</p>
                              </div>

                              <div
                                className="col-md-12 set-detail-col"
                                style={{ borderBottom: "none" }}
                              >
                                <p className="et-field-name">Notes</p>
                                <p className="et-field-value">{ps.notes}</p>
                              </div>
                            </Fragment>
                          );
                        })}
                      </div>
                    </div>
                    {/* <ul className="et-vc-list row">
                    <li
                      className={`col-md-3 et-view-border-bottom et-view-border-right`}
                    >
                      <div className="et-list-inner">
                        <p className="et-field-name">Current Plan</p>
                        <p className="et-field-value">
                          <span className="plan-lab-current">
                            {result.payment?.planId?.title}
                          </span>
                        </p>
                      </div>
                    </li>
                    {[1].map((i, k) => {
                      let ps = planStatus();
                      return (
                        <Fragment key={k}>
                          <li
                            className={`col-md-3 et-view-border-bottom et-view-border-right`}
                          >
                            <div className="et-list-inner">
                              <p className="et-field-name">{ps.title}</p>
                              <p className="et-field-value">{ps.date}</p>
                            </div>
                          </li>
                          {ps.notes && (
                            <li
                              className={`col-md-6 et-view-border-bottom et-view-border-right`}
                            >
                              <div className="et-list-inner">
                                <p className="et-field-name">Notes</p>
                                <p className="et-field-value">{ps.notes}</p>
                              </div>
                            </li>
                          )}
                        </Fragment>
                      );
                    })}
                  </ul> */}

                    {status !== "success" ? (
                      <StatusBar status={status} message="default" />
                    ) : (
                      <div className="row m-0">
                        <div className="col-lg-12 col-md-12">
                          <div className="row sub-bgsubscript">
                            <div className="row table-responsive">
                              <table className="uncollaps">
                                <tbody>
                                  <tr>
                                    <th></th>
                                    {result.plan.map((i, k) => (
                                      <th className="bgheader" key={k}>
                                        <div className="bghead">
                                          <p className="bgheadclr setting-plan-title">
                                            {i.title}
                                          </p>
                                          <div
                                            className={`SubscriptionCnt plan-price-wrap ${i.style}`}
                                          >
                                            <p className="sub-plan-type">
                                              {i.billingTitle}
                                            </p>
                                            <p className="sub-commission">
                                              {i.commission}
                                            </p>
                                            <Fragment>
                                              {i.upgrade ? (
                                                <p className="plan-upgrade">
                                                  {i.currencySymbols +
                                                    i?.upgrade.grandTotal}
                                                  <span>
                                                    {i.currencySymbols + i.price}
                                                  </span>
                                                </p>
                                              ) : (
                                                <p className="plan-upgrade">
                                                  {i.currencySymbols +
                                                    (i?.price || 0)}
                                                </p>
                                              )}
                                            </Fragment>
                                          </div>
                                        </div>
                                      </th>
                                    ))}
                                  </tr>
                                  {Object.entries(types.planCategory).map(
                                    ([m, n]) => (
                                      <tr key={n}>
                                        <td style={{ width: "20px" }}></td>
                                        {/* <td className="topichead lastborder">
                                        {n}
                                      </td> */}
                                        {result.plan.map((i, k) => (
                                          <Fragment key={k}>
                                            {i.access.includes(m) ? (
                                              <td>
                                                <div
                                                  className="SubscriptionCnt1 sub-feature-list"
                                                  style={{ borderLeft: "none" }}
                                                >
                                                  <div className="bghead1">
                                                    <div>
                                                      <p className="register-step-head1">
                                                        {n}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            ) : (
                                              <td>
                                                <div
                                                  className="SubscriptionCnt1 sub-feature-list"
                                                  style={{ borderLeft: "none" }}
                                                >
                                                  <div className="bghead1">
                                                    <div>
                                                      <p className="register-step-head1">
                                                        --
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </td>
                                            )}
                                          </Fragment>
                                        ))}
                                      </tr>
                                    )
                                  )}

                                  <tr>
                                    <td style={{ width: "20px" }}></td>

                                    {result.plan.map((i, k) => {
                                      let planStatus = result?.payment?.status;
                                      return (
                                        <td key={k}>
                                          {isUpgrade(i._id) ? (
                                            <div
                                              className="plan-btn plan-btn-upgrade mt-5"
                                              onClick={() =>
                                                navigate(
                                                  `/agent/payment/${i._id}`
                                                )
                                              }
                                            >
                                              <label>Upgrade Now</label>
                                            </div>
                                          ) : i.currentPlan &&
                                            planStatus === "grace" ? (
                                            <div
                                              className="plan-btn plan-btn-renew"
                                              onClick={() =>
                                                navigate(
                                                  `/agent/payment/${i._id}`
                                                )
                                              }
                                            >
                                              <label>Renew Now</label>
                                            </div>
                                          ) : (
                                            i.name !== "basic" &&
                                            ["grace", "expired"].includes(
                                              planStatus
                                            ) && (
                                              <div
                                                className="plan-btn plan-btn-upgrade"
                                                onClick={() =>
                                                  navigate(
                                                    `/agent/payment/${i._id}`
                                                  )
                                                }
                                              >
                                                <label>Activate Now</label>
                                              </div>
                                            )
                                          )}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Payment History */}
                  <div
                    className={toggleState === 9 ? "content active" : "content"}
                  >
                    <div className="mb-3">
                      <h3 className="tabs-content-title">Payment History</h3>
                      <div className="container p-0 mt-5">
                        {paymentHistory.map((item) => (
                          <div className="row payment-history-row mt-4">
                            <div className="col-8">
                              <p className="payment-p1">{item?.planId?.title}</p>
                              <p className="payment-p2">{item?.description}</p>
                              <p className="payment-p3">
                                {item?.planId?.name !== "basic"
                                  ? `Exp Date : ${moment(item?.endDate).format(
                                    "MMM DD, YYYY hh:mm a"
                                  )}`
                                  : ""}
                              </p>
                            </div>
                            <div className="col-4 d-flex justify-content-end">
                              <p className="payment-price">
                                {item?.currency !== "0"
                                  ? common.currencyToSymbol(item?.currency)
                                  : ""}
                                {item?.grandTotal}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {/* Documents */}
                  <div
                    className={toggleState === 11 ? "content active" : "content"}
                  >
                    <div className="mb-3">
                      <div className="d-flex align-items-center justify-content-between">
                        <h3 className="tabs-content-title">Documents</h3>
                      </div>

                      <div className="container">
                        <div className="row">
                          <div className="col-md-6 set-detail-col set-detail-col1">
                            <p className="et-field-name">Agent Document</p>
                            {/* {fieldsC?.businessRegDocs?.map((iB, kB) => ( */}
                            {agreement != "" && agreement && (
                              <div className="row mb-3">
                                <div className="et-field-value col-md-9">
                                  <span
                                    className="et-link"
                                    onClick={() => common.openCloudUrl(agreement)}
                                  >
                                    {common.fileName(agreement)}
                                  </span>
                                </div>
                              </div>
                            )}
                            {/* ))} */}
                          </div>
                          <div className="col-md-6 set-detail-col ps-5">
                            <p className="et-field-name">User Verified</p>
                            {/* {fieldsC?.proofIdentityDocs?.map((iB, kB) => ( */}
                            {isVerified !== "" && (
                              <div className="row mb-3">
                                <div className="et-field-value col-md-9">
                                  <span
                                  // className="et-link"
                                  // onClick={() => common.openCloudUrl(iB)}
                                  >
                                    {isVerified ? "Verified" : "Not Verified"}
                                  </span>
                                </div>
                              </div>
                            )}
                            {/* ))} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>)}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Settings;
