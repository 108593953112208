import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-modal";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
// includes
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";

function CoursePickup(props) {
  // props

  const navigate = useNavigate();
  const { tId, tKey, courseId } = props;
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();

  // state
  const [status, setStatus] = useState("process");
  const [courseStatus, setCourseStatus] = useState(null);
  const [fields, setFields] = useState({
    courseId: courseId,
    intakeYear: 2022,
    intakeMonth: 0,
  });
  const [result, setResult] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  // effect
  useEffect(() => {
    if (tId === "p") {
      checkProfile();
    } else {
      getInfo();
    }
  }, []);

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  // api
  const checkProfile = () => {
    let data = {};
    if (authInfo.role === "student") {
      data = {
        url: "student_info",
        method: "GET",
      };
    } else {
      data = {
        url: "agent_student_info",
        query: `/${tKey.split(":")[0]}`,
        method: "GET",
      };
    }

    api.call(data, (res) => {
      if (res.status === 200) {
        const { progress, course } = res.data;
        if (authInfo.role === "student") {
          if (progress.total !== userInfo.progress.total) {
            outletAction("myProgress");
          }
        }
        // check status
        // if (progress.total !== 100) {
        //   setCourseStatus("progress");
        //   setStatus("success");
        // }
        if (course.picked.length >= 1) {
          setCourseStatus("reached");
          setStatus("success");
        } else {
          getInfo();
        }
      } else {
        if (authInfo.role === "student") {
          setStatus("error");
        } else {
          common.notify("W", "Please select the valid student and add course");
          navigate("/agent/student/list");
        }
      }
    });
  };

  const getInfo = () => {
    let status = "success";
    let data = {
      url: "course",
      method: "GET",
      query: `/${courseId}`,
    };
    api.call(data, (res) => {
      if (res.status !== 200) {
        status = "error";
      } else {
        setResult(res.data);
      }
      setStatus(status);
    });
  };

  console.log({ tId });

  const applyCourse = () => {
    // validate
    if (fields.intakeMonth === 0) {
      setError("Please select the month");
    } else {
      setLoading(true);
      if (["p", "ch", "cp"].includes(tId)) {
        coursePickup();
      } else if (["ur", "s"].includes(tId)) {
        courseChange();
      } else if(["u"].includes(tId)){
        intakeChange();
      }
    }
  };

  const coursePickup = () => {
    let tKeys = tKey.split(":");
    let data = {
      url: "course_pickup",
      method: "PUT",
      query: `/${authInfo.role === "student" ? authInfo._id : tKeys[0]}`,
      body: {
        pickId: tKeys?.[1] || "",
        courseId: courseId,
        intakeYear: fields.intakeYear,
        intakeMonth: fields.intakeMonth,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        if (authInfo.role === "student") {
          outletAction("myProgress");
        }
        props.onSuccess();
        //redirect
        redirect();
      } else if (res.status === 500) {
        common.notify("I", "This course is already selected");
        if (authInfo.role === "student") {
          outletAction("myProgress");
        }
      }
      setLoading(false);
    });
  };

  const courseChange = () => {
    let tKeys = tKey.split(":");
    let data = {
      url: "course_change",
      method: "PUT",
      query: `/${tKeys[0]}`,
      body: {
        applicationId: tKeys[1] || props?.applicationId,
        courseId: courseId,
        intakeYear: fields.intakeYear,
        intakeMonth: fields.intakeMonth,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        props.onSuccess();
        redirect();
      } else if (res.status === 500) {
        common.notify("I", "This course is already selected");
        props.onClose();
      }
      setLoading(false);
    });
  };

  const intakeChange = () => {
    let tKeys = tKey.split(":");
    let data = {
      url: "intake_change",
      method: "PUT",
      query: `/${tKeys[0]}`,
      body: {
        applicationId: tKeys[1] || props?.applicationId,
        courseId: courseId,
        intakeYear: fields.intakeYear,
        intakeMonth: fields.intakeMonth,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        props.onSuccess();
        redirect();
      } else if (res.status === 500) {
        common.notify("I", "Something went wrong please try again");
        props.onClose();
      }
      setLoading(false);
    });
  };

  const redirect = () => {
    let url = "";
    let tKeys = tKey.split(":");
    if (authInfo.role === "student") {
      switch (tId) {
        case "p":
          url = "/student/home";
          break;
        case "ch":
          url = "/student/home";
          break;
        case "cp":
          url = `/application/preview/${tKeys[0]}`;
          break;
        case "ur":
          url = `/application/payment/fees/${tKeys[2]}`;
          break;
        case "s":
          url = `/application/status/${tKeys[1] || props?.applicationId}`;
          break;
        case "u":
          url = `/application/status/${tKeys[1] || props?.applicationId}`;
          break;
      }
    } else if (authInfo.role === "agent" || authInfo.role === "sub_agent") {
      switch (tId) {
        case "cp":
          url = `/application/preview/${tKeys[0]}`;
          break;
        case "ur":
          url = `/application/payment/fees/${tKeys[2]}`;
          break;
        case "s":
          url = `/application/status/${tKeys[1] || props?.applicationId}`;
          break;
        case "p":
          url = `/course/finder/p/${tKeys[0]}`;
          break;
        case "u":
          url = `/application/status/${tKeys[1] || props?.applicationId}`;
          break;
        default:
          url = `/course/finder/p/${tKeys[0]}`;
          break;
      }
    }
    common.notify("S", types.coursePickupMessage[tId.split("")[0]]);
    console.log({ url });
    if (url) {
      navigate(url);
    } else {
      props.onSuccess();
    }
  };

  return (
    <Modal
      isOpen={true}
      style={common.modalStyle(50)}
      contentLabel="Modal"
      ariaHideApp={false}
      disa
    >
      <div className="p-4">
        <div className="et-modal-header d-flex justify-content-between align-items-center">
          <h2 className="et-modal-title">
            {types.coursePickup[tId.split("")[0]]}
            <p className="typehead pt-2 pb-0">
              Please choose your preferred intake.
            </p>
          </h2>
          <button className="et-modal-close" onClick={props.onClose}>
            <img src={common.loadImg("modal-close-icn.svg")} />
          </button>
        </div>
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : ["progress", "reached"].includes(courseStatus) && tId === "p" ? (
          <Fragment>
            <div className="et-modal-body text-center">
              <div className="mb-4">
                <img
                  src={common.loadImg(
                    courseStatus === "reached"
                      ? "course-selection-icn.svg"
                      : "course-selection-icn1.svg"
                  )}
                  alt="Course Selection Icon"
                />
              </div>
              {courseStatus === "reached" ? (
                <h3>You have reached maximum course selection limit</h3>
              ) : (
                <h3>You are almost there! Let’s finish up your profile</h3>
              )}
            </div>
            <div className="et-modal-footer p-3">
              {courseStatus === "progress" ? (
                <div className="d-flex justify-content-center">
                  <button
                    className="register-step-btn no-btn me-2"
                    onClick={props.onClose}
                  >
                    Cancel
                  </button>
                  <button
                    className="register-step-btn"
                    onClick={() =>
                      navigate(
                        authInfo.role === "student"
                          ? "/student/profile"
                          : `/agent/student/view/${tKey}`
                      )
                    }
                  >
                    Goto Profile
                  </button>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <button
                    className="register-step-btn no-btn me-2"
                    onClick={props.onClose}
                  >
                    Got It
                  </button>
                  {authInfo.role === "student" && (
                    <button
                      className="register-step-btn"
                      onClick={() =>
                        authInfo.role === "student"
                          ? navigate("/student/home")
                          : navigate(`/application/preview/${tKey}`)
                      }
                    >
                      View Courses
                    </button>
                  )}
                </div>
              )}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <Container className="et-modal-body text-center">
              {/* <Row>
                <Col lg={12}>
                  <p className="headtype">Course</p>
                  <p className="courseType">{result.name}</p>
                </Col>
                <Col lg={12}>
                  <Row className="universityborder">
                    <Col lg={3} className="imgcls">
                      <img
                        src={common.cloudImg(result.university?.logo)}
                        className="uni-logo"
                      />
                    </Col>
                    <Col lg={9} className="d-flex align-items-end">
                      <p>
                        {result.university?.name} - {result.university.city}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row> */}
              <Row className="centreallign">
                <Col lg={12}>
                  <p className="typehead pb-2">
                    Which Year do you want to study?
                  </p>
                </Col>
                {common.getFullYear(3).map((i, k) => (
                  <Col lg={4} md={4} className="mb-4 mx-auto">
                    <div className="bgbuttonnew">
                      <input
                        type="radio"
                        value={i}
                        id={`year${k}`}
                        name="isYear"
                        className="form-control"
                        checked={fields.intakeYear === i}
                        onChange={() => {
                          setFields({
                            intakeMonth: 0,
                            intakeYear: i,
                          });
                          setError(null);
                        }}
                      />
                      <label className="btn btn-default" htmlFor={`year${k}`}>
                        {i}
                      </label>
                    </div>
                  </Col>
                ))}
                <Col lg={12}>
                  <p className="typehead pb-4">Which intake month?</p>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                {result.intake.map(
                  (i, k) =>
                    i.year === fields.intakeYear &&
                    i.month.map((j, l) => (
                      <Col
                        lg={4}
                        md={4}
                        className="d-flex justify-content-center datepick"
                        key={l}
                      >
                        <div className="bgbuttonnew">
                          <input
                            type="radio"
                            id={`month${l}`}
                            name="isMonth"
                            value={j}
                            className="form-control"
                            checked={fields.intakeMonth === j}
                            onChange={() => {
                              setError(null);
                              setFields((prev) => ({
                                ...prev,
                                intakeMonth: j,
                              }));
                            }}
                          />
                          <label
                            className="btn btn-default"
                            htmlFor={`month${l}`}
                          >
                            {common.getMonthList()?.[j - 1]?.label}
                          </label>
                        </div>
                      </Col>
                    ))
                )}
                {result.intake.filter((i) => i.year === fields.intakeYear)
                  .length === 0 ? (
                  <Col
                    lg={4}
                    md={4}
                    className="d-flex justify-content-center datepick"
                  >
                    <p className="alert alert-warning">No intakes available</p>
                  </Col>
                ) : (
                  <p className="error-txt text-center">{error}</p>
                )}

                {/* <Col lg={12} className="register-step-content">
                  
                </Col> */}
              </Row>
            </Container>
            <Container className="et-modal-footer">
              <Row className="register-form-footer-brder">
                <Col lg={12} className="textright pt-4 pe-2">
                  <Link to="" className="m-0 p-0">
                    <button
                      className="btn-default"
                      onClick={applyCourse}
                      disabled={loading}
                    >
                      {loading ? "Processing..." : "Submit"}
                    </button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </Fragment>
        )}
      </div>
      <div className="shadow-div m-0"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}

export default CoursePickup;
