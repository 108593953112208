import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
// pages
import CommonStudentView from "includes/studentView";
// includes
import { api, common } from "helpers";

function View() {
  const navigate = useNavigate();
  const { stdID } = useParams();
  const [user, setUser] = useState();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [student, setStudent] = useState();

  useEffect(() => {
    if(authInfo.role != "student"){
      userVerify();
      agentStudentInfo();
     }
   }, [])

   const agentStudentInfo = () => {
    let data = {
      url: "promoter_students_info",
      query: `/${stdID}`,
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setStudent(res.data);
      }
    });
  };

   const userVerify = () => {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            setUser(res.data)
          }
        }
      );
  };

  const loginGenerate = () => {
    let data = {
      url: "agent_student",
      method: "POST",
      query: `/${stdID}/logins`,
      body: {}
    };
    api.call(data, (res) => {
      if (res.status == 204) {
        common.notify("S", "Student login created successfully");
      } else {
        common.notify("E", res.data.error);
      }
    });
  }

  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            <h3 className="inner-page-header-title mb-0">Student Profile</h3>
          </div>
          {((user && user?.createLoginAuthorized) && (student && !student?.id_s)) && (
            <a
              onClick={() => loginGenerate()}
              className="send-login d-flex justify-content-between align-items-center"
            >
              <span className="ms-3">Send Login Invite to Student</span>
              <img
                src={common.loadImg("plus-sign.svg")}
                className="me-3"
                alt="promotion-icn"
              ></img>
            </a>
          )}
        </div>
      </div>
      <div className="content-wrap">
        <CommonStudentView
          id={stdID}
          formModule="all"
          formPreview={true}
          formType="promoterStdInfo"
          onSuccess={() => navigate("/agent/student/list")}
          coordinatorView={true}
          onlyView={true}
        />
      </div>
    </Fragment>
  );
}

export default View;
