import AmlHashForm from "aml-hash-form";
import moment from "moment";
import { Fragment, useEffect, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";
// includes
import FieldSelect from "elements/fieldSelect";
import StatusBar from "elements/statusBar";
import { api, common, config, types } from "helpers";

function Form() {
  // props
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState(id ? "process" : "success");
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState("");
  const [finder, setFinder] = useState({
    university: true,
    course: true,
    agent: true,
  });
  const [effectField, setEffectField] = useState([]);
  // validate
  const formFields = {
    title: { validate: ["req"] },
    description: {},
    category: {
      validate: ["objValReq#Please select the category"],
      obj: "value",
    },
    code: { validate: ["req", "min:4", "max:20"] },
    value: { validate: ["req"], allow: "float" },
    type: { value: types.promoType[0] },
    limit: { validate: ["req"], allow: "number" },
    userLimit: { validate: ["req"], allow: "number" },
    startDate: {},
    endDate: {},
    university: { value: [] },
    course: { value: [] },
    cashBack: { value: types.booleanYesNo[1] },
    isActive: { value: types.status[0] },
  };
  const {
    fields,
    errors,
    setValue,
    handleChange,
    handleSubmit,
    setMultiValue,
  } = AmlHashForm(formFields);
  // effect
  useEffect(() => {
    if (id) {
      getInfo();
    }
  }, []);
  useEffect(() => {
    if (effectField.length > 0) {
      setValue(effectField[0], effectField[1]);
      setFinder((prev) => ({ ...prev, [effectField[0]]: false }));
    }
  }, [effectField]);
  console.log({ finder });
  // api
  const getInfo = () => {
    let data = {
      url: "promoCode",
      method: "GET",
      query: `/${id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        setMultiValue({
          title: result.title,
          description: result.description,
          category: common.FindSelect(types.promoCategory, result.category),
          code: result.code,
          value: result.value,
          type: result.type
            ? common.FindSelect(types.promoType, result.type)
            : types.promoType[0],
          limit: result.limit,
          userLimit: result.userLimit,
          startDate: result.startDate
            ? moment(result.startDate).format(config.elements.dp.A)
            : "",
          endDate: result.endDate
            ? moment(result.endDate).format(config.elements.dp.A)
            : "",
          isActive: result.isActive ? types.status[0] : types.status[1],
          university: result.university,
          course: result.course,
          agent: result.agent,
          cashBack: result.cashBack
            ? types.booleanYesNo[0]
            : types.booleanYesNo[1],
        });
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };
  // handler
  const onSubmit = () => {
    let valid = handleSubmit();
    if (valid) {
      setLoading(true);
      let data = {};
      let params = {
        title: fields.title,
        description: fields.description,
        category: fields.category.value,
        code: fields.code,
        value: fields.value,
        type: fields.type.value,
        limit: fields.limit,
        userLimit: fields.userLimit,
        startDate: fields.startDate,
        endDate: fields.endDate,
        isActive: fields.isActive.value,
        university:
          fields.category?.value === "application"
            ? []
            : fields.university?.map((i) => i.value),
        course:
          fields.category?.value === "application"
            ? []
            : fields.course?.map((i) => i.value),
        agent: fields.agent?.length ? fields.agent.map((i) => i.value) : [],
        cashBack:
          fields.category?.value === "application"
            ? false
            : fields.cashBack.value,
      };
      if (id) {
        data = {
          url: "promoCode",
          method: "PUT",
          query: `/${id}`,
          body: params,
        };
      } else {
        data = {
          url: "promoCode",
          method: "POST",
          body: params,
        };
      }
      api.call(data, (res) => {
        if (res.status === 200) {
          common.notify("S", `Promocode request created successfully`);
          navigate("/agent/promo/list");
        } else if (res.status === 500) {
          setError("Promocode already exist");
        } else {
          common.notify("E", "Something went wrong");
        }
        setLoading(false);
      });
    }
  };
  // render
  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <img
            src={common.loadImg("back-icn.svg")}
            className="ed-cp"
            alt=""
            onClick={() => navigate(-1)}
          />
          <h1 className="page-title ps-4">
            {id ? "View" : "Add New"} Promo Code
          </h1>
        </div>
      </div>
      <div className="content-wrap ">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : id ? (
          <div className="container-fluid">
            <h2 className="form-title">View Promo Code</h2>
            <ul className="et-vc-list row et-view-content-wrap">
              {[
                {
                  name: "Title",
                  key: "title",
                  type: "string",
                  data: `${fields.title}`,
                },
                {
                  name: "Description",
                  key: "description",
                  type: "string",
                  data: `${fields.description || "-"}`,
                },
                {
                  name: "Category",
                  key: "category",
                  type: "string",
                  data: `${fields.category?.label || "-"}`,
                },

                {
                  name: "Promo Code",
                  key: "code",
                  type: "string",
                  data: `${fields.code || "-"}`,
                },

                {
                  name: "Usage Limit",
                  key: "limit",
                  type: "string",
                  data: `${fields.limit || "-"}`,
                },

                {
                  name: "Value",
                  key: "value",
                  type: "string",
                  data: `${fields.value || "-"}`,
                },

                {
                  name: "Start Date",
                  key: "startDate",
                  type: "string",
                  data: `${fields.startDate || "-"}`,
                },
                {
                  name: "End Date",
                  key: "endDate",
                  type: "string",
                  data: `${fields.endDate || "-"}`,
                },
                {
                  name: "Limit per user",
                  key: "userLimit",
                  type: "string",
                  data: `${fields.userLimit || "-"}`,
                },
                {
                  name: "Status",
                  key: "status",
                  type: "string",
                  data: `${fields.isActive?.label || "-"}`,
                },

                {
                  name: "Cash Back",
                  key: "cashBack",
                  type: "string",
                  data: `${fields.cashBack?.label || "-"}`,
                },
                {
                  name: "University",
                  key: "university",
                  type: "string",
                  data: `${fields?.university.length > 0 ? fields?.university[0].name : "-"}`,
                },

                {
                  name: "Course",
                  key: "course",
                  type: "string",
                  data: `${fields?.course.length > 0 ? fields?.course[0].name : "-"}`,
                },

                {
                  name: "Agent",
                  key: "agent",
                  type: "string",
                  data: `${fields?.agent.length > 0 ? fields?.agent[0].firstName : "-"}`,
                },
              ].map((cItem, cKey) => (
                <li
                  className={`col-md-4 et-view-border-bottom et-view-border-right `}
                  key={cKey}
                >
                  <div className="et-list-inner">
                    <p className="et-field-name">{cItem.name}</p>
                    <p className="et-field-value">
                      {cItem.data}
                      {/* {common.readable(cItem.type, i[cItem.key])} */}
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ) : (
          <div className="container-fluid">
            <h2 className="form-title">Promo Code</h2>
            <div className="form-wrap">
              <div className="row">
                <div className="col-md-6 mb-4">
                  <label>Title</label>
                  <input
                    type="text"
                    name="title"
                    placeholder="Enter the title"
                    className="form-control"
                    value={fields.title}
                    onChange={handleChange}
                  />
                  <p className="error-txt">{errors.title}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Description</label>
                  <input
                    type="text"
                    name="description"
                    placeholder="Enter the description"
                    className="form-control"
                    value={fields.description}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Category</label>
                  <Select
                    className="custom-select-box"
                    value={fields.category}
                    options={types.promoCategory}
                    isClearable={true}
                    onChange={(data) => {
                      setMultiValue({
                        category: data,
                        university:
                          data?.value === "application"
                            ? []
                            : fields.university,
                        course:
                          data?.value === "application" ? [] : fields.course,
                        cashBack:
                          data?.value === "application"
                            ? types.booleanYesNo[1]
                            : fields.cashBack,
                      });
                    }}
                  />
                  <p className="error-txt">{errors.category}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Promo Code</label>
                  <input
                    type="text"
                    name="code"
                    placeholder="Enter the promo code"
                    className="form-control"
                    value={fields.code}
                    onChange={(e) => {
                      handleChange(e);
                      setError("");
                    }}
                  />
                  <p className="error-txt">{errors.code || error}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Usage Limit</label>
                  <input
                    type="text"
                    name="limit"
                    placeholder="Enter the usage limit"
                    className="form-control"
                    value={fields.limit}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <p className="error-txt">{errors.limit}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Value</label>
                  <div className="d-flex">
                    <div className="col-md-7">
                      <input
                        type="text"
                        name="value"
                        placeholder="Enter Value"
                        className="form-control select-left"
                        value={fields.value}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-5">
                      <Select
                        className="custom-select-box"
                        value={fields.type}
                        options={types.promoType}
                        onChange={(data) => {
                          setMultiValue({
                            type: data,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <p className="error-txt">{errors.value}</p>
                </div>
                <div className="col-md-6 mb-4 date-picker">
                  <label>Start Date</label>
                  <DateRangePicker
                    initialSettings={{
                      ...config.elements.singleDp,
                    }}
                    onShow={(event, picker) => {
                      setMultiValue({
                        startDate: moment(picker.startDate).format(
                          config.elements.dp.A
                        ),
                      });
                    }}
                    onCallback={(start) => {
                      setMultiValue({
                        startDate: moment(start).format(config.elements.dp.A),
                      });
                    }}
                  >
                    <input
                      type="text"
                      className="form-control date-picker"
                      value={fields.startDate}
                      placeholder="Select the start date"
                      readOnly={true}
                    />
                  </DateRangePicker>
                  <button
                    className="clear-icn cust-clear-icn"
                    onClick={() => {
                      setMultiValue({
                        startDate: "",
                      });
                    }}
                  >
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      className="css-tj5bde-Svg"
                    >
                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                    </svg>
                  </button>
                </div>
                <div className="col-md-6 mb-4 date-picker">
                  <label>End Date</label>
                  <DateRangePicker
                    initialSettings={{
                      ...config.elements.singleDp,
                    }}
                    onShow={(event, picker) => {
                      setMultiValue({
                        endDate: moment(picker.startDate).format(
                          config.elements.dp.A
                        ),
                      });
                    }}
                    onCallback={(start) => {
                      setMultiValue({
                        endDate: moment(start).format(config.elements.dp.A),
                      });
                    }}
                  >
                    <input
                      type="text"
                      className="form-control date-picker"
                      value={fields.endDate}
                      placeholder="Select the end date"
                      readOnly={true}
                    />
                  </DateRangePicker>
                  <button
                    className="clear-icn cust-clear-icn"
                    onClick={() => {
                      setMultiValue({
                        endDate: "",
                      });
                    }}
                  >
                    <svg
                      height="20"
                      width="20"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                      focusable="false"
                      className="css-tj5bde-Svg"
                    >
                      <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                    </svg>
                  </button>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Limit per user</label>
                  <input
                    type="text"
                    name="userLimit"
                    placeholder="Enter limit per user"
                    className="form-control"
                    value={fields.userLimit}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                  <p className="error-txt">{errors.userLimit}</p>
                </div>
                <div className="col-md-6 mb-4">
                  <label>Status</label>
                  <Select
                    className="custom-select-box"
                    value={fields.isActive}
                    options={types.status}
                    isClearable={false}
                    onChange={(data) => {
                      setMultiValue({ isActive: data });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Cash Back</label>
                  <Select
                    className="custom-select-box"
                    value={fields.cashBack}
                    options={types.booleanYesNo}
                    isClearable={false}
                    isDisabled={fields.category?.value === "application"}
                    onChange={(data) => {
                      setMultiValue({ cashBack: data });
                    }}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Select University</label>
                  <FieldSelect
                    api="university"
                    query="?offset=1&limit=1000"
                    get="name"
                    set="_id"
                    value={fields?.university}
                    placeholder="Please select the university"
                    render={"yes"}
                    clearable={true}
                    finder={finder?.university}
                    multi={true}
                    disabled={fields.category?.value === "application"}
                    onSelect={(data) => {
                      setEffectField(["university", data]);
                    }}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Select Course</label>
                  <FieldSelect
                    api="course"
                    query="?offset=1&limit=1000"
                    get="name"
                    set="_id"
                    join="universityName"
                    value={fields?.course}
                    placeholder="Please select the university"
                    render={"yes"}
                    clearable={true}
                    finder={finder?.course}
                    multi={true}
                    disabled={fields.category?.value === "application"}
                    onSelect={(data) => {
                      setEffectField(["course", data]);
                    }}
                  />
                </div>
                <div className="col-md-6 mb-4">
                  <label>Select Agent</label>
                  <FieldSelect
                    api="agents"
                    query="?offset=1&limit=1000"
                    get="email"
                    set="_id"
                    value={fields?.agent}
                    placeholder="Please select the agent"
                    render={"yes"}
                    clearable={true}
                    finder={finder?.agent}
                    multi={true}
                    onSelect={(data) => {
                      setEffectField(["agent", data]);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn ed-btn-default ed-btn-save-dt"
                onClick={onSubmit}
                disabled={loading}
              >
                {loading ? "Processing..." : "Request Promocode"}
              </button>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
}
export default Form;
