import { api, common, types } from "helpers";
import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
// includes
import StatusBar from "elements/statusBar";
import VerifyModal from "elements/modalNotVerified";

function Home({ socket }) {
  // init
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState({});
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [isModal, setIsModal] = useState(null);
  // effect
  useEffect(() => {
    getInfo();
  }, []);

  //socket
  useEffect(() => {
    socket.on(`${types?.socket?.HOME}`, (data) => {
      // if (data?.userID === common.getAuth()?._id) {
        getInfo();
      // }
    });
  }, [socket]);

  // api
  const getInfo = () => {
    let data = {
      url: "agent_dashboard",
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        let result = res.data;
        let reParse = {};
        result.map((i) => {
          reParse[i._id] = i.count;
        });
        setResult(reParse);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const userVerify = () => {
    if (authInfo?.role === "agent") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            if (res?.data.isVerified) {
              navigate(`/agent/student/list`);
            } else {
              setIsModal(true);
            }
          }
        }
      );
    } else {
      navigate(`/agent/student/list`);
    }
  };

  const conditionalCount = (a, b) => {
    let counts = 0;
    a &&
      Object.keys(a).length &&
      Object.keys(a).map((i) => {
        if (b.includes(i)) {
          counts += a[i];
        }
      });
    return counts;
  };

  //render
  return (
    <div className="content-wrap home-wrap">
      <div className="ed-bb d-flex align-items-center justify-content-between mx-3">
        <div>
          <p className="student-home-title mb-0 border-bottom-0">
            Hi! {common.getAuth()?.firstName}
          </p>
        </div>
        <div>
          {/* <Link to="/agent/student/list" className="text-dec-none"> */}
          <button
            onClick={() => userVerify()}
            className="btn-signin d-flex justify-content-between align-items-center"
          >
            <span className="ms-3">New Application</span>
            <img
              src={common.loadImg("plus-sign.svg")}
              className="me-3"
              alt="promotion-icn"
            ></img>
          </button>
          {/* </Link> */}
        </div>
      </div>
      {/* Home option 2 */}
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <div className="mt-5">
          <ul className="row">
            {types.agentHome.map((i, k) => (
              <li
                className="col-md-4 et-cp"
                key={k}
                onClick={() =>
                  navigate(
                    `${i.url}?q=currentStatus=${
                      i.title !== "Draft Applications" ? i.key : ""
                    }`
                  )
                }
              >
                <div className="agent-home-card mx-3">
                  <div className="card-title">{i.title}</div>
                  <div className="studappl">
                    {i.key.length > 1 ? (
                      <div>{conditionalCount(result, i.key)} Applications listed </div>
                    ) : (
                      <div>{result?.[i.key] || "0"} Applications listed</div>
                    )}
                    <div className="arrow-wrap">
                      {/* <img
                        src={common.loadImg("arrow-right-circle.svg")}
                        className="ms-1 et-cp"
                        alt="arrow"
                      /> */}
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
      {true === false && (
        <>
          <div className="agent-latest-updated-wrap ed-bb">
            <p className="student-home-title mb-0">Latest Updated</p>
          </div>
          <div className="text-center mt-5">
            <img
              src={common.loadImg("no-data-found.png")}
              className="me-3"
              alt="promotion-icn"
            ></img>
            <p className="no-data-found-txt mt-4">No Data Found</p>
          </div>
        </>
      )}
      {isModal && <VerifyModal onClose={() => setIsModal(null)} />}
    </div>
  );
}
export default Home;
