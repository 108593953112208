import React, { useState, useEffect } from "react";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
// includes
import { api, common, types } from "helpers";
import StatusBar from "elements/statusBar";

function Five(props) {
  // state
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [tempData] = useState(common.tempDataGet());
  const [result, setResult] = useState([]);

  // effect
  useEffect(() => {
    getPlan();
  }, []);

  // api
  const getPlan = () => {
    let data = {
      url: "plan",
      method: "GET",
      auth: "temp",
    };
    api.call(data, (res) => {
      let status = "success";
      if (res.status === 200) {
        setResult(res.data);
      } else {
        status = "error";
      }
      setStatus(status);
    });
  };

  const makePayment = (planId) => {
    setLoading("basic");
    let params = {
      id: planId,
      category: "",
      promoCode: "",
      paymentId: "",
      refId: "",
      type: "",
    };

    let data = {
      url: "payment_create",
      query: "/subscription",
      method: "POST",
      auth: "temp",
      body: params,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        props?.onSuccess(7);
      } else {
        common.notify("E", res.data.error);
      }
      setLoading(null);
    });
  };

  // handler
  const onSubmit = (item) => {
    if (item.name === "basic") {
      makePayment(item._id);
    } else {
      common.tempDataSet({ ...tempData, planId: item._id });
      props?.onSuccess(6);
    }
  };

  const planDesc = (acc, pl) => {
    let checkIcon = (
      <span className="dotbtn">
        <img src={common.loadImg("ticksymbol.svg")} alt="" />
      </span>
    );
    if (pl === "basic") {
      if (["ap", "oc", "af"].includes(acc)) {
        let message = {
          ap: "0 Free Applications",
          oc: "Single",
          af: "80%",
        };
        return <p className="sub-description">{message[acc]}</p>;
      } else if (["sp"].includes(acc)) {
        //icons
        return checkIcon;
      } else {
        return "";
      }
    } else if (pl === "standard") {
      if (["ap", "oc", "af"].includes(acc)) {
        let message = {
          ap: "10 Free Applications",
          oc: "Two",
          af: "100%",
        };
        return <p className="sub-description">{message[acc]}</p>;
      } else if (["sp"].includes(acc)) {
        //icons
        return checkIcon;
      } else {
        return "";
      }
    } else if (pl === "premium") {
      if (["ap", "oc", "af"].includes(acc)) {
        let message = {
          ap: "Unlimited Free Application",
          oc: "All",
          af: "100%",
        };
        return <p className="sub-description">{message[acc]}</p>;
      } else if (["sp", "ep"].includes(acc)) {
        //icons
        return checkIcon;
      } else {
        return "";
      }
    }
  };

  //render
  return (
    // <div className="content-wrap">
    <div className="row">
      <div className="register-step-container container-fluid">
        <div className="row">
          <div className="col-md-12">
            {status !== "success" ? (
              <StatusBar status={status} message="default" />
            ) : (
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-12 ref-count-wrap">
                          <p className="ref-counts">Subscription Plans</p>
                        </div>
                        <div className="row p-0">
                          <table className="sub-uncollaps ">
                            <tbody>
                              <tr>
                                <th style={{ width: "50px" }}></th>
                                <th className="firsthead"></th>
                                {result.map((i, k) => (
                                  <th className="" key={k}>
                                    <div className="sub-bghead ">
                                      <p className="sub-bgheadclr">{i.title}</p>
                                      <div className={`sub-Cnt`}>
                                        <p className="sub-Title pt-1">
                                          {i.billingTitle}
                                        </p>
                                        <p className="sub-subTitle pb-1">
                                          {i.commission}
                                        </p>
                                        <p>
                                          <button
                                            className="get-plan-btn"
                                            onClick={() => onSubmit(i)}
                                            disabled={loading !== null}
                                          >
                                            Get Started
                                          </button>
                                        </p>
                                      </div>
                                    </div>
                                  </th>
                                ))}
                              </tr>
                              {Object.entries(types.planCategory).map(
                                ([m, n]) => (
                                  <tr key={m} className="tb-row">
                                    <td style={{ width: "20px" }}></td>
                                    <td className="sub-topichead sub-lastborder">
                                      {n}
                                    </td>
                                    {result.map((i, k) =>
                                      i.access.includes(m) ? (
                                        <td key={k}>
                                          <div className="sub-Cnt1 sub-lastborder">
                                            <div className="bghead1">
                                              <div>
                                                <p className="register-step-head1">
                                                  {planDesc(m, i.name)}
                                                  {/* <span className="dotbtn">
                                                    <img
                                                      src={common.loadImg(
                                                        "ticksymbol.svg"
                                                      )}
                                                      alt=""
                                                    />
                                                  </span> */}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      ) : (
                                        <td key={k}>
                                          <div className="sub-Cnt1 lastborder">
                                            <div className="bghead1">
                                              <div>
                                                <p className="register-step-head1"></p>
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                      )
                                    )}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading !== null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
    // <div className="row subscription-wrap">
    //   <div className="register-step-container container-fluid">
    //     <div className="row">
    //       <div className="col-md-12">
    //         {status !== "success" ? (
    //           <StatusBar status={status} message="default" />
    //         ) : (
    //           <div className="row">
    //             <div className="col-md-10 mx-auto">
    //               <div className="row">
    //                 <div className="col-lg-12">
    //                   <div className="row sub-bg">
    //                     <div className="col-lg-12 sub-thead">
    //                       Subscription Plans
    //                     </div>
    //                     <div className="row">
    //                       <table className="sub-uncollaps ">
    //                         <tbody>
    //                           <tr>
    //                             <th style={{ width: "50px" }}></th>
    //                             <th className="firsthead"></th>
    //                             {result.map((i, k) => (
    //                               <th className="sub-bgheader" key={k}>
    //                                 <div className="sub-bghead ">
    //                                   <p className="sub-bgheadclr">
    //                                     {i.title}
    //                                   </p>
    //                                   <div className={`sub-Cnt py-3`}>
    //                                     <p className="sub-Title">
    //                                       {i.billingTitle}
    //                                     </p>
    //                                     <p className="sub-subTitle">
    //                                       {i.commission}
    //                                     </p>
    //                                     <p>
    //                                       <button
    //                                         className="register-step-btn px-4"
    //                                         onClick={() => onSubmit(i)}
    //                                         disabled={loading !== null}
    //                                       >
    //                                         Get Started
    //                                       </button>
    //                                     </p>
    //                                   </div>
    //                                 </div>
    //                               </th>
    //                             ))}
    //                           </tr>
    //                           {Object.entries(types.planCategory).map(
    //                             ([m, n]) => (
    //                               <tr key={m}>
    //                                 <td style={{ width: "20px" }}></td>
    //                                 <td className="sub-topichead sub-lastborder">
    //                                   {n}
    //                                 </td>
    //                                 {result.map((i, k) =>
    //                                   i.access.includes(m) ? (
    //                                     <td key={k}>
    //                                       <div className="sub-Cnt1 sub-lastborder">
    //                                         <div className="bghead1">
    //                                           <div>
    //                                             <p className="register-step-head1">
    //                                               {planDesc(m, i.name)}
    //                                               {/* <span className="dotbtn">
    //                                               <img
    //                                                 src={common.loadImg(
    //                                                   "ticksymbol.svg"
    //                                                 )}
    //                                                 alt=""
    //                                               />
    //                                             </span> */}
    //                                             </p>
    //                                           </div>
    //                                         </div>
    //                                       </div>
    //                                     </td>
    //                                   ) : (
    //                                     <td key={k}>
    //                                       <div className="sub-Cnt1 lastborder">
    //                                         <div className="bghead1">
    //                                           <div>
    //                                             <p className="register-step-head1"></p>
    //                                           </div>
    //                                         </div>
    //                                       </div>
    //                                     </td>
    //                                   )
    //                                 )}
    //                               </tr>
    //                             )
    //                           )}
    //                         </tbody>
    //                       </table>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </div>
    //             </div>
    //           </div>
    //         )}
    //       </div>
    //     </div>
    //   </div>
    //   <Backdrop
    //     sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
    //     open={loading !== null}
    //   >
    //     <CircularProgress color="inherit" />
    //   </Backdrop>
    // </div>
    // </div>
  );
}
export default Five;
