import { common, types } from "helpers";
import React, { Fragment, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Modal from "react-modal";
import Select from "react-select";
function ModalCancel(props) {
  const [cancelReason, setCancelReason] = useState(props?.default);
  const [cancelValid, setCancelValid] = useState(props?.secondModal);
  const [error, setError] = useState(props?.default);
  const [viewTextArea, setViewTextArea] = useState(false);
  const onSelectChange = (e) => {
    console.log({ e });
    if (e.value === "other") {
      setViewTextArea(true);
      setCancelReason("");
    } else {
      setViewTextArea(false);
      setCancelReason(e.value);
    }
  };
  return (
    <Modal
      isOpen={props?.open === "cancelInterview"}
      style={common.modalStyle(60)}
      ariaHideApp={false}
      contentLabel=""
    >
      <div className="p-4">
        <div className="et-modal-header d-flex justify-content-between align-items-center">
          <h2 className="et-modal-title">
            {props?.data?.Rescheduled
              ? "Reschedule the interview? "
              : "Are you sure you want to cancel?"}
          </h2>
          <button
            className="et-modal-close"
            onClick={() => {
              props.resetData({});
              props?.close(null);
              setError(null);
            }}
          >
            <img src={common.loadImg("modal-close-icn.svg")} className="cp" />
          </button>
        </div>
        <div className="et-modal-body text-center">
          {cancelValid ? (
            <React.Fragment>
              <div className="mb-4">
                <img
                  src={common.loadImg("delete-confirmation-icn.svg")}
                  alt="Delete confirmation Icon"
                />
              </div>
              <h3>
                Are you sure that you want to cancel your Schedule Interview?
                {/* <br /> If you cancel, the application will be rejected. */}
              </h3>
            </React.Fragment>
          ) : (
            <Row>
              <Col md={12}>
                <label className="form-label">Reschedule Reason</label>
                <div className="signin-form-row">
                  <Select
                    options={types.reschedule}
                    className="custom-select-box cancel-reason"
                    isClearable={true}
                    onChange={onSelectChange}
                  />
                </div>
                {viewTextArea && (
                <Fragment>
                  <textarea
                    className="txtarea form-control pt-2 mt-2"
                    rows={4}
                    placeholder="Reason for cancellation?"
                    value={cancelReason}
                    onChange={(e) => setCancelReason(e.target.value)}
                  ></textarea>
                </Fragment>
              )}
              {error && <p className="error-txt p-0">{error}</p>}
              </Col>
            </Row>
          )}
        </div>

        <div className="et-modal-footer">
          <div className="d-flex justify-content-center">
            {cancelValid ? (
              <React.Fragment>
                <button
                  className="register-step-btn m-2"
                  onClick={() => setCancelValid(false)}
                >
                  Back
                </button>
                <button
                  className="register-step-btn m-2"
                  onClick={() =>
                    props?.onSubmit(props?.data?.id, cancelReason, "Cancelled")
                  }
                  disabled={props?.loading === "cancelInterview" ? true : false}
                >
                  {props?.loading === "cancelInterview"
                    ? "Processing...."
                    : "Confirm"}
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  className="register-step-btn m-2"
                  onClick={() => {
                    if (props?.data?.Rescheduled) {
                      if (!cancelReason) {
                        setError("please  provide your valid reason");
                      } else {
                        props?.onSubmit(
                          props?.data?.id,
                          cancelReason,
                          "Rescheduled"
                        );
                      }
                    } else if (props?.data?.avoidrescheduled >= 2) {
                      setError(
                        "You have reached the maximum number of Reschedules, please contact the support team."
                      );
                    } else {
                      if (!cancelReason) {
                        setError("please enter your valid reason");
                      } else {
                        props?.onSubmit(
                          props?.data?.id,
                          cancelReason,
                          "Rescheduled"
                        );
                      }
                    }
                  }}
                  disabled={props?.loading === "cancelInterview" ? true : false}
                >
                  {props?.loading === "cancelInterview"
                    ? "Processing...."
                    : "Rescheduled Interview"}
                </button>
                <button
                  className="register-step-btn m-2"
                  onClick={() => setCancelValid(true)}
                >
                  Cancel Interview
                </button>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      <div className="shadow-div"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}
export default ModalCancel;
