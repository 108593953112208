import { common } from "helpers";
import moment from "moment";
import { useState } from "react";
import Modal from "react-modal";
function BookSlot(props) {
  const [date, setDate] = useState(props?.default);
  const [error, setError] = useState(props?.default);

  return (
    <Modal
      isOpen={props?.open === "BookSlot"}
      style={common.modalStyle(40)}
      ariaHideApp={false}
      contentLabel="Modal"
    >
      <div className="p-4">
        <div className="et-modal-header d-flex justify-content-between align-items-center">
          <h2 className="et-modal-title">Book your time slot</h2>
          <button
            className="et-modal-close"
            onClick={() => {
              setDate(null);
              setError(null);
              props.resetData({});
              props?.close(null);
            }}
          >
            <img src={common.loadImg("modal-close-icn.svg")} className="cp" />
          </button>
        </div>
        <div className="et-modal-body text-center">
          {props?.data?.timelineSlot?.map((item, index) => (
            <div className="date-slot-wrap mb-3">
              <div className="inner">
                <div>
                  <label className="slot-date">{item?.date}</label>
                </div>
                {item?.slot?.map((slotDate, i) => (
                  <div>
                    <input
                      className="form-check-input slot-checkbox"
                      type="checkbox"
                      checked={date === slotDate}
                      name="slot"
                      value={slotDate}
                      onChange={() => {
                        setDate(slotDate);
                        setError("");
                      }}
                      id={`slot${index}${i}`}
                    />
                    <label
                      className="form-check-label slot-label"
                      htmlFor={`slot${index}${i}`}
                    >
                      {moment(new Date(slotDate)).format("hh:mm A")}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {error && <p className="error-txt p-0">{error}</p>}
        </div>

        <div className="et-modal-footer p-3">
          <div className="d-flex justify-content-center">
            <button
              className="register-step-btn"
              onClick={() => {
                if (!date) {
                  setError("Please choose the Slot");
                } else {
                  props?.onSubmit(props?.data?.type, props?.data?.id, date);
                }
              }}
              disabled={props?.loading === "BookSlot" ? true : false}
            >
              {props?.loading === "BookSlot" ? "Processing...." : "Confirm"}
            </button>
          </div>
        </div>
      </div>
      <div className="shadow-div"></div>
      <div className="greenshow-div"></div>
    </Modal>
  );
}
export default BookSlot;
