import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import {
  Tooltip,
  OverlayTrigger,
  Col,
  Row,
  Accordion,
  Table,
} from "react-bootstrap";
import Modal from "react-modal";
// includes
import { common, api, config } from "helpers";
import StatusBar from "elements/statusBar";
import StripePayment from "elements/stripePayment";
import FileUpload from "elements/fileUpload";

function PaymentForm(props) {
  // props
  const { id, category, taxKey, auth, promo } = props;
  // state
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState(null);
  const [reload, setReload] = useState(null);
  const [myModal, setMyModal] = useState(null);
  const [result, setResult] = useState({});
  const [docs, setDocs] = useState("");
  const [paymentStatus, setPaymentStatus] = useState({
    error: "",
    data: new Date(),
  });
  const [promoCode, setPromoCode] = useState("");
  const [paymentType, setPaymentType] = useState("card");
  const [promoList, setPromoList] = useState([]);
  const authInfo = common.getAuth();

  // effect
  useEffect(() => {
    getInfo("init");
    if (authInfo?.role == "student") {
      setPromoCode(config.free_code);
      setReload(new Date());
    }
  }, []);

  useEffect(() => {
    if (reload !== null) {
      getInfo("promo");
    }
  }, [reload]);

  // api
  const getInfo = (type) => {
    let data = {
      url: "payment_info",
      method: "POST",
      auth: auth ? "token" : "temp",
      body: {
        category,
        id,
        promoCode,
      },
    };
    api.call(data, (res) => {
      let status = "success";
      if (res?.status === 200) {
        setResult(res?.data);
        // promo error
        // if (res?.data?.promoCode?.error) { //recheck
        //   common.notify("E", "Please enter the valid promo code");
        // }
      } else if (res?.status === 500) {
        common.notify("E", res?.data?.error);
        // common.notify("E", "Please enter the valid promo code");
      } else {
        status = "error";
      }
      if (type === "init") {
        setStatus(status);
        if (status === "success" && promo) {
          getPromoList(res.data);
        }
      } else if (type === "promo") {
        setLoading(null);
      }
    });
  };

  const getPromoList = (result) => {
    let data = {
      url: "promo",
      method: "GET",
      query: `?query=${JSON.stringify({
        university: result.item.university._id,
        course: result.item._id,
      })}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setPromoList(res.data?.data);
      }
    });
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    let data = {
      url: "common_upload",
      method: "POST",
      auth: auth ? "token" : "temp",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  // handler
  const onSubmit = () => {
    setSubmit(true);
    if (result.item.name === "basic") {
      makePayment({ iA: null, iB: "", iC: "" });
    } else if (
      Number(result.paymentInfo.grandTotal) === 0 &&
      paymentType === "card"
    ) {
      makePayment({ iA: null, iB: "", iC: paymentType });
    } else if (paymentType === "card") {
      setMyModal("stripe");
    } else if (paymentType === "direct") {
      onSubmitDirect();
    }
  };

  const onSubmitDirect = async () => {
    if (docs !== "") {
      setLoading("submit");
      let result = await uploadFile([docs]);
      if (result.status !== 200) {
        common.notify("E", result.data.error);
        setLoading(null);
      } else {
        makePayment({ iA: null, iB: result.data.link[0], iC: paymentType });
      }
    }
  };

  const makePayment = (paymentData) => {
    setLoading("submit");
    let params = {};
    params = {
      id,
      category,
      promoCode,
      paymentId: paymentData.iA,
      refId: paymentData.iB,
      type: paymentData.iC,
    };

    let data = {
      url: "payment_create",
      method: "POST",
      query: `${category === `subscription` ? `/subscription` : ""}`,
      auth: auth ? "token" : "temp",
      body: params,
    };

    api.call(data, (res) => {
      let modalHide = true;
      if (res.status === 200) {
        props.onSuccess();
      } else {
        if (myModal === "stripe") {
          modalHide = false;
          setPaymentStatus({
            error: res.data.error,
            data: new Date(),
          });
        } else {
          common.notify("E", res.data.error);
        }
      }
      setLoading(null);
      if (modalHide) {
        setMyModal(null);
      }
    });
  };

  const onPromoSubmit = () => {
    setLoading("promo");
    setReload(new Date());
  };

  const applyCode = () => {
    setPromoCode(config.free_code);
    setError(null);
    setLoading("promo");
    setReload(new Date());
  };

  const onApplyPromo = (code) => {
    setMyModal(null);
    setError(null);
    setPromoCode(code);
    setLoading("promo");
    setReload(new Date());
  };

  return (
    <div>
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          <div className="payment-wrap position-relative">
            {loading !== null && (
              <div className="et-loader-wrap payment-loader-wrap">
                <StatusBar
                  status="process"
                  message={
                    loading === "promo" ? "Applying promotion..." : "default"
                  }
                />
              </div>
            )}
            <div className="payment-wrap-card mb-4">
              <div className="payment-wrap-header">
                <div className="d-flex align-items-center">
                  <div className="col-md-6 col-sm-6">
                    <p className="header-title mb-0">Pricing Breakdown</p>
                  </div>
                  <div className="col-md-6 col-sm-6 endtext">
                    <p className="header-amount mb-0">Amount</p>
                  </div>
                </div>
              </div>
              {category === "subscription" ? (
                <div className="payment-wrap-header">
                  <Row>
                    <Col
                      md={6}
                      lg={6}
                      className="d-flex align-items-center justify-content-start flex-wrap"
                    >
                      <div className="me-5">
                        <label className="payment-header-sub-title">
                          Subscription
                        </label>
                        <p className="payment-header-sub-title-name mb-0">
                          {result.item.title}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} lg={6} className="payment-amount-col">
                      <span className="payment-course-amount">
                        {result.paymentInfo.amount}{" "}
                        {result.paymentInfo.currency}
                      </span>
                    </Col>
                  </Row>
                </div>
              ) : (
                category === "course_fee" && (
                  <div className="payment-wrap-header">
                    <Row>
                      <Col
                        md={6}
                        lg={6}
                        className="d-flex align-items-center justify-content-start flex-wrap"
                      >
                        <div className="payment-uni-border pe-3">
                          <img
                            src={common.cloudImg(result.item.university?.logo)}
                            alt=""
                            className=""
                            width={120}
                          />
                        </div>
                        <div className="ms-3 me-5">
                          <label className="payment-header-sub-title">
                            Course
                          </label>
                          <p className="payment-header-sub-title-name mb-0">
                            {result.item.name}
                          </p>
                        </div>
                      </Col>
                      <Col md={6} lg={6} className="payment-amount-col">
                        <span className="payment-course-amount">
                          {result.item.tuitionFee} {result.item.currency}
                        </span>
                      </Col>
                    </Row>
                  </div>
                )
              )}
              {category === "application_fee" && (
                <div className="payment-wrap-header">
                  <Row>
                    <Col md={6} lg={6} sm={6} className="">
                      <p className="mb-0">Application Processing Fee</p>
                    </Col>
                    <Col md={6} lg={6} sm={6} className="payment-amount-col">
                      <span className="payment-other-amount">
                        {result.setup.applicationFees.fees}{" "}
                        {result.setup.applicationFees.currency}
                      </span>
                    </Col>
                  </Row>
                </div>
              )}

              {category !== "subscription" && (
                <Fragment>
                  <div className="payment-wrap-header">
                    <Row>
                      <Col md={9} lg={9} className="">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 250, hide: 400 }}
                          show={error === "promo"}
                          overlay={
                            <Tooltip id="button-tooltip">
                              Promo code is required
                            </Tooltip>
                          }
                        >
                          <div className="d-flex align-items-center">
                            <span>Promotion Applied</span>
                            <input
                              type="text"
                              className="form-control promotion-input ms-3"
                              value={promoCode}
                              name="promoCode"
                              placeholder="XX0000013"
                              onChange={(e) => {
                                setPromoCode(e.target.value);
                                setError(null);
                              }}
                            />
                            <button
                              className="btn btn-promotion"
                              onClick={onPromoSubmit}
                            >
                              Update Code
                            </button>
                            {promoList.length > 0 && (
                              <span
                                className="m-2 et-cp"
                                onClick={() => setMyModal("promo")}
                              >
                                {promoList.length} Promotion Available for you
                                click to see!
                              </span>
                            )}
                          </div>
                        </OverlayTrigger>
                        {authInfo?.role == "student" && (
                          <Fragment>
                            <button
                              className="btn btn-promo-code"
                              onClick={applyCode}
                            >
                              Apply Free Code
                            </button>
                            <p className="noti-txt">
                              This service is Free of Charge
                            </p>
                          </Fragment>
                        )}
                      </Col>
                      <Col
                        md={3}
                        lg={3}
                        className="d-flex align-items-center justify-content-end payment-amount-col"
                      >
                        <span className="casch-back-txt">
                          {result.paymentInfo.promoTotal}{" "}
                          {result.paymentInfo.currency}
                        </span>
                      </Col>
                    </Row>
                  </div>

                  {authInfo?.role != "student" && (
                    <div className="payment-wrap-header">
                      <div className="d-flex align-items-center justify-content-between">
                        <p className="header-amount mb-0">Sub Total</p>
                        <p className="payment-total-amount mb-0">
                          {result.paymentInfo.subTotal}{" "}
                          {result.paymentInfo.currency}
                        </p>
                      </div>
                    </div>
                  )}
                </Fragment>
              )}

              {authInfo?.role != "student" &&
                result.paymentInfo.balance > 0 && (
                  <Fragment>
                    <div className="payment-wrap-header">
                      <Row>
                        <Col md={6} lg={6} sm={6} className="">
                          <p>Balance Amount (Previous Subscription)</p>
                        </Col>
                        <Col
                          md={6}
                          lg={6}
                          sm={6}
                          className="payment-amount-col"
                        >
                          <span className="payment-other-amount">
                            {result.paymentInfo.balance}{" "}
                            {result.paymentInfo.currency}
                          </span>
                        </Col>
                      </Row>
                    </div>

                    <div className="payment-wrap-header">
                      <Row>
                        <Col
                          md={6}
                          lg={6}
                          className="d-flex align-items-center justify-content-start flex-wrap"
                        >
                          <div className="me-5">
                            <label className="payment-header-sub-title">
                              Sub Total
                            </label>
                          </div>
                        </Col>
                        <Col md={6} lg={6} className="payment-amount-col">
                          <span className="payment-course-amount">
                            {result.paymentInfo.subTotal}{" "}
                            {result.paymentInfo.currency}
                          </span>
                        </Col>
                      </Row>
                    </div>
                  </Fragment>
                )}

              {authInfo?.role != "student" &&
                result.setup[taxKey].additionalTax > 0 && (
                  <div className="payment-wrap-header">
                    <Row>
                      <Col md={6} lg={6} sm={6} className="">
                        <p>Addtional Tax</p>
                      </Col>
                      <Col md={3} lg={3} sm={3} className="payment-amount-col">
                        <span className="payment-other-amount">
                          {result.setup[taxKey].additionalTax}%
                        </span>
                      </Col>
                      <Col md={3} lg={3} sm={3} className="payment-amount-col">
                        <span className="payment-other-amount">
                          {result.paymentInfo.additionalTax}{" "}
                          {result.paymentInfo.currency}
                        </span>
                      </Col>
                    </Row>
                  </div>
                )}
              {/* Total Paid */}
              <div className="payment-wrap-header">
                <div className="d-flex align-items-center justify-content-between">
                  <p className="header-amount mb-0">Total to be paid</p>
                  <p className="payment-total-amount mb-0">
                    {result.paymentInfo.grandTotal}{" "}
                    {result.paymentInfo.currency}
                  </p>
                </div>
              </div>

              {/* Cashback */}
              {result.paymentInfo.cashBack > 0 && (
                <div className="payment-wrap-header">
                  <div className="d-flex align-items-center justify-content-between">
                    <p className="header-amount mb-0">Cashback</p>
                    <p className="payment-cashback-amount mb-0">
                      {result.paymentInfo.cashBack}{" "}
                      {result.paymentInfo.currency}
                    </p>
                  </div>
                </div>
              )}
            </div>

            <div className="payment-wrap-card">
              {authInfo?.role != "student" && result.item.name !== "basic" && (
                <div className="payment-wrap-header">
                  <Row>
                    <Col md={3} className="d-flex align-items-center">
                      <p className="mb-0">We support</p>
                    </Col>
                    <Col md={9}>
                      <div className="d-flex align-items-center justify-content-end flex-wrap">
                        <div
                          className="payment-methods ms-3 et-cp"
                          onClick={() => setPaymentType("card")}
                        >
                          <img
                            src={common.loadImg("master-card-icn.svg")}
                            alt=""
                            className=""
                          />
                          <span className="ms-2 me-5">Credit / Debit Card</span>
                          {paymentType === "card" && (
                            <img
                              src={common.loadImg("tick-square.svg")}
                              alt=""
                              className=""
                            />
                          )}
                        </div>
                        {category === "course_fee" && (
                          <div
                            className="payment-methods ms-3 et-cp"
                            onClick={() => setPaymentType("direct")}
                          >
                            <img
                              src={common.loadImg("bank-icn.svg")}
                              alt=""
                              className=""
                            />
                            <span className="ms-2 me-5">Direct Payment</span>
                            {paymentType === "direct" && (
                              <img
                                src={common.loadImg("tick-square.svg")}
                                alt=""
                                className=""
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}

              {paymentType === "direct" && (
                <div className="payment-wrap-header">
                  <p className="header-amount mb-0">Payment Proof</p>
                  <FileUpload
                    multiple={false}
                    onSuccess={(files) => {
                      setDocs(...files.acceptedFiles);
                    }}
                  />
                  <div className="d-flex align-items-center mt-3">
                    {docs === "" && submit && (
                      <p className="error-txt">
                        Please upload the payment proof
                      </p>
                    )}
                    {docs !== "" && (
                      <Fragment>
                        <div className="et-field-value me-5">{docs.name}</div>
                        <div className="d-flex">
                          <Link
                            to=""
                            className="et-view-doc-action"
                            onClick={() => {
                              setDocs("");
                            }}
                          >
                            <img
                              src={common.loadImg("iconly-light-delete.svg")}
                              alt=""
                            />
                          </Link>
                        </div>
                      </Fragment>
                    )}
                  </div>
                </div>
              )}
              {category == "course_fee" && paymentType == "direct" && (
                <div className="payment-wrap-header">
                  <p className="header-amount mb-0">Instructions</p>
                  <p className="">
                    Please upload your bank transfer slip for payment
                    verification
                  </p>
                </div>
              )}
            </div>

            <div className="my-4">
              <Row>
                <Col md={6} className="d-flex align-items-center">
                  <p className="payment-agreement-txt">
                    I agree to the{" "}
                    <Link to="#" className="foot-link">
                      Terms & Conditions
                    </Link>{" "}
                    by clicking Submit
                  </p>
                </Col>
                <Col md={6} className="d-flex justify-content-end">
                  <button
                    className="register-step-btn"
                    onClick={onSubmit}
                    disabled={loading !== null}
                  >
                    {loading === "submit"
                      ? "Processing..."
                      : paymentType === "direct" || result.item.name === "basic"
                      ? "Submit"
                      : "Proceed to Payment"}
                  </button>
                </Col>
              </Row>
            </div>
          </div>
          <Modal
            isOpen={myModal === "promo"}
            style={common.modalStyle(60)}
            contentLabel=""
            ariaHideApp={false}
          >
            <div className="p-4">
              <div className="et-modal-header d-flex justify-content-between align-items-center">
                <h2 className="et-modal-title">Promo Code</h2>
                <button
                  className="et-modal-close"
                  onClick={() => {
                    setMyModal(null);
                  }}
                >
                  <img src={common.loadImg("modal-close-icn.svg")} />
                </button>
              </div>
              <Table bordered hover className="text-center">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Description</th>
                    <th>Value</th>
                    <th>Cashback</th>
                    <th>Promo Code</th>
                    <th>Apply</th>
                  </tr>
                </thead>
                <tbody>
                  {promoList.map((i, k) => (
                    <tr key={k}>
                      <td>{++k}</td>
                      <td>{i?.title}</td>
                      <td>{i?.description}</td>
                      {i.type === "percentage" ? (
                        <td>{i.value + " %"}</td>
                      ) : (
                        <td>
                          {result.paymentInfo.amount}{" "}
                          {result.paymentInfo.currency}
                        </td>
                      )}
                      <td>{i.cashBack ? "Yes" : "No"}</td>
                      <td>{i.code}</td>
                      <td>
                        <button
                          className="btn btn-submit btn-paynow"
                          onClick={() => onApplyPromo(i.code)}
                        >
                          Apply Now
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Modal>
        </Fragment>
      )}
      {myModal === "stripe" && Number(result.paymentInfo.grandTotal) !== 0 && (
        <StripePayment
          status={paymentStatus}
          onCancel={() => setMyModal(null)}
          onSuccess={(res) => {
            makePayment({ iA: res.paymentMethod?.id, iB: "", iC: paymentType });
          }}
        />
      )}
    </div>
  );
}

export default PaymentForm;
