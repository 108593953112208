import { Fragment, useState, useEffect } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
// includes
import AgTable from "elements/AgTable";
import ModalConfirm from "elements/modalConfirm";
import { api, common } from "helpers";
import { isMobile } from "react-device-detect";
import Accordion from "react-bootstrap/Accordion";

function List() {
  // props
  const navigate = useNavigate();
  const { search } = useLocation();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [reload, setReload] = useState(new Date());
  const [deleteData, setDeleteData] = useState(null);
  const [loading, setLoading] = useState(false);
  const currentStatus = search.split("?q=currentStatus=");
  const [user, setUser] = useState({});

  useEffect(() => {
    getUser();
  }, []);

  const getUser = () => {
    if (authInfo?.role !== "student") {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            setUser(res.data);
          }
        }
      );
    }
  };

  // functions
  const onActionA = (data) => {
    if (data.type === "Edit") {
      navigate(`/application/status/${data.item._id}`);
    } else if (data.type === "View") {
      if (data.module == "coordinatorAgentApplication") {
        navigate(`/agent/promoters/application/info/${data.item._id}`);
      } else {
        navigate(`/application/status/${data.item._id}`);
      }
    }
  };

  const onActionB = (data) => {
    if (data.type === "Delete") {
      setDeleteData(data.item);
    }

    if (data.type === "View") {
      setDeleteData(data.item);
      if (data.module == "coordinatorAgentApplication") {
        navigate(`/agent/promoters/application/info/${data.item._id}`);
      } else {
        navigate(`/application/preview/order/${data.item._id}`);
      }
    }

    if (data.type === "Payment") {
      navigate(`/application/payment/fees/${data.item._id}`);
    }
  };

  //api
  const DeleteRecord = (item) => {
    setLoading(true);
    let data = {
      url: "application_order",
      method: "DELETE",
      query: `/${item._id}`,
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        common.notify("S", "Record has been removed");
        setDeleteData(null);
        setReload(new Date());
      }
    });
    setLoading(false);
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex justify-content-between align-items-center">
        <h1 className="page-title">Application</h1>
      </div>
      {/* <div className="content-wrap app-list-wrap">
        <h1 className="page-title">My Application</h1>
        <div className="app-tbls-title">
          {isMobile ? <h1 className="page-title">Paid Applications</h1> : null}
        </div>
        <div className="mb-5">
          <AgTable
            module={
              authInfo.role === "agent" ? "agentApplication" : "application"
            }
            query={{ feePaymentStatus: ["paid"] }}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    courseMulti: { key: "courseId", value: [] },
                    applicationSelect: {
                      key: "currentStatus",
                      value: currentStatus.length ? currentStatus[1] : "",
                    },
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionA}
          />
        </div>
        <div className="app-tbls-title">
          {isMobile ? (
            <h1 className="page-title">Unpaid Applications</h1>
          ) : null}
        </div>
        <AgTable
          query={{ feePaymentStatus: ["not_paid"] }}
          module={
            authInfo.role === "agent"
              ? "agentApplicationOrder"
              : "applicationOrder"
          }
          reload={reload}
          filterData={
            authInfo.role === "agent"
              ? {
                  dateA: {
                    key: "startDate",
                    value: "",
                    title: "Created Date From",
                  },
                  dateB: {
                    key: "endDate",
                    value: "",
                    title: "Created Date To",
                  },
                }
              : {}
          }
          onAction={onActionB}
        />
      </div> */}
      {/* {user.isPromotor && (
        <div className="content-wrap app-list-wrap">
          <h1 className="page-title">Agents Application</h1>
          <div className="app-tbls-title">
            {isMobile ? (
              <h1 className="page-title">Paid Applications</h1>
            ) : null}
          </div>
          <div className="mb-5">
            <AgTable
              module={"coordinatorAgentApplication"}
              query={{ feePaymentStatus: ["paid"] }}
              reload={reload}
              filterData={
                authInfo.role === "agent"
                  ? {
                      courseMulti: { key: "courseId", value: [] },
                      applicationSelect: {
                        key: "currentStatus",
                        value: currentStatus.length ? currentStatus[1] : "",
                      },
                      dateA: {
                        key: "startDate",
                        value: "",
                        title: "Created Date From",
                      },
                      dateB: {
                        key: "endDate",
                        value: "",
                        title: "Created Date To",
                      },
                    }
                  : {}
              }
              onAction={onActionA}
            />
          </div>
          <div className="app-tbls-title">
            {isMobile ? (
              <h1 className="page-title">Unpaid Applications</h1>
            ) : null}
          </div>
          <AgTable
            query={{ feePaymentStatus: ["not_paid"] }}
            module={"coordinatorAgentApplication"}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionB}
          />
        </div>
      )} */}
<div className="content-wrap app-list-wrap">
{(authInfo?.role !== "student" && user?.isPromotor || user?.agent?.isPromotor) ?
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>My Applications</Accordion.Header>
          <Accordion.Body>
      
        {/* <h1 className="page-title">My Application</h1> */}
        <div className="app-tbls-title">
          {isMobile ? <h1 className="page-title">Paid Applications</h1> : null}
        </div>
        <div className="mb-5">
          <AgTable
            module={
              authInfo.role === "agent" ? "agentApplication" : "application"
            }
            query={{ feePaymentStatus: ["paid"] }}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    courseMulti: { key: "courseId", value: [] },
                    applicationSelect: {
                      key: "currentStatus",
                      value: currentStatus.length ? currentStatus[1] : "",
                    },
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionA}
          />
        </div>
        <div className="app-tbls-title">
          {isMobile ? (
            <h1 className="page-title">Unpaid Applications</h1>
          ) : null}
        </div>
        <AgTable
          query={{ feePaymentStatus: ["not_paid"] }}
          module={
            authInfo.role === "agent"
              ? "agentApplicationOrder"
              : "applicationOrder"
          }
          reload={reload}
          filterData={
            authInfo.role === "agent"
              ? {
                  dateA: {
                    key: "startDate",
                    value: "",
                    title: "Created Date From",
                  },
                  dateB: {
                    key: "endDate",
                    value: "",
                    title: "Created Date To",
                  },
                }
              : {}
          }
          onAction={onActionB}
        />
      {/* </div> */}
      </Accordion.Body>
        </Accordion.Item>
      {/* {(user?.isPromotor || user?.agent?.isPromotor) && ( */}
        <Accordion.Item eventKey="1">
          <Accordion.Header>Agents Applications</Accordion.Header>
          <Accordion.Body>
        <>
        {/* <div className="content-wrap app-list-wrap"> */}
          {/* <h1 className="page-title">Agents Application</h1> */}
          <div className="app-tbls-title">
            {isMobile  ? (
              <h1 className="page-title">Paid Applications</h1>
            ) : null}
          </div>
          <div className="mb-5">
            <AgTable
              module={"coordinatorAgentApplication"}
              query={{ feePaymentStatus: ["paid"] }}
              reload={reload}
              filterData={
                authInfo.role === "agent"
                  ? {
                      courseMulti: { key: "courseId", value: [] },
                      applicationSelect: {
                        key: "currentStatus",
                        value: currentStatus.length ? currentStatus[1] : "",
                      },
                      dateA: {
                        key: "startDate",
                        value: "",
                        title: "Created Date From",
                      },
                      dateB: {
                        key: "endDate",
                        value: "",
                        title: "Created Date To",
                      },
                    }
                  : {}
              }
              onAction={onActionA}
              title={"Paid Applications"}
            />
          </div>
          <div className="app-tbls-title">
            {isMobile ? (
              <h1 className="page-title">Unpaid Applications</h1>
            ) : null}
          </div>
          <AgTable
            query={{ feePaymentStatus: ["not_paid"] }}
            module={"coordinatorAgentApplication"}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionB}
            title={"Unpaid Applications"}
          />
          </>
        
            </Accordion.Body>
         </Accordion.Item>
      {/* )} */}
      </Accordion>
      :
      <>
      <div className="app-tbls-title">
          {isMobile ? <h1 className="page-title">Paid Applications</h1> : null}
        </div>
        <div className="mb-5">
          <AgTable
            module={
              authInfo.role === "agent" ? "agentApplication" : "application"
            }
            query={{ feePaymentStatus: ["paid"] }}
            reload={reload}
            filterData={
              authInfo.role === "agent"
                ? {
                    courseMulti: { key: "courseId", value: [] },
                    applicationSelect: {
                      key: "currentStatus",
                      value: currentStatus.length ? currentStatus[1] : "",
                    },
                    dateA: {
                      key: "startDate",
                      value: "",
                      title: "Created Date From",
                    },
                    dateB: {
                      key: "endDate",
                      value: "",
                      title: "Created Date To",
                    },
                  }
                : {}
            }
            onAction={onActionA}
          />
        </div>
        <div className="app-tbls-title">
          {isMobile ? (
            <h1 className="page-title">Unpaid Applications</h1>
          ) : null}
        </div>
        <AgTable
          query={{ feePaymentStatus: ["not_paid"] }}
          module={
            authInfo.role === "agent"
              ? "agentApplicationOrder"
              : "applicationOrder"
          }
          reload={reload}
          filterData={
            authInfo.role === "agent"
              ? {
                  dateA: {
                    key: "startDate",
                    value: "",
                    title: "Created Date From",
                  },
                  dateB: {
                    key: "endDate",
                    value: "",
                    title: "Created Date To",
                  },
                }
              : {}
          }
          onAction={onActionB}
        />
        </>
}
      </div>

      {deleteData !== null && (
        <ModalConfirm
          loading={loading}
          onYes={() => DeleteRecord(deleteData)}
          onNo={() => setDeleteData(null)}
        />
      )}
    </Fragment>
  );
}

export default List;
