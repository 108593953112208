import { Fragment, useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Modal from "react-modal";
import { Link, useOutletContext } from "react-router-dom";
import Select from "react-select";
// includes
import { api, common } from "helpers";
// pages
import FileUpload from "elements/fileUpload";
import ModalConfirm from "elements/modalConfirm";

function StudentDocs(props) {
  // props
  const { formType, formPreview, studentId, data, onlyView } = props;

  const [outletAction] = useOutletContext();

  const TravelDocument = {
    passportDocs: {
      label:
        data?.birth?._id && data?.birth?._id == "GB"
          ? "Birth Certificate or Proof of ID Docs"
          : "Passport Docs",
      multi: true,
    },
    euDocs: {
      label: "EU Share Code",
      multi: false,
    },
    brpDocs: {
      label: "Biometric Residence Permit (BRP)",
      multi: false,
    },
    sfeAppDocs: {
      label: "Letter of Approval from SFE / Academic Progress",
      multi: false,
    },
    homeOfficeDocs: {
      label: "Home office letter",
      multi: false,
    },
    poeDocs: {
      label: "Proof of evidence of Stay",
      multi: true,
    },
  };
  // state
  const [init] = useState(true);
  const [submit, setSubmit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(null);
  const [docs, setDocs] = useState({});
  const [docsA, setDocsA] = useState({
    passportDocs: data?.passportDocs || [],
    euDocs: data?.euDocs,
    brpDocs: data?.brpDocs,
    sfeAppDocs: data?.sfeAppDocs,
    homeOfficeDocs: data?.homeOfficeDocs,
    poeDocs: data?.poeDocs,
  });

  const [docsModal, setDocsModal] = useState(null);
  const [travelModal, setTravelModal] = useState(null);
  const [errors, setErrors] = useState({
    title: "",
    docs: "",
  });
  const [authData] = useState(common.getAuth());

  // effect
  useEffect(() => {
    setDocs(reParse(data));
  }, []);

  useEffect(() => {
    if (submit) {
      validate();
    }
  }, [docsModal]);

  useEffect(() => {
    if (submit) {
      validateTravel();
    }
  }, [travelModal]);

  // api
  const getResult = () => {
    let data = {};
    if (formType === "application") {
      data = {
        url: "application_profile",
        query: `/${studentId}`,
        method: "GET",
      };
    } else {
      if (authData.role === "student") {
        data = {
          url: "student_profile",
          method: "GET",
        };
      } else {
        data = {
          url: "agent_student",
          query: `/${studentId}`,
          method: "GET",
        };
      }
    }

    api.call(data, (res) => {
      if (res.status === 200) {
        const {
          passportDocs,
          euDocs,
          brpDocs,
          sfeAppDocs,
          homeOfficeDocs,
          poeDocs,
        } = res.data;
        setDocsA({
          passportDocs: passportDocs ?? [],
          euDocs,
          brpDocs,
          sfeAppDocs,
          homeOfficeDocs,
          poeDocs,
        });

        setDocs(reParse(res.data));
      }
      closeModal();
    });
  };

  const uploadFile = async (arr) => {
    var formdata = new FormData();
    arr.map((i) => {
      formdata.append("file", i);
    });
    let data = {
      url: "common_upload",
      method: "POST",
      body: formdata,
      ssr: true,
      cType: 6,
    };
    let resultData = await api.call(data);
    return resultData;
  };

  const updateData = async (aData) => {
    if (formType === "application") {
      aData = {
        url: "application_profile",
        method: "PUT",
        query: `/${studentId}`,
        body: aData,
      };
    } else {
      if (authData.role === "student") {
        aData = {
          url: "student_profile",
          method: "PUT",
          body: aData,
        };
      } else if (authData.role === "agent" || authData.role === "sub_agent") {
        aData = {
          url: "agent_student",
          method: "PUT",
          query: `/${studentId}`,
          body: aData,
        };
      }
    }
    api.call(aData, (res) => {
      if (res.status === 200) {
        getResult();
        if (authData.role === "student" && formType !== "application") {
          outletAction("myProgress");
        }
      } else {
        common.notify("E", res.data.error);
        closeModal();
      }
    });
  };
  // handler
  const onSubmitDocsModal = async () => {
    setSubmit(true);
    let isValid = validate();
    if (isValid) {
      setLoading(true);
      let reDocs = common.reParse(docs[docsModal.key]);
      if (docsModal.edit?.[1] === null) {
        reDocs.data[docsModal.edit[0]] = {
          title: docsModal.title,
          description: docsModal.description,
          docs: reDocs.data[docsModal.edit[0]].docs,
        };
        // upload
        if (docsModal.docs.length > 0) {
          let result = await uploadFile(docsModal.docs);
          if (result.status === 200) {
            reDocs.data[docsModal.edit[0]].docs.push(...result.data.link);
          }
        }
      } else {
        let result = await uploadFile(docsModal.docs);
        if (result.status === 200) {
          if (reDocs.selectInp) {
            let key = docsModal.select?.value ?? 0;
            if (docsModal.edit === null) {
              reDocs.data[key].docs.push(...result.data.link);
            } else {
              reDocs.data[docsModal.edit[0]].docs[docsModal.edit[1]] =
                result.data.link[0];
            }
          } else {
            if (docsModal.edit === null) {
              reDocs.data.push({
                title: docsModal.title,
                description: docsModal.description,
                docs: [...result.data.link],
              });
            } else {
              reDocs.data[docsModal.edit[0]].docs[docsModal.edit[1]] =
                result.data.link[0];
            }
          }
        }
      }
      // params
      let aData = {
        [docsModal.key]: reDocs.data,
      };
      updateData(aData);
    }
  };

  const onSubmitTravelModal = async (multi = false) => {
    setSubmit(true);

    let isValid = validateTravel();
    if (isValid) {
      setLoading(true);
      let result = await uploadFile(travelModal.docs);
      if (result.status !== 200) {
        common.notify("E", result.data.error);
        setLoading(false);
      } else {
        // params

        let aData = {
          [travelModal.key]: multi
            ? [...docsA[travelModal.key], ...result.data.link]
            : result.data.link[0],
        };

        updateData(aData);
      }
    }
  };

  // validate
  const validate = () => {
    let isValid = true;
    let err = {
      title: "",
      docs: "",
    };
    if (["extraCurricular", "otherActivity"].includes(docsModal.key)) {
      if (docsModal.edit === null || docsModal.edit?.[1] === null) {
        if (docsModal.title === "") {
          err.title = "Please enter the document title";
          isValid = false;
        }
      }
    }
    if (docsModal.edit?.[1] !== null && docsModal.docs.length === 0) {
      err.docs = "Please select the document";
      isValid = false;
    }

    setErrors(err);
    return isValid;
  };

  const validateTravel = () => {
    let isValid = true;
    let err = {
      title: "",
      docs: "",
    };
    if (travelModal.docs.length === 0) {
      err.docs = "Please select the document";
      isValid = false;
    }
    setErrors(err);
    return isValid;
  };

  // support
  const closeModal = () => {
    setSubmit(false);
    setLoading(false);
    setConfirm(null);
    setDocsModal(null);
    setTravelModal(null);
  };

  const onYes = () => {
    if (confirm[0] === "modal") {
      if (docsModal) {
        setDocsModal((prev) => {
          let nData = { ...prev };

          nData.docs.splice(confirm[1], 1);
          return nData;
        });
      } else {
        setTravelModal((prev) => {
          let nData = { ...prev };

          nData.multi ? nData.docs.splice(confirm[1], 1) : (nData.docs = []);
          return nData;
        });
      }
      setConfirm(null);
    } else {
      let aData = {};
      if (
        ["euDocs", "brpDocs", "sfeAppDocs", "homeOfficeDocs"].includes(
          confirm[0]
        )
      ) {
        aData = {
          [confirm[0]]: null,
        };
      } else if (["poeDocs", "passportDocs"].includes(confirm[0])) {
        aData = {
          [confirm[0]]: docsA[confirm[0]].filter(
            (i, index) => index !== confirm[2]
          ),
        };
      } else {
        let reDocs = common.reParse(docs[confirm[0]]);
        reDocs.data[confirm[1]].docs.splice(confirm[2], 1);
        if (!reDocs.selectInp && !reDocs.data[confirm[1]].docs.length) {
          reDocs.data.splice(confirm[1], 1);
        }
        aData = {
          [confirm[0]]: reDocs.data,
        };
      }
      setLoading(true);
      updateData(aData);
    }
  };

  const reParse = (data) => {
    let res = {
      college: {
        title: "Education Documents",
        data: data.college,
        docs: data.college.filter((i) => i.docs.length > 0).length,
        docsTitle: "institutionName",
        selectInp: true,
        selectTitle: "College",
        selectData: data.college.map((i, k) => ({
          label: i.institutionName,
          value: k,
        })),
      },
      // exam: {
      //   title: "Test Score Documents",
      //   data: data.exam,
      //   docs: data.exam.filter((i) => i.docs.length > 0).length,
      //   docsTitle: "examType",
      //   selectInp: true,
      //   selectTitle: "Course",
      //   selectData: data.exam.map((i, k) => ({
      //     label: i.examType,
      //     value: k,
      //   })),
      // },
      workExp: {
        title: "Work Experience Documents",
        data: data.workExp,
        docs: data.workExp.filter((i) => i.docs.length > 0).length,
        docsTitle: "companyName",
        selectInp: true,
        selectTitle: "Company",
        selectData: data.workExp.map((i, k) => ({
          label: i.companyName,
          value: k,
        })),
      },
      extraCurricular: {
        title: "Extracurricular Activity Documents",
        subTitle: "Sports, Arts, and any other activities documents,...etc",
        data: data.extraCurricular,
        docs: data.extraCurricular.filter((i) => i.docs.length > 0).length,
        docsTitle: "",
        selectInp: false,
        selectTitle: "",
        selectData: [],
      },
      otherActivity: {
        title: "Other Documents",
        subTitle: "CV, Travel documents,...etc",
        data: data.otherActivity,
        docs: data.otherActivity.filter((i) => i.docs.length > 0).length,
        docsTitle: "",
        selectInp: false,
        selectTitle: "",
        selectData: [],
      },
    };
    return res;
  };

  // render
  return (
    <Fragment>
      {init && (
        <Fragment>
          <div className="et-view-content-wrap">
            {(authData.role === "agent" || authData.role === "sub_agent") && (
              <div className="register-step-header-general">
                <p className="register-step-title mb-0">Document Attach</p>
              </div>
            )}
            {Object.keys(docs).map((i, k) => {
              let nI = docs[i];
              return (
                <Fragment key={k}>
                  <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                    <h3 className="et-view-secondary-title">{nI.title}</h3>
                    {nI.docs !== 0 && !formPreview && (
                      <Link
                        to=""
                        onClick={() => {
                          if (nI.selectInp && nI.selectData.length === 0) {
                            common.notify(
                              "W",
                              `Please add the ${nI.selectTitle}`
                            );
                          } else {
                            setDocsModal({
                              key: i,
                              title: "",
                              description: "",
                              select: 0,
                              docs: [],
                              edit: null,
                            });
                          }
                        }}
                        className="et-view-title-link"
                      >
                        Add
                      </Link>
                    )}
                  </div>
                  {nI?.docs === 0 ? (
                    <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                      <div className="et-vc-left">
                        <p className="et-vc-info-required">
                          {nI.subTitle || nI.title}
                        </p>
                      </div>
                      {!formPreview && (
                        <div className="et-vc-right">
                          <Link
                            to="#"
                            className="et-view-inner-link"
                            onClick={() => {
                              if (nI.selectInp && nI.selectData.length === 0) {
                                common.notify(
                                  "W",
                                  `Please add the ${nI.selectTitle}`
                                );
                              } else {
                                setDocsModal({
                                  key: i,
                                  title: "",
                                  description: "",
                                  select: 0,
                                  docs: [],
                                  edit: null,
                                });
                              }
                            }}
                          >
                            Upload file
                          </Link>
                        </div>
                      )}
                    </div>
                  ) : (
                    <Fragment>
                      <div className="et-vc-info et-view-border-bottom res-padding-none">
                        <ul className="et-vc-list row">
                          {/* Already added */}
                          {nI.data.map((iA, kA) => {
                            return (
                              <li
                                className="col-md-6 et-view-border-right et-view-border-bottom res-padding-none"
                                key={kA}
                              >
                                <div className="et-list-inner res-display-flex">
                                  <div className="et-doc-actions">
                                    <div className="d-flex mt-3">
                                      {!nI.selectInp && !formPreview && (
                                        <Link
                                          to=""
                                          className="et-view-doc-action me-3"
                                          onClick={() => {
                                            setDocsModal({
                                              key: i,
                                              docs: [],
                                              title: iA?.title ?? "",
                                              description:
                                                iA?.description ?? "",
                                              select: kA,
                                              edit: [kA, null],
                                            });
                                          }}
                                        >
                                          <img
                                            src={common.loadImg("edit-btn.svg")}
                                            alt=""
                                          />
                                        </Link>
                                      )}
                                    </div>
                                  </div>
                                  {nI.selectInp ? (
                                    <p className="et-field-name">
                                      {iA[nI.docsTitle]}
                                    </p>
                                  ) : (
                                    <p className="et-field-name">{iA.title}</p>
                                  )}
                                  {iA.docs.length ? (
                                    iA.docs.map((iB, kB) => (
                                      <div
                                        key={kB}
                                        className="row mb-3 res-pad"
                                      >
                                        <div className="et-field-value col-md-9">
                                          <span
                                            className="et-link"
                                            onClick={() =>
                                              common.openCloudUrl(iB)
                                            }
                                          >
                                            {common.fileName(iB)}
                                          </span>
                                        </div>
                                        {!formPreview && (
                                          <div className="d-flex col-md-3">
                                            {!props.onlyView && (
                                              <Link
                                                to=""
                                                className="et-view-doc-action"
                                                onClick={() =>
                                                  setConfirm([i, kA, kB])
                                                }
                                              >
                                                <img
                                                  src={common.loadImg(
                                                    "iconly-light-delete.svg"
                                                  )}
                                                  alt=""
                                                />
                                              </Link>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    ))
                                  ) : (
                                    <div className="row mb-3">
                                      <div className="et-field-value col-md-9">
                                        No files uploaded yet
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </Fragment>
                  )}
                </Fragment>
              );
            })}
            <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
              <h3 className="et-view-secondary-title">Application Documents</h3>
            </div>
            <div className="et-vc-info et-view-border-bottom res-padding-none">
              <ul className="et-vc-list row">
                {[
                  "passportDocs",
                  "euDocs",
                  "brpDocs",
                  "sfeAppDocs",
                  "homeOfficeDocs",
                  "poeDocs",
                ].map((i, k) => (
                  <li
                    className="col-md-6 et-view-border-right et-view-border-bottom res-padding-none"
                    key={k}
                  >
                    <div className="et-list-inner res-display-flex">
                      {!formPreview && (
                        <div className="et-doc-actions">
                          {docsA[i] &&
                            docsA[i].length > 0 &&
                            !TravelDocument[i].multi && (
                              <div className="d-flex mt-3">
                                <Link
                                  to=""
                                  className="et-view-doc-action me-3"
                                  onClick={() =>
                                    setTravelModal({
                                      key: i,
                                      docs: [],
                                    })
                                  }
                                >
                                  <img
                                    src={common.loadImg("edit-btn.svg")}
                                    alt=""
                                  />
                                </Link>
                                {!props.onlyView && (
                                  <Link
                                    to=""
                                    className="et-view-doc-action"
                                    onClick={() => setConfirm([i, k])}
                                  >
                                    <img
                                      src={common.loadImg(
                                        "iconly-light-delete.svg"
                                      )}
                                      alt=""
                                    />
                                  </Link>
                                )}
                              </div>
                            )}
                        </div>
                      )}
                      <p className="et-field-name">
                        Copy of your {TravelDocument[i]?.label}
                      </p>
                      {docsA[i] &&
                      docsA[i].length > 0 &&
                      !TravelDocument[i].multi ? (
                        <p
                          className="et-field-value et-link"
                          onClick={() => common.openCloudUrl(docsA[i])}
                        >
                          {common.fileName(docsA[i])}
                        </p>
                      ) : docsA[i] && TravelDocument[i].multi ? (
                        <Fragment>
                          {formPreview ? (
                            <div className="row mb-3 res-pad">
                              {onlyView ? (
                                <div className="et-field-value col-md-9">
                                  {docsA[i].length > 0 &&
                                    docsA[i].map((item, index) => (
                                      <p
                                        className="d-flex justify-content-between et-field-value et-link"
                                        key={index}
                                      >
                                        <p
                                          onClick={() =>
                                            common.openCloudUrl(item)
                                          }
                                        >
                                          {common.fileName(item)}
                                        </p>
                                        {!props.onlyView && (
                                          <Link
                                            to=""
                                            className="et-view-doc-action"
                                            onClick={() =>
                                              setConfirm([i, k, index])
                                            }
                                          >
                                            <img
                                              src={common.loadImg(
                                                "iconly-light-delete.svg"
                                              )}
                                              alt=""
                                            />
                                          </Link>
                                        )}
                                      </p>
                                    ))}
                                  {!docsA[i] ||
                                    (docsA[i].length == 0 && (
                                      <div className="et-field-value col-md-9">
                                        No file uploaded yet
                                      </div>
                                    ))}
                                </div>
                              ) : (
                                <div className="et-field-value col-md-9">
                                  No file uploaded yet
                                </div>
                              )}
                            </div>
                          ) : (
                            <Fragment>
                              <Link
                                to="#"
                                className="et-view-inner-link"
                                onClick={() =>
                                  setTravelModal({
                                    key: i,
                                    docs: [],
                                    multi: TravelDocument[i].multi,
                                  })
                                }
                              >
                                Upload file
                              </Link>
                              {docsA[i].length > 0 &&
                                docsA[i].map((item, index) => (
                                  <p
                                    className="d-flex justify-content-between et-field-value et-link"
                                    key={index}
                                  >
                                    <p
                                      onClick={() => common.openCloudUrl(item)}
                                    >
                                      {common.fileName(item)}
                                    </p>
                                    {!props.onlyView && (
                                      <Link
                                        to=""
                                        className="et-view-doc-action"
                                        onClick={() =>
                                          setConfirm([i, k, index])
                                        }
                                      >
                                        <img
                                          src={common.loadImg(
                                            "iconly-light-delete.svg"
                                          )}
                                          alt=""
                                        />
                                      </Link>
                                    )}
                                  </p>
                                ))}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <Fragment>
                          {formPreview ? (
                            <div className="row mb-3 res-pad">
                              <div className="et-field-value col-md-9">
                                No file uploaded yet
                              </div>
                            </div>
                          ) : (
                            <Link
                              to="#"
                              className="et-view-inner-link"
                              onClick={() =>
                                setTravelModal({
                                  key: i,
                                  docs: [],
                                  multi: TravelDocument[i].multi,
                                })
                              }
                            >
                              Upload file
                            </Link>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {docsModal !== null && (
            <Modal
              isOpen={true}
              style={common.modalStyle(75)}
              contentLabel=""
              ariaHideApp={false}
            >
              <Container fluid>
                <Row>
                  <Col
                    lg={12}
                    className="register-step-header d-flex justify-content-between align-items-center"
                  >
                    <p className="register-step-title mb-0">Document</p>
                    <img
                      src={common.loadImg("close-icn.svg")}
                      alt="arrow"
                      onClick={closeModal}
                      className="cp"
                    />
                  </Col>
                  <Col lg={12} className="register-step-content">
                    {docs[docsModal.key].selectInp ? (
                      <Row>
                        <Col md={6}>
                          <label className="form-label">
                            Select {docs[docsModal.key].selectTitle}
                          </label>
                          <div className="signin-form-row">
                            <Select
                              className="custom-select-box"
                              options={docs[docsModal.key].selectData}
                              value={
                                docs[docsModal.key].selectData[docsModal.select]
                              }
                              isDisabled={docsModal.edit !== null}
                              onChange={(data) => {
                                setDocsModal((prev) => {
                                  let nData = { ...prev };
                                  nData.select = data;
                                  return nData;
                                });
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      (docsModal.edit === null ||
                        docsModal.edit?.[1] === null) && (
                        <Row>
                          <Col md={6}>
                            <label className="form-label">Title</label>
                            <div className="signin-form-row">
                              <input
                                type="text"
                                name="title"
                                className="form-control"
                                value={docsModal.title}
                                placeholder="Enter the document title"
                                onChange={(e) =>
                                  setDocsModal((prev) => ({
                                    ...prev,
                                    title: e.target.value,
                                  }))
                                }
                              />
                            </div>
                            <p className="error-txt">
                              {submit && errors.title ? errors.title : ""}
                            </p>
                          </Col>
                          <Col md={6}>
                            <label className="form-label">Description</label>
                            <div className="signin-form-row">
                              <input
                                type="text"
                                name="description"
                                className="form-control"
                                maxLength={150}
                                value={docsModal.description}
                                placeholder="Enter the description"
                                onChange={(e) =>
                                  setDocsModal((prev) => ({
                                    ...prev,
                                    description: e.target.value,
                                  }))
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      )
                    )}
                    <Row>
                      <Col md={12}>
                        <FileUpload
                          multiple={
                            docsModal.edit === null ||
                            docsModal.edit?.[1] === null
                          }
                          onSuccess={(files) =>
                            setDocsModal((prev) => {
                              let nData = { ...prev };
                              if (
                                docsModal.edit === null ||
                                docsModal.edit?.[1] === null
                              ) {
                                nData.docs.push(...files.acceptedFiles);
                              } else {
                                nData.docs = [...files.acceptedFiles];
                              }
                              return nData;
                            })
                          }
                        />
                        <p className="error-txt">
                          {submit && errors.docs ? errors.docs : ""}
                        </p>
                        {docsModal.docs.map((item, key) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={key}
                          >
                            <p className="agt-proof-file mb-0">{item.name}</p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() => setConfirm(["modal", key])}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="register-form-footer mt-0 mb-3">
                  <Col lg={12} className="text-right">
                    <Link
                      to="#"
                      className="m-0 p-0"
                      onClick={() => onSubmitDocsModal()}
                    >
                      <button className="btn-default" disabled={loading}>
                        {loading ? "Procesing..." : "Save & Update"}
                      </button>
                    </Link>
                  </Col>
                </Row>
              </Container>
              <div className="shadow-div m-0"></div>
              <div className="greenshow-div"></div>
            </Modal>
          )}

          {travelModal !== null && (
            <Modal
              isOpen={true}
              style={common.modalStyle(75)}
              contentLabel=""
              ariaHideApp={false}
            >
              <Container fluid>
                <Row>
                  <Col
                    lg={12}
                    className="register-step-header d-flex justify-content-between align-items-center"
                  >
                    <p className="register-step-title mb-0">Document</p>
                    <img
                      src={common.loadImg("close-icn.svg")}
                      alt="arrow"
                      onClick={closeModal}
                      className="cp"
                    />
                  </Col>
                  <Col lg={12} className="register-step-content">
                    <Row>
                      <Col md={12}>
                        <FileUpload
                          multiple={travelModal?.multi}
                          onSuccess={(files) =>
                            setTravelModal((prev) => ({
                              ...prev,
                              docs: files.acceptedFiles,
                            }))
                          }
                        />
                        <p className="error-txt">
                          {submit && errors.docs ? errors.docs : ""}
                        </p>
                        {travelModal.docs.map((item, key) => (
                          <div
                            className="d-flex align-items-center justify-content-between pt-3"
                            key={key}
                          >
                            <p className="agt-proof-file mb-0">{item.name}</p>
                            <img
                              src={common.loadImg("iconly-dark-delete.svg")}
                              className="et-cp"
                              onClick={() => setConfirm(["modal", key])}
                            />
                          </div>
                        ))}
                      </Col>
                    </Row>
                    <Row className="register-form-footer">
                      <Col lg={12} className="text-right pt-4 pe-2">
                        <Link
                          to="#"
                          className="m-0 p-0"
                          onClick={() =>
                            onSubmitTravelModal(travelModal?.multi)
                          }
                        >
                          <button className="btn-default" disabled={loading}>
                            {loading ? "Procesing..." : "Save & Update"}
                          </button>
                        </Link>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
              <div className="shadow-div m-0"></div>
              <div className="greenshow-div"></div>
            </Modal>
          )}

          {confirm && (
            <ModalConfirm
              loading={loading}
              onYes={() => onYes()}
              onNo={() => setConfirm(null)}
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
}
export default StudentDocs;
