import { Fragment, useEffect, useRef, useState } from "react";
import Lightbox from "react-18-image-lightbox";
import Carousel from "react-bootstrap/Carousel";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import Slider from "react-slick";
//includes
import GoogleMaps from "elements/googleMaps";
import StatusBar from "elements/statusBar";
import { api, common } from "helpers";
import CoursePickup from "includes/coursePickup";
// const
const htmlParse = require("html-react-parser");

function Info() {
  // props
  let { id, tId, tKey } = useParams();
  const navigate = useNavigate();
  const refLocation = useRef(null);
  const refAbout = useRef(null);
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState([]);
  const [courseResult, setCourseResult] = useState([]);
  const [galleryView, SetGalleryView] = useState(false);
  const [galleryIndex, SetGalleryIndex] = useState(0);
  const [galleryImages, setGalleryImages] = useState([]);
  const [courseId, setCourseId] = useState(null);
  // effect
  useEffect(() => {
    getUniversityInfo();
  }, []);

  // api
  const getUniversityInfo = () => {
    let data = {
      url: "university",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        setGalleryImages(res.data.gallery);
        getUniversityCourseInfo();
      } else {
        setStatus("error");
      }
    });
  };

  const getUniversityCourseInfo = () => {
    setCourseResult({ res: { data: [] } });
    let query = {
      university: id,
      tags: ["top-courses"],
    };
    // data
    let data = {
      url: "course_tag",
      query: `?query=${JSON.stringify(query)}&offset=1&1&limit=7`,
      method: "GET",
    };
    api.call(data, (res) => {
      let apiStatus = "error";
      if (res.status === 200) {
        setCourseResult(res.data);
        apiStatus = "success";
      }
      setStatus(apiStatus);
    });
  };

  // support
  const getApplyText = () => {
    let res = "Apply Now";
    if (["ch", "cp"].includes(tId)) {
      res = "Change Now";
    } else if (["ur", "u"].includes(tId)) {
      res = "Update Now";
    } else if (tId === "s") {
      res = "Pick Now";
    }
    return res;
  };

  const offerUI = (item) => {
    const { university } = item;

    if (university?.cab && university?.cbCy && authInfo?.role == "student") {
      return (
        <li className="green d-flex mb-2">
          <img
            src={common.loadImg("cashback-icon.svg")}
            alt="cashback-icon"
            className="cc-header-icn"
          />
          {common.currencyToSymbol(university?.cbCy)}
          {university?.cab} Cashback
        </li>
      );
    }

    if (university?.agcyCom && university?.agcy && authInfo?.role == "agent") {
      return (
        <li className="green d-flex mb-2">
          <img
            src={common.loadImg("cashback-icon.svg")}
            alt="cashback-icon"
            className="cc-header-icn"
          />
          {university?.agcyCom == "Percentage"
            ? ` ${university?.agcy}% Commission`
            : `${common.currencyToSymbol(university?.agcyCom)} ${
                university?.agcy
              } Commission`}
        </li>
      );
    }
    return null;
  };

  //render
  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate(-1)}
            />
            {/* <h3 className="inner-page-header-title mb-0 me-1"></h3> */}
            <h3 className="inner-page-header-title mb-0">
              {status === "process" ? "Loading..." : result.name}
            </h3>
          </div>
        </div>
      </div>
      <div className="content-wrap">
        {status !== "success" ? (
          <StatusBar status={status} />
        ) : (
          <Fragment>
            <div className="univ-detail-wrap">
              <div className="row">
                <div className="col-md-6">
                  <div className="row row-padding et-border-bottom">
                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <img
                            src={common.cloudImg(result.logo)}
                            className="univ-view-img"
                            alt=""
                          />
                        </div>
                        <div className="ms-4">
                          <h2 className="univ-name">{result.name}</h2>
                          <p className="univ-place">{result.province?.name}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row row-padding py-2">
                    <div className="col-6 col-md-6">
                      <label className="univ-detail-label">Country</label>
                      <p className="univ-details-text">
                        {result.country?.name}
                      </p>
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="univ-detail-label">City</label>
                      <p className="univ-details-text">{result.city?.name}</p>
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="univ-detail-label">Since</label>
                      <p className="univ-details-text">{result.sinceYear}</p>
                    </div>
                    <div className="col-6 col-md-6">
                      <label className="univ-detail-label">Students</label>
                      <p className="univ-details-text">
                        {result.studentStrength}
                      </p>
                    </div>
                  </div>
                  <div className="row ed-bt mt-3">
                    <div className="col-6 col-md-6 row-padding-icons ed-br">
                      <p
                        className="univ-icn-p et-link"
                        onClick={() => common.openCloudUrl(result.brochureDocs)}
                      >
                        <img src={common.loadImg("doc-icn.svg")} alt="" />
                        <span>View Brochure PDF</span>
                      </p>
                    </div>
                    <div className="col-6 col-md-3 row-padding-icons  ed-br">
                      <p
                        className="univ-icn-p cp"
                        onClick={() => window.open(`mailto:${result.email}`)}
                      >
                        <img src={common.loadImg("msg-icn.svg")} alt="" />
                        <span>Email</span>
                      </p>
                    </div>
                    <div className="col-12 col-md-3 row-padding-icons res-univ-border-top">
                      <p
                        className="univ-icn-p cp"
                        onClick={() => common.scrollTo("bottom")}
                      >
                        <img
                          src={common.loadImg("iconly-light-location.png")}
                          alt=""
                        />
                        <span>Location</span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="univ-slider">
                    <Carousel>
                      {result.gallery.map((i, k) => (
                        <Carousel.Item key={k}>
                          <img
                            className="univ-img d-block"
                            src={common.cloudImg(i)}
                            alt="First slide"
                          />
                        </Carousel.Item>
                      ))}
                    </Carousel>
                    <button
                      className="btn btn-view-photo"
                      onClick={() => SetGalleryView(true)}
                    >
                      <img src={common.loadImg("btn-img.svg")}></img>
                      <span className="ps-2">View Photo</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="form-title-step3" ref={refAbout}>
              About University
            </h2>
            <div className="row-wrap-step3 editor-content-wrap">
              {htmlParse(result.description)}
            </div>
            {result.features && (
              <Fragment>
                <h2 className="form-title-step3">Features</h2>
                <div className="row-wrap-step3 editor-content-wrap">
                  {htmlParse(result.features)}
                </div>
              </Fragment>
            )}

            <div className="right-content-width">
              <h2 className="form-title-step3">Courses</h2>
              <div className="right-content-width">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card-slider courses-slider-wrap">
                      <Slider {...common.sliderSettings()}>
                        {courseResult.data.map((i, k) => (
                          <div className="card-margin" key={k}>
                            <div className="course-card">
                              <div
                                className="et-cp"
                                onClick={() =>
                                  navigate(
                                    `/course/info/${i._id}/${tId}/${tKey}`
                                  )
                                }
                              >
                                <div className="course-card-header university-header">
                                  <div className="">
                                    <h3 className="card-course-title ">
                                      {i.name}
                                    </h3>
                                  </div>
                                  {/* <span className="course-tag me-3">
                                    {i.courseLevel}
                                  </span>
                                  <span className="course-tag bg-gold">
                                    {i.courseDurationType} Year
                                  </span> */}
                                  <ul className="course-info-list d-flex justify-content-start align-items-center flex-wrap">
                                    <li className="me-2 mb-2 d-flex">
                                      <img
                                        src={common.loadImg("level-icn.svg")}
                                        alt="level-icon"
                                        className="cc-header-icn"
                                      />
                                      {i.courseLevel}
                                    </li>
                                    <li className="me-2 mb-2 d-flex">
                                      <img
                                        src={common.loadImg("duration-icn.svg")}
                                        alt="duration-icon"
                                        className="cc-header-icn"
                                      />
                                      {i.courseDurationType} Year
                                    </li>
                                    {offerUI(i)}
                                  </ul>
                                </div>
                                <div className="d-flex align-items-center justify-content-between course-card-university-wrap university-logo-cont">
                                  <div className="d-flex align-items-center">
                                    {/* <img
                                      src={common.loadImg(
                                        "oxford-university-icon.svg"
                                      )}
                                      alt=""
                                    /> */}
                                    <img
                                      src={common.cloudImg(result.logo)}
                                      alt=""
                                    />
                                    <p className="card-university-name ps-3">
                                      <div className="card-university-title">
                                        {result.name}
                                      </div>
                                      {/* <br /> */}
                                      <div className="card-university-place">
                                        {result.province?.name}
                                      </div>
                                    </p>
                                  </div>
                                </div>
                                <div className="card-course-desc">
                                  {/* <p>{common.trimString(i.shortDesc, 100)}</p> */}
                                  <p className="course-short-desc">
                                    {i.shortDesc}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between align-items-center course-card-footer">
                                <div>
                                  <p className="label">Fee</p>
                                  <p className="amount">
                                    {i.currency} {i.tuitionFee}
                                  </p>
                                </div>
                                <div>
                                  <a
                                    className="apply-now"
                                    onClick={() => setCourseId(i._id)}
                                  >
                                    {getApplyText()}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                        {courseResult?.pageInfo.totalCount > 7 && (
                          <div className="d-flex align-items-center justify-content-center view-more-padding course-card">
                            <div
                              className="et-cp"
                              onClick={() =>
                                navigate(
                                  `/course/finder/p/0?q=uName=${result?.name}&uid=${result?._id}`
                                )
                              }
                            >
                              <div className="rounded-btn ms-4">
                                <img src={common.loadImg("more-course.svg")} />
                              </div>
                              <p className="label-slider">
                                View more <br />
                                Courses
                              </p>
                            </div>
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="br-white"></div> */}
            <h2 className="form-title-step3">Institutional details</h2>
            <div className="row-wrap-step3 editor-content-wrap">
              {htmlParse(result.institutionDetail)}
            </div>
            <h2 className="form-title-step3" ref={refLocation}>
              Location details
            </h2>
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-6 p-0 d-flex align-items-center justify-content-center univ-footer-addr">
                  <div className="inner-footer">
                    <p className="univ-detail-p footer-univ-name mb-0">
                      {result.name}
                    </p>
                    <p className="univ-detail-p mb-0">{`${result.location}`}</p>
                  </div>
                </div>
                <div className="col-md-6 p-0">
                  <GoogleMaps
                    coordinate={result.coordinate}
                    zoom={10}
                    search={false}
                    dragMarker={false}
                    clickMarker={false}
                    markerShow={true}
                    markerText={`${result.location}`}
                  />
                </div>
              </div>
            </div>
            {galleryView && (
              <Lightbox
                mainSrc={common.cloudImg(galleryImages[galleryIndex])}
                nextSrc={common.cloudImg(
                  galleryImages[(galleryIndex + 1) % galleryImages.length]
                )}
                prevSrc={common.cloudImg(
                  galleryImages[
                    (galleryIndex + galleryImages.length - 1) %
                      galleryImages.length
                  ]
                )}
                onCloseRequest={() => SetGalleryView(false)}
                onMovePrevRequest={() =>
                  SetGalleryIndex(
                    (galleryIndex + galleryImages.length - 1) %
                      galleryImages.length
                  )
                }
                onMoveNextRequest={() =>
                  SetGalleryIndex((galleryIndex + 1) % galleryImages.length)
                }
              />
            )}
          </Fragment>
        )}
        <div className="shadow-div1"></div>
        <div className="shadow-div2"></div>
      </div>

      {/* modal */}
      {courseId !== null && (
        <CoursePickup
          tId={tId}
          tKey={tKey}
          courseId={courseId}
          onClose={() => {
            setCourseId(null);
          }}
          onSuccess={() => {
            setCourseId(null);
            navigate(-1);
          }}
        />
      )}
    </Fragment>
  );
}

export default Info;
