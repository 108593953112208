import AmlHashForm from "aml-hash-form";
import { useEffect, useState } from "react";
// includes
import { common } from "helpers";
import { api } from "helpers/api";
import FireBase from "../../../helpers/firebase";

export default function One(props) {
  // state
  const [viewA, setViewA] = useState(false);
  const [viewB, setViewB] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deviceToken, setDeviceToken] = useState(null);
  // form
  const formFields = {
    firstName: {
      validate: ["req#First Name is required"],
    },
    email: {
      validate: [
        "req#Email is required",
        "email#Please enter a valid email address",
      ],
    },
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmPassword: {
      validate: [
        "req#Confirm Password is required",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password do not match",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // Handler
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      register();
    }
  };

  // effect
  useEffect(() => {
    FireBase.getDeviceToken(setDeviceToken);
  }, []);

  // Api
  const register = () => {
    setLoading(true);
    let payload = {
      name: fields?.firstName,
      email: fields?.email,
      password: fields?.password,
      userType: "AGENT",
      webDevToken: deviceToken,
      registeredFrom: "Web",
    };
    let data = {
      url: "ids_sign_up",
      method: "POST",
      auth: "none",
      body: payload,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        common.localDataSet({
          name: fields.firstName,
          email: fields.email,
          password: fields.password,
          userType: "AGENT",
        });
        props?.onSuccess();
      } else if (res.status === 500) {
        setError(res.data.error);
      }
      setLoading(false);
    });
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="row">
          <div className="col-md-12">
            <div className="right-content-width remove-border">
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="signin-form-row">
                      <input
                        type="text"
                        name="firstName"
                        placeholder="Enter first name"
                        value={fields.firstName}
                        onChange={handleChange}
                        className="form-control cust-input"
                      />
                    </div>

                    <p className="error-txt">{errors.firstName}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="signin-form-row">
                      <input
                        type="email"
                        name="email"
                        placeholder="Enter email address"
                        value={fields.email}
                        onChange={(e) => {
                          handleChange(e);
                          setError(null);
                        }}
                        className="form-control cust-input"
                      />
                    </div>
                    <p className="error-txt">{error ?? errors.email}</p>
                  </div>
                </div>

                <div className="row  ">
                  <div className="col-md-12">
                    <div className="signin-form-row">
                      <input
                        type="password"
                        name="password"
                        placeholder="Enter password"
                        value={fields.password}
                        onChange={handleChange}
                        maxLength={15}
                        className="form-control cust-input"
                      />
                    </div>
                    <p className="error-txt">{errors.password}</p>
                  </div>
                  <div className="col-md-12">
                    <div className="signin-form-row">
                      <input
                        type="password"
                        name="confirmPassword"
                        placeholder="Enter confirm password"
                        value={fields.confirmPassword}
                        onChange={handleChange}
                        maxLength={15}
                        className="form-control cust-input"
                      />
                    </div>
                    <p className="error-txt">{errors.confirmPassword}</p>
                  </div>
                </div>

                <div className="row px-3">
                  <p className="agree-txt">
                    I agree to the Terms and Conditions by clicking Singup
                  </p>
                  <button
                    className="form-control form-btn promoter-auth-btn"
                    onClick={() => onSubmit()}
                    disabled={loading}
                  >
                    {!loading ? "Next" : "Processing"}
                  </button>
                </div>
                {/* <div class="d-flex align-items-center justify-content-between mt-2">
                  <p class="mb-0 ms-2" style={{color:"#ffffff"}}>
                    Already Registered
                  </p>
                  <a
                    class="rememberlogin-text new-forgot-pwd"
                    href="/login"
                  >
                    Signin
                  </a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
