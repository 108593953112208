import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
// includes
import { api, common } from "helpers";

export default function Two(props) {
  // init
  const navigate = useNavigate();

  // state
  const [init, setInit] = useState(true);
  const [localData] = useState(common.localDataGet());
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(null);
  const [timeOut, setTimeOut] = useState(59);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const timer =
      timeOut > 0 && setInterval(() => setTimeOut(timeOut - 1), 1000);
    return () => clearInterval(timer);
  }, [timeOut]);

  // api
  const onSubmit = () => {
    if (otp.length < 6) {
      setError("Enter the otp");
    } else {
      setLoading(true);
      // call
      let data = {
        url: "ids_otp_verify",
        method: "POST",
        auth: "none",
        body: {
          otp,
        },
      };

      api.call(data, (res) => {
        if (res.status === 200) {
          common.tempDataSet({
            page: 3,
            planId: null,
            accessToken: res.data.accessToken,
          });
          common.notify("S", "Your email has been verified successfully");
          setTimeout(() => {
            props?.onSuccess();
          }, 1000);
        } else if (res.status === 500) {
          setError(res.data.error);
        }
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      });
    }
  };

  const otpGenerator = () => {
    let data = {
      url: "ids_sign_up",
      method: "POST",
      auth: "none",
      body: {
        ...localData,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        setTimeOut(59);
        common.notify("S", "Otp has been sent to " + localData.email);
      } else if (res.status === 500) {
        setError(res.data.error);
      }
    });
  };

  // render
  return (
    init && (
      <div className="auth-form-container">
        <div className="">
          <div className="text-center pb-3">
            <label className="auth-otp-label">
              OTP sent to your <strong>{localData?.email}</strong>
            </label>
          </div>
          <div className="otp-section d-flex justify-content-center">
            <OtpInput
              value={otp}
              onChange={(value) => {
                setError(null);
                setOtp(value);
              }}
              numInputs={6}
              isInputNum={true}
              className={error && "otp-error"}
              inputStyle={{
                width: "60px",
                height: "74px",
                color: "#000",
                fontWeight: "400",
                fontSize: "22px",
                caretColor: "blue",
                borderRadius: "10px",
              }}
              separator={<span>-</span>}
            />
          </div>
          <div className="mx-5 my-3">
            <p className="error-txt">{error}</p>
          </div>
          <div className="d-flex justify-content-between mt-4">
            <div className="d-flex">
              <p className="auth-otp-label">Didn't receive code?</p>
            </div>
            <div>
              {timeOut ? (
                <p className="auth-otp-label">Resend Otp in 0:{timeOut} </p>
              ) : (
                <p onClick={otpGenerator} className="auth-otp-label cp">
                  Resend code
                </p>
              )}
            </div>
          </div>

          <div className=" justify-content-between">
            <div>
              <p className="auth-otp-label cp" onClick={props?.onBack}>
                Back
              </p>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-4">
            <button
              className={loading ? "form-control form-btn promoter-auth-btn" : "form-control form-btn promoter-auth-btn"}
              disabled={loading ? loading : false}
              onClick={onSubmit}
            >
              {loading ? "Processing" : "Submit"}
            </button>
          </div>
        </div>
      </div>
      // <div className="row">
      //   <div className="col-12 agent-signup-wrap py-5">
      //     <div className="row">
      //       <div className="col-md-5 mx-auto">
      //         <div className="box-header">
      //           <p className="title">Otp Verification</p>
      //         </div>
      //         <div className="otp-body">
      //           <div className="text-center pb-3">
      //             <label className="form-label">
      //               OTP sent to your <b>{localData?.email}</b>
      //             </label>
      //           </div>
      //           <div className="otp-section d-flex justify-content-center">
      //             <OtpInput
      //               value={otp}
      //               onChange={(value) => {
      //                 setError(null);
      //                 setOtp(value);
      //               }}
      //               numInputs={6}
      //               isInputNum={true}
      //               className={error && "otp-error"}
      //               inputStyle={{
      //                 width: "54px",
      //                 height: "54px",
      //                 color: "#000",
      //                 fontWeight: "400",
      //                 fontSize: "22px",
      //                 caretColor: "blue",
      //               }}
      //               separator={<span>-</span>}
      //             />
      //           </div>
      //           <div className="mx-5 my-3">
      //             <p className="error-txt">{error}</p>
      //           </div>
      //           <div className="d-flex justify-content-between mt-4">
      //             <div className="d-flex">
      //               <p className="rememberlogin-text">Didn't receive code?</p>
      //             </div>
      //             <div>
      //               {timeOut ? (
      //                 <p>Resend Otp in 0:{timeOut} </p>
      //               ) : (
      //                 <p
      //                   onClick={otpGenerator}
      //                   className="rememberlogin-text cp"
      //                 >
      //                   Resend code
      //                 </p>
      //               )}
      //             </div>
      //           </div>

      //           <div className=" justify-content-between mt-3">
      //             <div>
      //               <p
      //                 className="rememberlogin-text cp"
      //                 onClick={props?.onBack}
      //               >
      //                 Back
      //               </p>
      //             </div>
      //           </div>

      //           <div className="d-flex justify-content-center mt-4">
      //             <button
      //               className={loading ? "btn-signin-disable" : "btn-signin"}
      //               disabled={loading ? loading : false}
      //               onClick={onSubmit}
      //             >
      //               {loading ? "Processing" : "Submit"}
      //             </button>
      //           </div>
      //         </div>

      //         <div className="shadow-div"></div>
      //         <div className="greenshow-div"></div>
      //       </div>
      //     </div>
      //   </div>
      // </div>
    )
  );
}
