import { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Slider from "react-slick";
import "react-tooltip/dist/react-tooltip.css";
// includes
import { common } from "helpers";
// pages
import Five from "./register/five";
import Four from "./register/four";
import One from "./register/one";
import Seven from "./register/seven";
import Six from "./register/six";
import Three from "./register/three";
import Two from "./register/two";
import Agreement from "./register/agreement";

function Register() {
  // state
  const [init, setInit] = useState(false);
  const [step, setStep] = useState(1);
  const [viewA, setViewA] = useState(false);
  const [viewB, setViewB] = useState(false);
  const [tempData] = useState(common.tempDataGet());
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  // effect

  useEffect(() => {
    if (tempData?.page) {
      setStep(tempData?.page);
    }
    setInit(true);
  }, []);

  // support
  const gotoStep = (val) => {
    if (step === 2 && val === 1) {
      setStep(1);
    }
    if (step === 4 && val === 3) {
      setStep(3);
    }
  };

  const onSuccess = (val) => {};
  const tooltip = [
    "Register as a Partner",
    "Otp verification",
    "Business info",
    "Reference check from the same industry",
    "Subscription",
    "Agreement",
  ];

  //render
  return (
    init && (
      <div className="container-fluid p-0">
        {/* {step == 1 && ( */}
        <div className="auth-wrap promoter-wrap auth-signup ">
          {/* <div className="auth-logo-wrap">
              <a href="/">
                <img
                  src={common.loadImg("logo-web.svg")}
                  width="115"
                  height="57"
                  alt="study-uk"
                />
              </a>
            </div> */}
          <div className="signup-right">
            <div className="inner-wrapper">
              <h2 className="title mb-0">No false promises</h2>
              <h1 className="title sub mb-0">Realistic Income</h1>
              <p>
                Believe in yourself to <br /> Achieve what is possible for you!
              </p>
              <div className="px-5">
                <img
                  src={common.loadImg("weight-down.svg")}
                  width="368"
                  height="343"
                  alt="weight1"
                  className="weight-down-img"
                />
              </div>
              <img
                src={common.loadImg("weight-up.svg")}
                width="397"
                height="688"
                alt="weight1"
                className="weight-up-img"
              />
            </div>
            <div className="agent-signup-right">
              <img
                src={common.loadImg("logo-web.svg")}
                width="115"
                height="57"
                alt="study-uk"
              />
            </div>
            <div>
              <p className="promoter-siginup-foot-txt">
                Register as a Promoter for Pickauni and earn a{" "}
                <strong>realistic £50 - £100k peryear.*</strong>
              </p>
              <p className="more-link-txt">
                Click the link below to find out more
              </p>
            </div>
          </div>
          <div className="bottom-bg"></div>
          <div className="left">
            <div className="auth-signin-btn-wrap">
              <a href="/login" className="signin-link">
                Sign In
                <img
                  src={common.loadImg("sigin-dwn-arrow.svg")}
                  alt="down-arrow"
                  width="14"
                  height="7"
                  className="ms-1"
                />
              </a>
            </div>
            <p className="title register-title">Register</p>
            <div className="d-flex justify-content-start mb-5">
              {Array(6)
                .fill()
                .map((i, k) => {
                  ++k;
                  return (
                    <div key={k} className="cp" onClick={() => gotoStep(k)}>
                      <p className="register-steps-label web-steps-label me-2">
                        <span
                          className={`dotbtn${
                            step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                          }`}
                          data-title={tooltip[k - 1]}
                        >
                          {k}
                        </span>
                        <span
                          className={`reg-steps-label${
                            step == 7 && k == 6 ? "" : k !== step ? 1 : ""
                          }`}
                        >
                          {tooltip[k - 1]}
                        </span>
                      </p>
                    </div>
                  );
                })}
              <p
                id="tool-tip"
                className="et-cp"
                data-tooltip-content="This is Tooltip"
              ></p>
              <ReactTooltip
                anchorId="tool-tip"
                place={"bottom"}
                style={{ zIndex: "999" }}
              />
            </div>
            <div className="form-wrap auth-form-wrap">
              <div className="auth-form-container">
                {step == 1 && <One onSuccess={() => setStep(2)} />}
                {/* <p className="policy-txt">
                    We never share your data without permission. <br />
                    Our privacy and cookie policies
                  </p> */}
                {step === 2 && (
                  <Two onSuccess={() => setStep(3)} onBack={() => setStep(1)} />
                )}
              </div>
              {step === 3 && <Three onSuccess={() => setStep(4)} />}
              {step === 4 && (
                <Four onSuccess={() => setStep(5)} onBack={() => setStep(3)} />
              )}
              {step === 5 && <Five onSuccess={(e) => setStep(e)} />}
              {step === 6 && (
                <Six onSuccess={() => setStep(7)} onBack={() => setStep(5)} />
              )}
              {step === 7 && <Agreement onSuccess={() => setStep(8)} />}
            </div>
          </div>
        </div>
        {/* )} */}
        {/* {step > 1 && step <= 7 && step != 5 && (
          <div className="row register-step-counter">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-10 mx-auto">
                  <div className="row d-flex align-items-center mt-5">
                    <div className="col-md-5 col-sm-5 register-step-main-head reghead mt-4">
                      Register
                    </div>
                    <div className="col-md-3 col-sm-3 d-flex dottedcls steps-btn">
                      {Array(6)
                        .fill()
                        .map((i, k) => {
                          ++k;
                          return (
                            <div
                              key={k}
                              className="cp"
                              onClick={() => gotoStep(k)}
                            >
                              <p className="register-step-head mb-1 me-2">
                                <span
                                  className={`dotbtn${
                                    step == 7 && k == 6
                                      ? ""
                                      : k !== step
                                      ? 1
                                      : ""
                                  }`}
                                  data-title={tooltip[k - 1]}
                                >
                                  0{k}
                                </span>
                              </p>
                            </div>
                          );
                        })}
                      <p
                        id="tool-tip"
                        className="et-cp"
                        data-tooltip-content="This is Tooltip"
                      ></p>
                      <ReactTooltip
                        anchorId="tool-tip"
                        place={"bottom"}
                        style={{ zIndex: "999" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {/* <div className={`et-${step === 1 ? "show" : "hide"}`}>
          <One onSuccess={() => setStep(2)} />
        </div> */}
        {/* {step === 2 && (
          <Two onSuccess={() => setStep(3)} onBack={() => setStep(1)} />
        )}
        {step === 3 && <Three onSuccess={() => setStep(4)} />}
        {step === 4 && (
          <Four onSuccess={() => setStep(5)} onBack={() => setStep(3)} />
        )}
        {step === 5 && <Five onSuccess={(e) => setStep(e)} />} */}
        {step === 6 && (
          <Six onSuccess={() => setStep(7)} onBack={() => setStep(5)} />
        )}
        {/* {step === 7 && <Agreement onSuccess={() => setStep(8)} />} */}
        {step === 8 && <Seven />}
      </div>
    )
  );
}

export default Register;
