import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useParams, useOutletContext } from "react-router-dom";
// includes
import { api, common } from "helpers";
// pages
import CommonStudentView from "includes/studentView";
import StatusBar from "elements/statusBar";
import Select from "react-select";

function View() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  const [user, setUser] = useState();
  const [student, setStudent] = useState();
  const [members, setMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [viewStudent, setViewStudent ] = useState();

  useEffect(() => {
  //  getSubAgents();
   if(authInfo.role != "student"){
    userVerify();
   }
  }, [])

  const checkPermission = (users, stu) => {
    if(authInfo.role == "sub_agent" && (users && users?.permissions?.length > 0)){
      if(users?.permissions[0]?.stuView == "stuall" && users?.permissions[0]?.stuUpdate == "stumy"){
        if(stu?.subAgentId && stu?.subAgentId === users?._id){
          setViewStudent(true);
        } else {
          setViewStudent(false);
        }
      } else{
        setViewStudent(true);
      }
    } else {
      setViewStudent(true);
    }
  }

  const getSubAgents = (id) => {
    let data = {
      url: "subagent",
      method: "GET",
      query: "?offset=1&limit=1000",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        let member = [];
        let selectedMembers = [];
        res?.data?.data?.map(item => {
          let val = {
            label: item.firstName,
            value: item._id
          }
          member.push(val);
          if(id == item._id){
            let val = {
              label: item.firstName,
              value: item._id
            }
            selectedMembers.push(val);
          }
        })
        setMembers(member);
        setSelectedMembers(selectedMembers);
      }
    });
  };

  const agentStudentInfo = (user) => {
    let data = {
      url: "agent_student",
      query: `/${id}`,
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setStudent(res.data);
        checkPermission(user, res.data);
        if(authInfo.role == "agent"){
          getSubAgents(res.data.subAgentId)
        }
      }
    });
  };

  const userVerify = () => {
      api.call(
        {
          url: "agent_profile",
          method: "GET",
        },
        (res) => {
          if (res.status === 200) {
            setUser(res.data);
            agentStudentInfo(res.data);
          }
        }
      );
  };

  const loginGenerate = () => {
    let data = {
      url: "agent_student",
      method: "POST",
      query: `/${id}/logins`,
      body: {}
    };
    api.call(data, (res) => {
      if (res.status == 204) {
        common.notify("S", "Student login created successfully");
      } else {
        common.notify("E", res.data.error);
      }
    });
  }

  const studentUpdate = (val) => {
    setSelectedMembers(val);
    let data = {
      url: "agent_student",
      method: "PUT",
      query: `/${student._id}`,
      body: {
        subAgentId: val.value
      },
    };
    api.call(data, (res) => {
      if (res.status == 200) {
        common.notify("S", "Student info Updated successfully");
      } else {
        common.notify("E", res.data.error);
      }
    });
  }

  return (
    <Fragment>
      <div className="title-wrap sticky-top">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <img
              src={common.loadImg("back-icn.svg")}
              className="ed-cp pe-4"
              alt=""
              onClick={() => navigate("/agent/student/list")}
            />
            <h3 className="inner-page-header-title mb-0">Student Profile</h3>
          </div>
          {((user && user?.createLoginAuthorized) && (student && !student?.id_s)) && (
            <a
              onClick={() => loginGenerate()}
              className="send-login d-flex justify-content-between align-items-center"
            >
              <span className="ms-3">Send Login Invite to Student</span>
              <img
                src={common.loadImg("plus-sign.svg")}
                className="me-3"
                alt="promotion-icn"
              ></img>
            </a>
          )}
          {authInfo.role == "agent" && authInfo.isPromotor && (
                // <div className="col-md-6 mb-4">
                <span className="ms-3"><label>Responsible person</label>
                  <Select
                    className="basic-multi-select"
                    value={selectedMembers}
                    options={members}
                    onChange={(data) => {
                      studentUpdate(data);
                    }}
                  /></span>
                // </div>
              )}
        </div>
      </div>
      <div className="content-wrap" style={{ paddingTop: "23vh" }}>
        {viewStudent == true &&
        <CommonStudentView
          id={id}
          formModule="all"
          onSuccess={() => navigate("/agent/student/list")}
        />
}
        {viewStudent == false &&
         <CommonStudentView
          id={id}
          formModule="all"
          formPreview={true}
          formType="promoterStdInfo"
          onSuccess={() => navigate("/agent/student/list")}
          coordinatorView={true}
          onlyView={true}
        /> 
      }
      </div>
    </Fragment>
  );
}

export default View;
