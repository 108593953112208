export const types = {
  gender: [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Others", value: "Others" },
  ],
  marital: [
    { label: "Single", value: "Single" },
    { label: "Married", value: "Married" },
  ],
  educationLevel: [
    { label: "Grade 12 / High School", value: "highschool" },
    { label: "2-Year Undergraduate Diploma", value: "diploma" },
    {
      label: "3-Year Undergraduate Advanced Diploma",
      value: "advanced_diploma",
    },
    { label: "3-Year Bachelors Degree", value: "3_bachelor" },
    { label: "4-Year Bachelors Degree", value: "bachelor" },
    { label: "Master", value: "masters" },
    { label: "Doctoral Degree (Phd, M.D., ...)", value: "doctoral" },
  ],
  educationLevelProfile: [
    { label: "Entry Level Certificate", value: "Entry Level Certificate" },
    {
      label: "NQF/ QCF L1 - GCSE Grade D-G/ BTEC Certificate or Diploma",
      value: "NQF/ QCF L1 - GCSE Grade D-G/ BTEC Certificate or Diploma",
    },
    {
      label: "NQF/ QCF L2 - GCSE Grade A-C, BTEC Certificate or Diploma",
      value: "NQF/ QCF L2 - GCSE Grade A-C, BTEC Certificate or Diploma",
    },
    {
      label:
        "NQF/ QCF L3 - AS/A Levels, BTEC Certificate or Diploma, BTEC and OCR Nationals",
      value:
        "NQF/ QCF L3 - AS/A Levels, BTEC Certificate or Diploma, BTEC and OCR Nationals",
    },
    {
      label:
        "NQF/ QCF L4 - BTEC Professional Diplomas, certificates and awards (HNC) certificates of Higher Education",
      value:
        "NQF/ QCF L4 - BTEC Professional Diplomas, certificates and awards (HNC) certificates of Higher Education",
    },
    {
      label:
        "NQF/ QCF L5 - HNDs/ Diploma of Higher and Further Education, Foundation Degree",
      value:
        "NQF/ QCF L5 - HNDs/ Diploma of Higher and Further Education, Foundation Degree",
    },
    {
      label:
        "NQF/ QCF L6 - BTEC Advanced Diplomas, Bachelor Degrees and Graduate Certificates",
      value:
        "NQF/ QCF L6 - BTEC Advanced Diplomas, Bachelor Degrees and Graduate Certificates",
    },
    {
      label:
        "NQF/ QCF L7 - Postgraduate Certificates and Diplomas, Masters Degree",
      value:
        "NQF/ QCF L7 - Postgraduate Certificates and Diplomas, Masters Degree",
    },
    { label: "Other Qualifications", value: "Other Qualifications" },
    { label: "Work Experience Only", value: "Work Experience Only" },
  ],
  financeYearTakenBefore: [
    { label: "1 Year", value: "1 Year" },
    { label: "2 Year", value: "2 Year" },
    { label: "3 Year", value: "3 Year" },
    { label: "4 Year", value: "4 Year" },
  ],
  visaStatus: [
    { label: "British National", value: "British National" },
    { label: "EU National", value: "EU National" },
    {
      label: "Indefinite Leave to Remain (ILR)",
      value: "Indefinite Leave to Remain (ILR)",
    },
    {
      label: "Limited Leave to Remain (LLR)",
      value: "Limited Leave to Remain (LLR)",
    },
    {
      label: "Family Member of a British National",
      value: "Family Member of a British National",
    },
    {
      label: "Family Member of an EU/ EAA National",
      value: "Family Member of an EU/ EAA National",
    },
    { label: "Refugee/ Asylum Seeker", value: "Refugee/ Asylum Seeker" },
  ],
  livingPeriod: [
    { label: "Less than a  Year", value: "Less than a  Year" },
    { label: "1 - 2 Years", value: "1 - 2 Years" },
    { label: "2 - 3 Years", value: "2 - 3 Years" },
    { label: "3 - 4 Years", value: "3 - 4 Years" },
    { label: "4 - 5 Years", value: "4 - 5 Years" },
    { label: "5 - 10 Years", value: "5 - 10 Years" },
    { label: "10 - 15 Years", value: "10 - 15 Years" },
    { label: "15 - 20 Years", value: "15 - 20 Years" },
    { label: "20 - 25 Years", value: "20 - 25 Years" },
    { label: "More than 30 Years", value: "More than 30 Years" },
  ],
  elItems: [
    { label: "Percentage", value: "elPercentage" },
    { label: "Points", value: "elPoints" },
    { label: "Grade", value: "elGrade" },
    { label: "GPA", value: "elGpa" },
    { label: "CGPA", value: "elCgpa" },
  ],

  gradingSystem: [
    { label: "Percentage", value: "Percentage" },
    { label: "Points", value: "Points" },
    { label: "GPA", value: "elGpa" },
    { label: "CGPA", value: "elCgpa" },
    { label: "Letter", value: "Letter" },
  ],
  test: [
    { label: "IELTS", value: "IELTS" },
    { label: "IELTS UKVI", value: "IELTS UKVI" },
    { label: "TOEFL", value: "TOEFL" },
  ],
  reschedule: [
    {
      label:
        "Illness: I am feeling sick, experiencing symptoms such as fever, cough, or cold, or have been diagnosed with a medical condition that prevents me from attending the appointment.",
      value:
        "Illness: I am feeling sick, experiencing symptoms such as fever, cough, or cold, or have been diagnosed with a medical condition that prevents me from attending the appointment.",
    },
    {
      label:
        "Family emergency: I have an unexpected or urgent situation involving a family member or someone close to me, such as a death in the family, a medical emergency, or a crisis that requires my immediate attention.",
      value:
        "Family emergency: I have an unexpected or urgent situation involving a family member or someone close to me, such as a death in the family, a medical emergency, or a crisis that requires my immediate attention.",
    },
    {
      label:
        "Transportation issues: I am unable to find or arrange transportation to get to the appointment, my car broke down, or I am experiencing unexpected traffic or road closures.",
      value:
        "Transportation issues: I am unable to find or arrange transportation to get to the appointment, my car broke down, or I am experiencing unexpected traffic or road closures.",
    },
    {
      label:
        "Work conflict: I have work-related commitments, such as mandatory meetings or urgent projects, that conflict with the appointment time.",
      value:
        "Work conflict: I have work-related commitments, such as mandatory meetings or urgent projects, that conflict with the appointment time.",
    },
    {
      label:
        "Personal conflict: I have personal commitments or responsibilities, such as caregiving or childcare, that prevent me from attending the appointment.",
      value:
        "Personal conflict: I have personal commitments or responsibilities, such as caregiving or childcare, that prevent me from attending the appointment.",
    },
    {
      label:
        "Other commitments: I have other pre-existing commitments or engagements, such as classes or appointments with other healthcare providers.",
      value:
        "Other commitments: I have other pre-existing commitments or engagements, such as classes or appointments with other healthcare providers.",
    },
    {
      label:
        "Financial constraints: I am unable to afford the costs associated with the appointment, such as co-pays, deductibles, or out-of-pocket expenses.",
      value:
        "Financial constraints: I am unable to afford the costs associated with the appointment, such as co-pays, deductibles, or out-of-pocket expenses.",
    },
    {
      label:
        "Car trouble: I am having issues with my vehicle that prevent me from attending the appointment.",
      value:
        "Car trouble: I am having issues with my vehicle that prevent me from attending the appointment.",
    },
    {
      label:
        "Inclement weather: There are severe weather conditions, such as heavy rain, snow, or ice, that make it unsafe or difficult for me to travel to the appointment.",
      value:
        "Inclement weather: There are severe weather conditions, such as heavy rain, snow, or ice, that make it unsafe or difficult for me to travel to the appointment.",
    },
    {
      label:
        "Miscommunication with the office/staff: There was a misunderstanding about the appointment time, location, or requirements, or I did not receive sufficient information from the office or staff.",
      value:
        "Miscommunication with the office/staff: There was a misunderstanding about the appointment time, location, or requirements, or I did not receive sufficient information from the office or staff.",
    },
    {
      label:
        "Other (please specify): There is another reason that is not listed above that prevents me from attending the appointment.",
      value: "other",
    },
  ],
  gradeLevel: [100, 80, 60, 40, 30],
  gradeValue: { 100: "A", 80: "B", 60: "C", 40: "D", 30: "E" },
  planCategory: {
    ap: "Student Applications",
    sp: "Student Portal",
    ep: "Exclusive Promotions",
    oc: "Operating Countries",
    af: "Commission",
  },

  passportCountry: [
    "AM",
    "AR",
    "AT",
    "AU",
    "BE",
    "BG",
    "BY",
    "BR",
    "CA",
    "CH",
    "CN",
    "CY",
    "CZ",
    "DE",
    "DK",
    "DZ",
    "EE",
    "ES",
    "FI",
    "FR",
    "GB",
    "GR",
    "HR",
    "HU",
    "IE",
    "IN",
    "IR",
    "ID",
    "IS",
    "IT",
    "JP",
    "KR",
    "LT",
    "LU",
    "LV",
    "LY",
    "MT",
    "MY",
    "MZ",
    "NL",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SL",
    "SK",
    "TR",
    "UA",
    "US",
  ],

  studentArrayFields: [
    "college",
    "exam",
    "workExp",
    "extraCurricular",
    "otherActivity",
  ],
  menus: {
    student: [
      {
        name: "Home",
        icon: "home-icn.svg",
        hoveredIcons: "agent-home-hv-icn.svg",
        url: "/student/home",
        menuKey: "student_home",
      },
      {
        name: "My Profile",
        icon: "profile-icn.svg",
        hoveredIcons: "active-myprofile-hv-icn.svg",
        url: "/student/profile",
        progress: "profile",
        menuKey: "student_profile",
      },
      {
        name: "Course Finder",
        icon: "category-icn.svg",
        hoveredIcons: "category-hv-icn.svg",
        url: "/course/finder/p/0",
        menuKey: "course",
      },
      {
        name: "Your Application",
        icon: "document-icn.svg",
        hoveredIcons: "active-yourapplication-hv-icn.svg",
        url: "/application/list",
        menuKey: "application",
      },
    ],
    agent: [
      {
        name: "Home",
        icon: "home-icn.svg",
        hoveredIcons: "agent-home-hv-icn.svg",
        url: "/agent/home",
        menuKey: "agent_home",
      },
      {
        name: "Students",
        icon: "students-icn.svg",
        hoveredIcons: "agent-students-hv-icn.svg",
        url: "/agent/student/list",
        menuKey: "agent_student",
      },
      {
        name: "Course Finder",
        icon: "category-icn.svg",
        hoveredIcons: "agent-coursefinder-hv-icn.svg",
        url: "/course/finder/p/0",
        menuKey: "course",
      },
      {
        name: "Your Application",
        icon: "document-icn.svg",
        hoveredIcons: "agent_application-hv-icn.svg",
        url: "/application/list",
        menuKey: "application",
      },
      {
        name: "Team Member",
        icon: "students-icn.svg",
        hoveredIcons: "agent-students-hv-icn.svg",
        url: "/agent/member/list",
        menuKey: "agent_member",
      },
      {
        name: "Students Connected",
        icon: "stu-con-icn.svg",
        hoveredIcons: "stu-con-hv-icn.svg",
        url: "/agent/connect/list",
        menuKey: "agent_connect",
      },
      {
        name: "Agent",
        icon: "agent_icn.svg",
        hoveredIcons: "agent_hv_icn.svg",
        url: "/agent/promoters/list",
        menuKey: "agent_promoters",
      },
      {
        name: "Promo Code",
        icon: "promocode_icn.svg",
        hoveredIcons: "promocode_hv_icn.svg",
        url: "/agent/promo/list",
        menuKey: "agent_promo",
      },
    ],
    sub_agent: [
      {
        name: "Home",
        icon: "home-icn.svg",
        hoveredIcons: "agent-home-hv-icn.svg",
        url: "/agent/home",
        menuKey: "agent_home",
      },
      {
        name: "Students",
        icon: "students-icn.svg",
        hoveredIcons: "agent-students-hv-icn.svg",
        url: "/agent/student/list",
        menuKey: "agent_student",
      },
      {
        name: "Course Finder",
        icon: "category-icn.svg",
        hoveredIcons: "agent-coursefinder-hv-icn.svg",
        url: "/course/finder/p/0",
        menuKey: "course",
      },
      {
        name: "Your Application",
        icon: "document-icn.svg",
        hoveredIcons: "agent_application-hv-icn.svg",
        url: "/application/list",
        menuKey: "application",
      }

    ],
  },
  // student profile
  studentProfile: [
    {
      title: "General Information",
      description: "Personal Info and Address (required)",
      icon: "generalinformation-icn.svg",
      key: "general",
      type: "personal_address",
    },
    {
      title: "Education History",
      description: "General And College",
      icon: "educationhistory-icn.svg",
      key: "education",
      type: "education_college",
    },
    // {
    //   title: "Test Score",
    //   description: "English Test",
    //   icon: "testscores-icn.svg",
    //   key: "test",
    //   type: "exam",
    // },
    {
      title: "Background Information",
      description: "About Student Finance England",
      icon: "backgroundinformation-icn.svg",
      key: "backgroundInfo",
      type: "background",
    },
    {
      title: "Work Experience (If employeee)",
      description: "Provide us with you work history",
      icon: "workexperience-icn.svg",
      key: "experience",
      type: "workExp",
    },
    {
      title: "Documents Attach",
      description: "Passport, Qualification, English language...etc",
      icon: "documentsattach-icn.svg",
      key: "docs",
      type: "document",
    },
  ],

  studentView: [
    {
      key: "personal",
      title: "Personal",
      header: "General Information",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your personal details",
        b: "Name, DOB, Gender, Nationality, Language,..etc",
        c: "",
      },
      multi: false,
      step: 1,
    },
    {
      key: "address",
      title: "Address Details",
      header: "",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your address details",
        b: "Address, City, Country, Province, Postcode,…etc",
        c: "",
      },
      multi: false,
      step: 0,
    },
    {
      key: "education",
      title: "General",
      header: "Educational  Information",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your education details",
        b: "Country of education, Highest level of education,…etc",
        c: "",
      },
      multi: false,
      step: 2,
    },
    {
      key: "college",
      title: "College",
      header: "",
      headerLink: false,
      subHeader: true,
      description: {
        a: "Please enter your college details",
        b: "Country of Institution, Level of education, Graduation date, Degree,…etc",
        c: "College details not added yet",
      },
      multi: true,
      step: 0,
    },
    // {
    //   key: "exam",
    //   title: "Course",
    //   header: "Test Scores",
    //   headerLink: true,
    //   subHeader: false,
    //   description: {
    //     a: "Please enter your test scores details",
    //     b: "Test name, Score, Date, Document,…etc",
    //     c: "Test score details not added yet",
    //   },
    //   multi: true,
    //   step: 3,
    // },
    {
      key: "background",
      title: "Background Informations",
      header: "Background Informations",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your background details",
        b: "SFE process informations",
        c: "Background details not added yet",
      },
      step: 3,
    },
    {
      key: "workExp",
      title: "Company",
      header: "Work History",
      headerLink: true,
      subHeader: false,
      description: {
        a: "Please enter your work details ",
        b: "Company, Designation, Joining, end date and,…etc",
        c: "Company details not added yet",
      },
      multi: true,
      step: 4,
    },
  ],

  profileHeader: {
    all: "All Information",
    personal: "Personal Information",
    address: "Address Information",
    personal_address: "General Information",
    education: "Educational Information",
    college: "College Information",
    education_college: "Education History",
    exam: "Test Scores",
    background: "Background Information",
    workExp: "Work History",
    document: "Documents Attach",
  },

  agentProfile: [
    {
      type: "personal_address",
    },
    {
      type: "education_college",
    },
    {
      type: "background",
    },
    // {
    //   type: "exam",
    // },
    {
      type: "workExp",
    },
    {
      type: "document",
    },
  ],
  coursePickup: {
    p: "Course Intake",
    c: "Course Change",
    u: "Course Update",
    s: "Course Apply",
  },
  coursePickupMessage: {
    p: "Course has been added successfully",
    c: "Course has been changed successfully",
    u: "Course has been updated successfully",
    s: "Course has been picked successfully",
  },

  agentHome: [
    {
      title: `Draft Applications`,
      description: "",
      icon: "",
      key: ["payment_pending"],
      url: "/application/list",
    },
    {
      title: "Submitted Applications",
      description: "",
      icon: "",
      key: ["review"],
      url: "/application/list",
    },
    {
      title: "Missing Documents",
      description: "",
      icon: "",
      key: ["review_missing_info"],
      url: "/application/list",
    },
    {
      title: "Submitted to University",
      description: "",
      icon: "",
      key: ["submitted_to_university"],
      url: "/application/list",
    },
    {
      title: "Offered Students",
      description: "",
      icon: "",
      key: [
        "conditional_offer_letter",
        "conditional_offer_missing_info",
        "conditional_offer_interview",
        "conditional_offer_mock",
        "unconditional_offer",
      ],
      url: "/application/list",
    },
    // {
    //   title: "CStudent Finance England",
    //   description: "",
    //   icon: "",
    //   key: ["course_payment", "course_payment_interview", "sfe_process"],
    //   url: "/application/list",
    // },
    {
      title: "Student Finance England",
      description: "",
      icon: "",
      key: ["sfe_process"],
      url: "/application/list",
    },
    // {
    //   title: "Accepted",
    //   description: "",
    //   icon: "",
    //   key: ["accepted"],
    //   url: "/application/list",
    // },
    // {
    //   title: "Visa Processing",
    //   description: "",
    //   icon: "",
    //   key: ["visa"],
    //   url: "/application/list",
    // },
    {
      title: "Enrolled Applications",
      description: "",
      icon: "",
      key: ["enrolled"],
      url: "/application/list",
    },
    {
      title: "Rejected Applications",
      description: "",
      icon: "",
      key: ["rejected"],
      url: "/application/list",
    },
    {
      title: "Cancelled Applications",
      description: "",
      icon: "",
      key: ["cancelled"],
      url: "/application/list",
    },
  ],

  applicationSelect: [
    { value: "payment_pending:", label: "Pending Payment" },
    { value: "review", label: "Under Review" },
    { value: "review_missing_info", label: "Missing Info" },
    { value: "submitted_to_university", label: "Submitted To University" },
    { value: "conditional_offer", label: "Offer" },
    { value: "course_payment", label: "Pending Payment" },
    { value: "sfe_process", label: "SFE Progress" },
    // { value: "accepted", label: "Accepted" },
    // { value: "visa", label: "Visa Processing" },
    { value: "enrolled", label: "Enrolled" },
    { value: "rejected", label: "Rejected" },
    { value: "cancelled", label: "Cancelled" },
  ],

  applicationStatus: {
    payment_pending: "Pending Payment",
    fees_payment: "Fees Paid",
    review: "Under Review",
    review_missing_info: "Missing Info",
    submitted_to_university: "Submitted To University",
    conditional_offer: "Offer",
    conditional_offer_letter: "Offer",
    conditional_offer_missing_info: "Offer",
    conditional_offer_interview: "Offer",
    conditional_offer_mock: "Offer",
    unconditional_offer: "Offer",
    course_payment: "unconditional Offer",
    course_payment_interview: "Interview",
    course_payment_docs: "Bank Statement",
    sfe_process: "SFE Progress",
    // accepted: "Accepted",
    // visa: "Visa Processing",
    enrolled: "Enrolled",
    rejected: "Rejected",
    cancelled: "Cancelled",
  },

  filterFields: {
    agentSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    agentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    studentSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    studentMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    courseSingle: {
      type: "object",
      value: "",
      finder: "",
    },
    courseMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    universitySingle: {
      type: "object",
      value: "",
      finder: "",
    },
    universityMulti: {
      type: "array",
      value: [],
      finder: "",
    },
    planMulti: {
      type: "object",
      value: "",
      finder: "",
    },
    country: {
      type: "object",
      value: "",
      finder: "",
    },
    province: {
      type: "object",
      value: "",
      finder: "",
    },
    city: {
      type: "object",
      value: "",
      finder: "",
    },
    applicationSelect: {
      type: "object",
      value: "",
      finder: "applicationSelect",
    },
    published: { type: "boolObject", value: "", finder: "published" },
    status: { type: "boolObject", value: "", finder: "status" },
    verified: { type: "boolObject", value: "", finder: "published" },
    feesSlider: { type: "twoArray", value: [1, 10000000] },
    dateA: { type: "date", value: "", finder: "" },
    dateB: { type: "date", value: "", finder: "" },
    dateC: { type: "date", value: "", finder: "" },
    dateD: { type: "date", value: "", finder: "" },
  },
  socket: {
    HOME: "permission",
    APPLICATION_TIMELINE: "application",
    NOTIFICATION: "notification",
  },
  yearOfExp: [
    { label: "Less than 2 Years", value: "Less than 2 Years" },
    { label: "Less than 5 Years", value: "Less than 5 Years" },
    { label: "More than 5 Years", value: "More than 5 Years" },
  ],

  object: {
    gradingSystem: {
      Percentage: "Percentage",
      Points: "Points",
      elGpa: "GPA",
      elCgpa: "CGPA",
      Letter: "Letter",
    },
  },
  promoCategory: [
    {
      label: "Course Fees",
      value: "course",
    },
    {
      label: "Application Fees",
      value: "application",
    },
  ],
  promoType: [
    {
      label: "Percentage",
      value: "percentage",
    },
    {
      label: "USD",
      value: "USD",
    },
  ],
  status: [
    { label: "Active", value: true },
    { label: "Inactive", value: false },
  ],
  booleanYesNo: [
    { label: "Yes", value: true },
    { label: "No", value: false },
  ],
  permission: {
    label: {
      c: "Create",
      u: "Update",
      r: "Read",
      d: "Delete",
      all: "All",
      my: "My",
      st: "Students",
      ap: "Applications",
    },
    list: [
      {
        key: "st",
        types: ["all", "my"],
      },
      {
        key: "ap",
        types: ["all", "my"],
      }
    ],
  },
};
