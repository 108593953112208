import { Fragment, useEffect, useState } from "react";
import Modal from "react-modal";
import { Link, useOutletContext } from "react-router-dom";
// includes
import ModalConfirm from "elements/modalConfirm";
import StatusBar from "elements/statusBar";
import { api, common, types } from "helpers";

import CommonStudentForm from "includes/studentForm";
import CommonStudentDocs from "./studentDocs";
// files
import { default as studentFields } from "assets/files/studentFields.json";

function StudentView(props) {
  // props

  const [outletAction, authInfo, userInfo, userInfoStatus] = useOutletContext();
  // state
  const [id] = useState(props.id);
  const [formType] = useState(props.formType);
  const [formModule] = useState(props.formModule);
  const [formPreview] = useState(props.formPreview);
  const [onlyView] = useState(props.onlyView);
  const [formModuleList] = useState(String(props.formModule).split("_"));
  const [filled, setFilled] = useState({});
  const [status, setStatus] = useState("process");
  const [loading, setLoading] = useState(null);
  const [result, setResult] = useState(null);
  const [myModal, setMyModal] = useState({
    formModule: "",
    formIndex: "all",
    formAddNew: false,
  });
  const [confirm, setConfirm] = useState(null);
  const [authData] = useState(common.getAuth());
  const PersonalDoLabel = {
    passportDocs: "Passport Document",
    birthDocs: "Birth Certificate or Proof of ID Document",
    euDocs: "EU Share Code Document",
    brpDocs: "BRP Document",
  };

  const EuList = [
    "AD",
    "AL",
    "AT",
    "AX",
    "BA",
    "BE",
    "BG",
    "BY",
    "CH",
    "CY",
    "CZ",
    "DE",
    "DK",
    "EE",
    "ES",
    "FI",
    "FO",
    "FR",
    "GG",
    "GR",
    "HR",
    "HU",
    "IE",
    "IM",
    "IC",
    "IT",
    "JE",
    "LI",
    "LT",
    "LU",
    "LV",
    "MC",
    "MD",
    "MK",
    "MT",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SE",
    "SI",
    "SJ",
    "SK",
    "SM",
    "UA",
    "VA",
  ];

  // effect
  useEffect(() => {
    initData();
  }, []);

  // api
  const initData = () => {
    if (formType === "application") {
      applicationInfo();
    } else {
      if (authData.role === "student") {
        studentInfo();
      } else {
        agentStudentInfo();
      }
    }
  };

  const hideAndShowView = (rsData) => {
    let sub = {
      personal: "personal",
      education: "education",
      background: "background",
    };
    //In United Kingdom
    if (rsData?.birth?._id == "GB") {
      ["brpDocs", "euDocs"].map(
        (item) => delete studentFields[sub?.personal][item]
      );
      ["passportNumber"].map(
        (item) => delete studentFields[sub?.background][item]
      );
    }
    //In European
    if (!EuList.includes(rsData?.birth?._id)) {
      ["euDocs"].map((item) => delete studentFields[sub?.personal][item]);
    }

    if (
      !["Work Experience Only", "Other Qualifications"].includes(
        rsData?.highLevelOfEducation
      )
    ) {
      [
        "yearOfExp",
        "ruWorking",
        "expDocs",
        "otherQualification",
        "otherQualificationDesc",
        "otherQualificationDocs",
      ].map((item) => delete studentFields[sub?.education][item]);
      studentFields["education"]["countryOfEducation"] = {
        isReq: "Select the country",
        view: ["Country of Education", 4, true, "select"],
      };
      studentFields["education"]["grade"] = {
        isReq: "Credits is required",
        view: ["Credits", 4, true, "string"],
      };
    } else {
      ["countryOfEducation", "grade"].map(
        (item) => delete studentFields[sub?.education][item]
      );
    }
    if (rsData?.highLevelOfEducation === "Work Experience Only") {
      [
        "otherQualification",
        "otherQualificationDesc",
        "otherQualificationDocs",
      ].map((item) => delete studentFields[sub?.education][item]);
    }
    if (rsData?.highLevelOfEducation === "Other Qualifications") {
      ["yearOfExp", "ruWorking", "expDocs"].map(
        (item) => delete studentFields[sub?.education][item]
      );
    }
    if (rsData?.isFinanceTakenBefore === "N") {
      ["financeYearTakenBefore"].map(
        (item) => delete studentFields[sub?.background][item]
      );
    }
  };
  const ConditionalLabel = (key, value, result) => {
    if (["passportDocs", "euDocs", "brpDocs"].includes(key)) {
      if (key === "passportDocs" && result["birth"]?._id === "GB") {
        return PersonalDoLabel["birthDocs"];
      }
      return PersonalDoLabel[key];
    }
    return value;
  };

  const convertFieldValue = (key, value, result) => {
    if (
      ["isFinanceTakenBefore", "refugee", "publicCare", "disability"].includes(
        key
      )
    ) {
      return value
        .replace("Year", "year")
        .replace("Y", "Yes")
        .replace("N", "No");
    }
    if (["yearOfExp"].includes(key)) return `${result[key]}`;

    return value;
  };

  const studentInfo = () => {
    let data = {
      url: "student_profile",
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        checkFilled(res.data);
        if (!res.data?.passportDocs) {
          res.fields.passportDocs = [];
        } else if (
          typeof result?.fields?.passportDocs === "string" ||
          result?.fields?.passportDocs instanceof String
        ) {
          res.fields.passportDocs = [res.fields.passportDocs];
        }

        setResult(res.data);
        hideAndShowView(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const agentStudentInfo = () => {
    let data = {
      url: props?.coordinatorView ? "promoter_students_info" : "agent_student",
      query: `/${id}`,
      method: "GET",
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        checkFilled(res.data);
        setResult(res.data);
        hideAndShowView(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const applicationInfo = () => {
    let data = {
      url: "application_profile",
      query: `/${id}`,
      method: "GET",
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        checkFilled(res.data);
        hideAndShowView(res.data);
        setResult(res.data);
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const checkFilled = (res) => {
    let isFilled = {};
    Object.entries(studentFields).map(([i, k]) => {
      let fields = studentFields[i];
      let isAllow = false;
      if (["college", "exam", "workExp"].includes(i)) {
        if (res[i].length > 0) {
          isAllow = true;
        }
      } else {
        Object.entries(fields).map(([m, n]) => {
          if (res[m] && n.view?.[2]) {
            isAllow = true;
          }
        });
      }
      isFilled[i] = isAllow;
    });
    setFilled(isFilled);
  };

  // onProps
  const gotoForm = (type) => {
    setMyModal((prev) => ({ ...prev, formModule: type }));
  };

  const gotoAddNew = (type) => {
    setMyModal({
      formModule: type,
      formIndex: "new",
      formAddNew: false,
    });
  };

  const gotoEdit = (type, key) => {
    setMyModal({
      formModule: type,
      formIndex: key,
      formAddNew: false,
    });
  };

  const onSuccess = () => {
    initData();
    setMyModal((prev) => ({ ...prev, formModule: "" }));
  };

  const closeModal = () => {
    hideAndShowView(result);
    setMyModal((prev) => ({ ...prev, formModule: "" }));
  };

  const onYes = () => {
    setLoading("delete");
    let data = {};
    let params = {};
    params[confirm[0]] = result[confirm[0]].filter((i, k) => k !== confirm[1]);
    if (formType === "application") {
      data = {
        url: "application_profile",
        method: "PUT",
        query: `/${id}`,
        body: params,
      };
    } else {
      if (authData.role === "student") {
        data = {
          url: "student_profile",
          method: "PUT",
          body: params,
        };
      } else if (authData.role === "agent" || authData.role === "sub_agent") {
        data = {
          url: "agent_student",
          method: "PUT",
          query: `/${id}`,
          body: params,
        };
      }
    }

    api.call(data, (res) => {
      if (res.status === 200) {
        if (authData.role === "student" && formType !== "application") {
          outletAction("myProgress");
        }
        initData();
      } else {
        common.notify("E", res.data.error);
      }
      setLoading(null);
      setConfirm(null);
    });
  };

  // render
  return (
    <div className="inner-content-wrap">
      {status !== "success" ? (
        <StatusBar status={status} />
      ) : (
        <Fragment>
          {types.studentView.map((i, k) => {
            if (
              formModuleList.includes(i.key) ||
              ["all"].includes(formModule)
            ) {
              return (
                <Fragment key={k}>
                  <div className="et-view-content-wrap">
                    {/* Main Header */}
                    {i.header &&
                      (authData.role === "agent" ||
                        authData.role === "sub_agent") && (
                        <div className="et-view-title-wrap d-flex justify-content-between align-items-center bg-blue">
                          <h1 className="et-view-primary-title">{i.header}</h1>
                          {i.headerLink &&
                            filled[i.key] &&
                            formPreview &&
                            !onlyView && (
                              <div className="et-vc-right">
                                <Link
                                  to="#"
                                  className="et-view-ptitle-link"
                                  onClick={() => {
                                    i.multi
                                      ? gotoAddNew(i.key)
                                      : gotoForm(i.key);
                                  }}
                                >
                                  {i.multi ? "Add New" : "Edit"}
                                </Link>
                              </div>
                            )}
                        </div>
                      )}

                    {/* Sub Header */}
                    {(i.subHeader || authData.role === "student") && (
                      <div className="et-view-title-wrap d-flex justify-content-between align-items-center">
                        <h3 className="et-view-secondary-title">{i.title}</h3>
                        {filled[i.key] &&
                          !formPreview &&
                          (i.multi ? (
                            <Link
                              to=""
                              className="et-view-title-link"
                              onClick={() => gotoAddNew(i.key)}
                            >
                              Add New
                            </Link>
                          ) : (
                            <Link
                              to=""
                              className="et-view-title-link"
                              onClick={() => gotoForm(i.key)}
                            >
                              Edit
                            </Link>
                          ))}
                      </div>
                    )}

                    {/* Inner Content */}
                    {!filled[i.key] ? (
                      <div className="et-vc--no-info d-flex justify-content-between align-items-center et-view-border-bottom">
                        <div className="et-vc-left">
                          <p className="et-vc-label">
                            {formPreview ? i.description.c : i.description.a}{" "}
                          </p>
                          <p className="et-vc-info-required">
                            {i.description.b}
                          </p>
                        </div>
                        {!formPreview && (
                          <div className="et-vc-right">
                            <Link
                              to="#"
                              className="et-view-inner-link"
                              onClick={() => {
                                gotoForm(i.key);
                              }}
                            >
                              Proceed
                            </Link>
                          </div>
                        )}
                      </div>
                    ) : i.multi ? (
                      result[i.key].map((cItem, cKey) => (
                        <Fragment key={cKey}>
                          <div className="et-vc-step-info d-flex align-items-center justify-content-between et-view-border-bottom">
                            <div>
                              <span className="et-vc-step me-3">
                                0{Number(cKey) + 1}
                              </span>
                              <span className="et-vc-step-title">
                                {i.title}
                              </span>
                            </div>
                            {!formPreview && (
                              <div className="d-flex">
                                <Link
                                  to="#"
                                  className="et-view-doc-action me-3"
                                  onClick={() => gotoEdit(i.key, cKey)}
                                >
                                  <img
                                    src={common.loadImg("edit-btn.svg")}
                                    alt=""
                                  />
                                </Link>
                                <Link
                                  to=""
                                  className="et-view-doc-action"
                                  onClick={() => setConfirm([i.key, cKey])}
                                >
                                  <img
                                    src={common.loadImg(
                                      "iconly-light-delete.svg"
                                    )}
                                    alt=""
                                  />
                                </Link>
                              </div>
                            )}
                          </div>

                          <div className="et-vc-info et-view-border-bottom res-padding-none">
                            <ul className="et-vc-list row m-0">
                              {Object.keys(studentFields[i.key]).map(
                                (j, k) =>
                                  studentFields[i.key][j].view !== "" && (
                                    <li
                                      className={`col-md-${
                                        studentFields[i.key][j].view[1]
                                      } et-view-border-bottom et-view-border-right res-padding-none`}
                                      key={k}
                                    >
                                      <div className="et-list-inner res-display-flex">
                                        <p className="et-field-name">
                                          {studentFields[i.key][j].view[0]}
                                        </p>
                                        {j === "docs" ? (
                                          <Fragment>
                                            {cItem[j].length
                                              ? cItem[j].map((dItem, dKey) => (
                                                  <Fragment>
                                                    <p
                                                      className="et-field-value et-link res-pad"
                                                      key={dKey}
                                                      onClick={() =>
                                                        common.openCloudUrl(
                                                          dItem
                                                        )
                                                      }
                                                    >
                                                      {common.fileName(dItem)}
                                                    </p>
                                                    <br />
                                                  </Fragment>
                                                ))
                                              : "-"}
                                          </Fragment>
                                        ) : j === "testScore" ? (
                                          <p className="et-field-value">
                                            L:{cItem["listening"] ?? 0} / R:
                                            {cItem["reading"] ?? 0} / W:
                                            {cItem["writing"] ?? 0} / S:
                                            {cItem["speaking"] ?? 0}
                                          </p>
                                        ) : (
                                          <p className="et-field-value">
                                            {["gradingSystem"].includes(j)
                                              ? types.object[j][
                                                  (studentFields[i.key][j]
                                                    .view[3],
                                                  cItem[j])
                                                ]
                                                ? types.object[j][
                                                    (studentFields[i.key][j]
                                                      .view[3],
                                                    cItem[j])
                                                  ]
                                                : "-"
                                              : common.readable(
                                                  studentFields[i.key][j]
                                                    .view[3],
                                                  cItem[j]
                                                )}
                                          </p>
                                        )}
                                      </div>
                                    </li>
                                  )
                              )}
                            </ul>
                          </div>
                        </Fragment>
                      ))
                    ) : (
                      <div className="et-vc-info et-view-border-bottom res-padding-none">
                        <ul className="et-vc-list row">
                          {Object.keys(studentFields[i.key]).map((j, k) => {
                            return (
                              studentFields[i.key][j].view != "" && (
                                <li
                                  className={`col-md-${
                                    studentFields[i.key][j].view[1]
                                  } et-view-border-bottom et-view-border-right res-padding-none`}
                                  key={k}
                                >
                                  <div className="et-list-inner res-display-flex">
                                    <p className="et-field-name">
                                      {ConditionalLabel(
                                        j,
                                        studentFields[i.key][j].view[0],
                                        result
                                      )}{" "}
                                    </p>
                                    {[
                                      "passportDocs",
                                      "euDocs",
                                      "brpDocs",
                                      "otherQualificationDocs",
                                      "expDocs",
                                      "sfeAppDocs",
                                      "homeOfficeDocs",
                                    ].includes(j) ? (
                                      <Fragment>
                                        {[
                                          "passportDocs",
                                          "otherQualificationDocs",
                                          "expDocs",
                                        ].includes(j) &&
                                        typeof result[j] !== "string" ? (
                                          result[j]?.map((dItem, dKey) => (
                                            <p
                                              className="et-field-value et-link res-pad"
                                              key={dKey}
                                              onClick={() =>
                                                common.openCloudUrl(dItem)
                                              }
                                            >
                                              {common.fileName(dItem)}
                                            </p>
                                          ))
                                        ) : [
                                            "euDocs",
                                            "brpDocs",
                                            "sfeAppDocs",
                                            "homeOfficeDocs",
                                          ].includes(j) && result[j]?.length ? (
                                          <p
                                            className="et-field-value et-link res-pad"
                                            onClick={() =>
                                              common.openCloudUrl(result[j])
                                            }
                                          >
                                            {common.fileName(result[j])}
                                          </p>
                                        ) : (
                                          "-"
                                        )}
                                      </Fragment>
                                    ) : (
                                      <p className="et-field-value">
                                        {studentFields[i.key][j].view[3] ===
                                        "mobile" ? (
                                          <span>
                                            +
                                            {result.mobile ? (
                                              <a
                                                href={"tel:" + result.mobile}
                                                className="et-field-value et-link res-pad txt-dec-none"
                                              >
                                                {common.hideMobile(
                                                  result.mobile
                                                )}
                                              </a>
                                            ) : (
                                              "-"
                                            )}
                                          </span>
                                        ) : (
                                          <span>
                                            {convertFieldValue(
                                              j,
                                              common.readable(
                                                j === "nationality"
                                                  ? "nationality"
                                                  : studentFields[i.key][j]
                                                      .view[3],
                                                j == "email" ? (
                                                  <a
                                                    href={"mailto:" + result[j]}
                                                    target="_blank"
                                                    className="et-field-value et-link res-pad txt-dec-none"
                                                  >
                                                    {common.hideEmail(
                                                      result[j]
                                                    )}
                                                  </a>
                                                ) : (
                                                  result[j]
                                                )
                                              ),
                                              result
                                            )}
                                          </span>
                                        )}
                                      </p>
                                    )}
                                  </div>
                                </li>
                              )
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </Fragment>
              );
            }
          })}
          {["document", "all"].includes(formModule) && (
            <CommonStudentDocs
              data={result}
              formType={formType}
              formPreview={formPreview}
              studentId={id}
              onSuccess={props?.onSuccess}
              onlyView={props.onlyView}
            />
          )}
        </Fragment>
      )}
      {myModal.formModule && (
        <Modal
          isOpen={true}
          style={common.modalStyle(75)}
          contentLabel=""
          ariaHideApp={false}
        >
          <CommonStudentForm
            studentId={id}
            formType={formType}
            formModule={myModal.formModule}
            formIndex={myModal.formIndex}
            formAddNew={myModal.formAddNew}
            formModal={true}
            onSuccess={onSuccess}
            onCancel={closeModal}
          />
        </Modal>
      )}
      {confirm && (
        <ModalConfirm
          loading={loading}
          onYes={onYes}
          onNo={() => setConfirm(null)}
        />
      )}
    </div>
  );
}
export default StudentView;
